import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import SubtitleComponent from '../../components/SubtitleComponent';
import Box from '@material-ui/core/Box'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FETCH_SEARCH_REDUCER } from '../../reducers/SearchReducer';
import ListComponent from '../../components/ListComponent';


const SearchPage = (props) => {
    const { t } = useTranslation();
    const { data, loading, loadingPagination } = useSelector((state) => state.SearchReducer);
    const dispatch = useDispatch();
    const { name } = useParams();

    useEffect(() => {
        dispatch(FETCH_SEARCH_REDUCER(name))
    }, [dispatch, name])

    const handleChangePage = useCallback((pag) => {
        dispatch(FETCH_SEARCH_REDUCER(name, pag))
    }, [dispatch, name])
    return (
        <ListComponent
            handleChangePage={handleChangePage}
            breadcrumbs={t('search_page.breadcrumb')}
            titlePagination={t('search_page.pagination')}
            data={data}
            loading={loading}
            loadingPagination={loadingPagination}
         >
            <Box mb='20px'>
                <SubtitleComponent variant='h1' title={t('search_page.title')} />
            </Box>
        </ListComponent>
    )
}

export default React.memo(SearchPage);