export const NEWS_API = '/news';
export const INFORMATION_API = '/information';
export const LOCALIZATION_API = '/localization';
export const PPN_API = '/ppn';
export const MENU_API = '/menu';
export const QUESTIONS_API = '/questions';
export const SEARCH_API = '/search';
export const CONTACT_API = '/contact';
export const COMPLAINT_API = '/complaint';
export const MAIN_API = '/main';
export const MAP_API = '/street/map';
export const DEV_MAP_API = '/street/map-dev';
export const STREET_API = '/street';
export const REGULATIONS_API = '/regulation';
export const FEES_API = '/fee';