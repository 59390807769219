import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react'

const useStyles = makeStyles((theme) => ({
    filter: {
        background: '#00000000',
        zIndex: 1,
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        position: 'absolute',
        transition: 'all 0.2s ease-in',
        '&:hover': {
            background: `${theme.palette.primary.main}90`,
        }
    }
}))
const FilterComponent = (props) => {
    const classes = useStyles();
    return (
        <section className={classes.filter}></section>
    )
}


export default FilterComponent;