import { createMuiTheme } from '@material-ui/core/styles';

export const createTheme = () => {
    const theme = createMuiTheme({
        palette: {
            primary: {
                // dark: '#305E9A',
                main: '#ffff00',
                // light: '#00000046',
            },
            gray: {
                primary: '#ffff00',
                secondary: "#ffff00",
            },
            background: {
                default: '#000000',
                files: '#C4C4C440',
                filesLight: '#C4C4C430',
            },
            text: {
                primary: '#ffff00',
                input: '#ffff00',
            },
            chip: {
                primary: '#ffff00'
            },
        },
    })
    return theme.palette
}

const MuiTheme = createTheme();
export default MuiTheme