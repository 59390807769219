import React, {useEffect, useState} from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import SuccessDialogComponent from './SuccessDialogComponent';
import ButtonComponent from './ButtonComponent';

const useStyles = makeStyles((theme) => ({
    sendedBtn: {
        padding: 22,
        minWidth: 'auto',
        width: 0,
        height: 44,
        animation: `$sendedBtn 0.5s linear forwards`,
        backgroundColor: `${theme.palette.primary.main} !important`,
        '& .MuiButton-label': {
            opacity: 0,
            animation: `$sendedBtnText 0.2s 0.5s ease-in forwards`,

        }
    },
    submitAnimation: {
        transition: 'all 0.2s ease-in',
        padding: 0,
        minWidth: 'auto',
        width: 44,
        height: 44,
    },
    circ: {
        margin: 0,
    },
    "@keyframes sendedBtn": {
        "to": {
            padding: '10px 30px',
            minWidth: 200,
            width: 200,
        }
    },
    "@keyframes sendedBtnText": {
        "0%": {
            opacity: 0,
        },
        "100%": {
            opacity: 1,
        }
    },
}))

const SendBtnComponent = ({ message = '', sendLoading, showSuccess, disabled, formikProps }) => {
    const classes = useStyles();
    const [showLoader, setShowLoader] = useState(false);
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (sendLoading) {
            const timer = setTimeout(() => {
                setShowLoader(true)
            }, 200);
            return () => clearInterval(timer);
        } else {
            setShowLoader(false)
        }
    }, [sendLoading])
    useEffect(() => {
        if (showSuccess) {
            setShowLoader(false);
            setOpen(true);
            formikProps.resetForm()
        } else {
            setShowLoader(false)
        }
        // eslint-disable-next-line 
    }, [showSuccess, message])
    return (
        <>
            {showLoader
                ? <CircularProgress color="primary" thickness={1} size={44} className={classes.circ} />
                : <ButtonComponent
                    disabled={Boolean(disabled)}
                    variant='outlined'
                    color='primary'
                    type='submit'
                    className={sendLoading ? classes.submitAnimation : ''}
                >
                    {sendLoading ? '' : t('btns.send')}
                </ButtonComponent>
            }
            <SuccessDialogComponent
                open={open}
                message={message}
                setOpen={setOpen}
            />
        </>
    )
}


export default React.memo(SendBtnComponent);