export const MAIN_ROUTE = "/";
// export const MAP_OLD_ROUTE = "/mapa-spp";
// export const MAP_ROUTE = "/wK0AQcWinD";
export const MAP_OLD_ROUTE = "/wK0AQcWinD";
export const MAP_ROUTE = "/mapa-spp";
export const FEES_ROUTE = "/oplaty/:slug";
export const REGULATIONS_ROUTE = "/regulaminy/:slug";
export const REGULATION_ROUTE = "/regulaminy";
export const NEWS_ROUTE = "/aktualnosci";
export const QUESTIONS_ROUTE = "/pytania-i-odpowiedzi";
export const INFORMATION_ROUTE = "/informacja/:slug";
export const LOCALIZATION_ROUTE = "/lokalizacja/:slug";
export const ARTICLE_ROUTE = "/aktualnosci/:slug";
export const PPN_ROUTE = "/ppn/:slug";
export const SEARCH_NAME_ROUTE = "/wyszukiwarka";
export const SEARCH_ROUTE = `${SEARCH_NAME_ROUTE}/:name`;
export const CONTACT_ROUTE = "/formularz-kontaktowy";
export const COMPLAINT_ROUTE = "/formularz-reklamacyjny";
export const ERROR_ROUTE = "/error";
export const EMPTY_ROUTE = "/404";


export const PRIVACY_ROUTE = `${REGULATION_ROUTE}/polityka-prywatnosci`
export const DECLARATION_ROUTE = `${REGULATION_ROUTE}/deklaracja-dostepnosci`