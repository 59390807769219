import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/styles/makeStyles'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { classNames } from '../functions';
import LinkComponent from './LinkComponent'

const useStyles = makeStyles((theme) => ({
    titleCont: {
        position: 'relative',
        cursor: 'pointer',
        '& .anim-list': {
            opacity: 0,
            zIndex: -1,
        },

        '&:hover': {
            '& .anim-list': {
                opacity: 1,
                zIndex: 3,
            },
        }
    },
    navItemCont: {
        marginRight: 5,
        display: 'flex',
        justifyContent: 'center',
        // alignItems: 'baseline',
    },
    title: {
        fontSize: 16,
        maxWidth: 160,
    },
    icon: {
        fontSize: 30,
        marginRight: 5,
        color: theme.palette.gray.secondary
    },
    listCont: {
        minWidth: 250,
        top: 70,
        paddingTop: 0,
        paddingBottom: 0,
        transition: 'all 0.2s ease-in',
        position: 'absolute',
        background: theme.palette.gray.primary,

        // display: ''
    },
    list: {
        paddingTop: 0,
        paddingBottom: 0,
        position: 'relative',
        '&:before': {
            content: "''",
            position: 'absolute',
            right: '100%',
            top: -10,
            width: 0,
            left: 16,
            height: 0,
            borderTop: '30px solid transparent',
            borderRight: `50px solid ${theme.palette.gray.primary}`,
            borderBottom: '8px solid transparent',
            transform: "rotate(210deg)",
        }
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
        '&:hover': {
            textDecoration: 'none',
        }
    },
    divider: {
        marginRight: 10,
    }
}))

const NavLiComponent = ({ title, links, slug, external_link }) => {
    const classes = useStyles();
    return (
        <Box className={classes.navItemCont}>
            {links?.length ? <KeyboardArrowDownIcon className={classes.icon} /> : null }
            <Box className={classes.titleCont}>
                {slug ? <LinkComponent className={classes.link} to={slug} title={title} external_link={external_link}>
                    <Typography className={classes.title}>{title}</Typography>
                </LinkComponent>
                    : <Typography className={classes.title}>{title}</Typography>
                }
                {links?.length
                    ? <Paper className={classNames([classes.listCont, 'anim-list'])}>
                        <List className={classes.list}>
                            {links?.map(({ title, link, external_link }, key) =>
                                <LinkComponent key={key} to={link} external_link={external_link} className={classes.link} title={title}>
                                    <ListItem button>
                                        <ListItemText
                                            primary={title}
                                        />
                                    </ListItem>
                                    {key + 1 !== links?.length ? <Divider className={classes.divider} /> : null}
                                </LinkComponent>
                            )
                            }
                        </List>
                    </Paper>
                    : null
                }

            </Box>
        </Box>
    )
}


NavLiComponent.propTypes = {
    links: PropTypes.array,
    slug: PropTypes.string,
    title: PropTypes.string,
}
export default NavLiComponent;