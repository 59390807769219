import { QUESTIONS_API } from "../constants/api";
import { GET } from "../functions/superagentSending";

const initialState = {
    loading: false,
    data: {},
    error: '',
};


const FETCH_QUESTIONS_REQUEST = 'FETCH_QUESTIONS_REQUEST'
const FETCH_QUESTIONS_SUCCESS = 'FETCH_QUESTIONS_SUCCESS'
const FETCH_QUESTIONS_FAILURE = 'FETCH_QUESTIONS_FAILURE'


const fetchQuestionsRequest = () => ({
    type: FETCH_QUESTIONS_REQUEST
})

const fetchQuestionsSuccess = data => ({
    type: FETCH_QUESTIONS_SUCCESS,
    payload: data
})


const fetchQuestionsFailure = error => ({
    type: FETCH_QUESTIONS_FAILURE,
    payload: error
})

const QuestionsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_QUESTIONS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_QUESTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload,
                error: '',
            }
        case FETCH_QUESTIONS_FAILURE:
            return {
                loading: false,
                data: {},
                error: payload
            }
        default:
            return state
    }
}

export const FETCH_QUESTIONS_REDUCER = () => {
    return (dispatch) => {
        dispatch(fetchQuestionsRequest())
        GET(QUESTIONS_API).then((success) => {
            dispatch(fetchQuestionsSuccess(success?.body))
        }).catch((error) => {
            dispatch(fetchQuestionsFailure(error)) 
            dispatch(fetchQuestionsFailure('')) 
        })
    }
}

export default QuestionsReducer