import { CONTACT_API } from "../constants/api";
import { GET, POST } from "../functions/superagentSending";

const initialState = {
    sendLoading: false,
    loading: false,
    data: [],
    error: '',
    errorSend: '',
    contactSuccess: false,
};


const SEND_CONTACT_REQUEST = 'SEND_CONTACT_REQUEST'
const SEND_CONTACT_SUCCESS = 'SEND_CONTACT_SUCCESS'
const FETCH_CONTACT_SUCCESS = 'FETCH_CONTACT_SUCCESS'
const FETCH_CONTACT_REQUEST = 'FETCH_CONTACT_REQUEST'
const FETCH_CONTACT_FAILURE = 'FETCH_CONTACT_FAILURE'
const FETCH_CONTACT_SEND_FAILURE = 'FETCH_CONTACT_SEND_FAILURE'

const fetchContactSuccess = (data) => ({
    type: FETCH_CONTACT_SUCCESS,
    payload: data
})

const fetchContactRequest = () => ({
    type: FETCH_CONTACT_REQUEST
})

const fetchContactFailure = error => ({
    type: FETCH_CONTACT_FAILURE,
    payload: error
})

const sendContactRequest = () => ({
    type: SEND_CONTACT_REQUEST
})
const sendContactSuccess = data => ({
    type: SEND_CONTACT_SUCCESS,
    payload: data
})

const fetchContactSendFailure = error => ({
    type: FETCH_CONTACT_SEND_FAILURE,
    payload: error
})


const ContactReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_CONTACT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case SEND_CONTACT_SUCCESS:
            return {
                ...state,
                sendLoading: false,
                contactSuccess: true,
            }
        case SEND_CONTACT_REQUEST:
            return {
                ...state,
                sendLoading: true,
                contactSuccess: false,

            }
        case FETCH_CONTACT_SUCCESS:
            return {
                ...state,
                loading: false,
                sendLoading: false,
                data: payload,
                error: '',
                errorSend: '',
            }
        case FETCH_CONTACT_FAILURE:
            return {
                loading: false,
                sendLoading: false,
                data: [],
                error: payload,
                errorSend: '',
            }
        case FETCH_CONTACT_SEND_FAILURE:
            return {
                loading: false,
                sendLoading: false,
                data: state.data,
                error: '',
                errorSend: payload,
            }
        default:
            return state
    }
}

export const SEND_CONTACT_REDUCER = (data) => {
    return (dispatch) => {
        dispatch(sendContactRequest())
        POST(CONTACT_API, data).then((success) => {
            dispatch(sendContactSuccess())
        }).catch((error) => {
            dispatch(fetchContactSendFailure(error));
        })
    }
}
export const FETCH_CONTACT_REDUCER = () => {
    return (dispatch) => {
        dispatch(fetchContactRequest())
        GET(CONTACT_API).then((success) => {
            dispatch(fetchContactSuccess(success.body))
        }).catch((error) => {
            dispatch(fetchContactFailure(error));
            dispatch(fetchContactFailure(''));
        })
    }
}

export default ContactReducer