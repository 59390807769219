import { ACCESS_TEXT, ACCESS_CONTARST, ACCESS_LISTEN } from "../constants";
import { MENU_API } from "../constants/api";
import { GET } from "../functions/superagentSending";

const initialState = {
    loading: false,
    data: {
        [ACCESS_TEXT]: localStorage.getItem(ACCESS_TEXT), 
        [ACCESS_CONTARST]: localStorage.getItem(ACCESS_CONTARST), 
        [ACCESS_LISTEN]: localStorage.getItem(ACCESS_LISTEN), 
    },
    error: '',
};


const UPDATE_MAIN_SUCCESS = 'UPDATE_MAIN_SUCCESS'
const FETCH_MENU_REQUEST = 'FETCH_MENU_REQUEST';
// const FETCH_MENU_FAILURE = 'FETCH_MENU_FAILURE';
const FETCH_MENU_SUCCESS = 'FETCH_MENU_SUCCESS';

const updateMainSuccess = (id, value) => ({
    type: UPDATE_MAIN_SUCCESS,
    payload: { id, value}
})


const fetchMenuRequest = () => ({
    type: FETCH_MENU_REQUEST
})

const fetchMenuSuccess = data => ({
    type: FETCH_MENU_SUCCESS,
    payload: data
})


// const fetchMenuFailure = error => ({
//     type: FETCH_MENU_FAILURE,
//     payload: error
// })


const MainReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_MAIN_SUCCESS:
            return {
                data: {
                    ...state.data,    
                   [payload.id]: payload.value,
                },
                loading: false,
                error: ''
            }
        case FETCH_MENU_SUCCESS:
            return {
                data: {
                    ...state.data,    
                    ...payload,
                },
                loading: false,
                error: ''
            }
        default:
            return state
    }
}

export const UPDATE_MAIN_REDUCER = (id, value) => {
    return (dispatch) => {
        dispatch(updateMainSuccess(id, value));
    }
}

export const FETCH_MENU_REDUCER = (slug) => {
    return (dispatch) => {
        dispatch(fetchMenuRequest())
        GET(MENU_API).then((success) => {
            dispatch(fetchMenuSuccess({ 
                ...success?.body?.data, 
                menu: success?.body?.data?.menu?.length ? success.body.data.menu : [{ title: 'Strona główna', link: '/',}]  
            }))
        })
    }
}



export default MainReducer;