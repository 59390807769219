import React from 'react';
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import makeStyles from '@material-ui/styles/makeStyles';
import useTheme from '@material-ui/styles/useTheme';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import FilesToDownloadComponent from './FilesToDownloadComponent';
import NewsInfoComponent from './NewsInfoComponent';
import articleCss from '../constants/articleCss';
import NewsSkeletonComponent from './skeletonComponents/NewsSkeletonComponent';

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    ...articleCss(theme),
  },
  zoom: {
    top: 0,
    right: 0,
    bottom: 0,
    width: 75,
    left: 0,
    margin: 'auto',
    position: 'absolute',
    transition: 'all 0.2s ease-in',
    opacity: 0,
    color: theme.palette.primary.main,
  },
  title: {
    fontSize: 45,
    fontWeight: 'bold',
    marginBottom: 40,
  },
  img: {
    width: '100%',
  }
}));

const NewsComponent = ({ heroImg, mobileImg, content, title, date, author, files, loading }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const matchXs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <>
      {loading
        ? <>
          <NewsSkeletonComponent />
        </>
        : <Box className={classes.contentContainer}>
          <NewsInfoComponent author={author} date={date} />
          <Typography variant="h1" color="primary" className={classes.title}>
            {title}
          </Typography>
          <Box mb="40px">
            {mobileImg || heroImg
             ? <img
                src={matchXs ? mobileImg : heroImg}
                className={classes.img}
                alt={title}
              />
              : null
            }
            <Typography
              variant="body1"
              className={classes.content}
              dangerouslySetInnerHTML={{ __html: `${content}` }}
            />
          </Box>
          {files?.length
            ? <>
              <Typography variant="body1" color="primary" className={classes.title}>
                {t('news_page.files_to_download')}
              </Typography>
              {files.map(({ name, src }, key) => (
                <FilesToDownloadComponent fileTitle={name} src={src} key={key} />
              ))
              }
            </>
            : null}
          <Box mt='40px'>
            <NewsInfoComponent share author={author} date={date} />
          </Box>
        </Box>
      }
    </>
  );
};

NewsComponent.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  author: PropTypes.string,
  content: PropTypes.string,
  heroImg: PropTypes.string,
  mobileImg: PropTypes.string,
  files: PropTypes.array,
  loading: PropTypes.bool,
};
export default React.memo(NewsComponent);
