import React from 'react'
import { makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: theme.typography.h3.fontSize,
        marginBottom: 10,
    },
}))

const NewsItemTitleStartComponent = ({ title}) => {
 const classes = useStyles();

    return (
        <Typography variant="h2" color="primary" className={classes.title}>
            {title}
        </Typography>
    )
}


NewsItemTitleStartComponent.propTypes = {
    title: PropTypes.string,
}
export default React.memo(NewsItemTitleStartComponent);