import React, { useCallback }from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useTranslation } from 'react-i18next';

const SuccessDialogComponent = ({ message, open, setOpen}) => {
    const {t} = useTranslation();

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen])
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>
                {t('success.title')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText >
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>
                    {t('btns.close')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}


export default React.memo(SuccessDialogComponent);