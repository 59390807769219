import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ArticleComponent from '../../components/ArticleComponent';
import { NEWS_ROUTE } from '../../constants/routes';
import { FETCH_ARTICLE_REDUCER } from '../../reducers/ArticleReducer';

const ArticlePage = () => {
  const { data, loading } = useSelector((state) => state.ArticleReducer);
  const dispatch = useDispatch();
  const { slug } = useParams();
  const {t} = useTranslation();
  useEffect(() => {
    dispatch(FETCH_ARTICLE_REDUCER(slug));
  }, [dispatch, slug]);
  return <ArticleComponent
    data={data}
    breadcrumbs={[{ title: t('news_page.title'), to: NEWS_ROUTE }]}
    loading={loading}
  />;
};

export default React.memo(ArticlePage);
