import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_NEWS_REDUCER } from '../../reducers/NewsReducer';
import ListComponent from '../../components/ListComponent';

const NewsPage = (props) => {
    const { t } = useTranslation();
    const { data, loading, loadingPagination } = useSelector((state) => state.NewsReducer);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(FETCH_NEWS_REDUCER())
    }, [dispatch])

    const handleChangePage = useCallback((pag = 1) => {
        dispatch(FETCH_NEWS_REDUCER(pag))
    }, [dispatch])
    return (
        <ListComponent
            handleChangePage={handleChangePage}
            title={t('news_page.title')}
            breadcrumbs={t('news_page.title')}
            titlePagination={t('news_page.pagination')}
            data={data}
            loading={loading}
            loadingPagination={loadingPagination}
        />
    )
}


NewsPage.propTypes = {
    name: PropTypes.string,
}
export default React.memo(NewsPage);