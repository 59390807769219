import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider'
import Skeleton from '@material-ui/lab/Skeleton'

const useStyles = makeStyles((theme) => ({
    img: {
        width: '100%',
        height: 300,
        objectFit: 'cover',
    },
    title: {
        marginTop: 10,
        height: 20,
        width: '70%',
        marginBottom: 10,
    },
    cont: {
        marginBottom: 20,
    },
    desc: {
        marginBottom: 5,
        height: 60,
    },
    btns: {
        marginTop: 5,
        height: 20,
    }
}))

const ReadAlsoSkeletonComponent = (props) => {
 const classes = useStyles();

    return (
        <Box className={classes.cont}>
            <Skeleton variant='rect' className={classes.img} />
            <Skeleton variant='text' className={classes.title} />
            <Skeleton variant='rect' className={classes.desc} />
           <Divider />
           <Skeleton variant='rect' className={classes.btns} />
        </Box>
    )
}

export default React.memo(ReadAlsoSkeletonComponent);