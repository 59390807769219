import React from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { classNames } from '../../../functions';

const useStyles = makeStyles((theme) => ({
    cont: {
        display: 'flex',
        alignItems: 'center',
        transition: 'all 0.2s ease-in',
        margin: 10,
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
        }
    },
    title: {
        color: theme.palette.primary.main,
        marginLeft: 20,
        transition: 'all 0.2s ease-in',
        cursor: 'pointer',
        fontSize: theme.typography.h6.fontSize,
    },
    icon: {
        width: 60,
        height: 60,
        minWidth: 60,
        minHeight: 60,
        objectFit: 'contain'
    },
    disactivate: {
        filter: 'grayscale(1)',
        opacity: 0.5,
    },
    hr: {
        width: 100,
        height: 10,
        border: 'none',
    }
}))

const LegendTitleComponent = ({ justColor, title, src, Icon, color, active, onClick = () => { } }) => {
    const classes = useStyles();

    return (
        <Box className={classNames([classes.cont, active ? '' : classes.disactivate])} onClick={onClick}>
            {justColor
                ? <hr className={classes.hr} style={{background: color}} />
                : Icon
                    ? <Icon fill={color} className={classes.icon}  />
                    : <img src={src} alt={title} className={classes.icon} />
            }
            <Typography className={classes.title}>{title}</Typography>
        </Box>
    )
}


LegendTitleComponent.propTypes = {
    name: PropTypes.string,
}
export default React.memo(LegendTitleComponent);