import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import MainReducer from './MainReducer'
import HomePageReducer from './HomePageReducer'
import LocalizationReducer from './LocalizationReducer'
import InformationReducer from './InformationReducer'
import ArticleReducer from './ArticleReducer'
import FeesReducer from './FeesReducer'
import RegulationsReducer from './RegulationsReducer'
import NewsReducer from './NewsReducer'
import SearchReducer from './SearchReducer'
import PpnReducer from './PpnReducer'
import QuestionsReducer from './QuestionsReducer'
import ContactReducer from './ContactReducer'
import ComplaintReducer from './ComplaintReducer'
import MapReducer from './MapReducer'
import OldMapReducer from './OldMapReducer'

export default createStore(
    combineReducers({
        MainReducer,
        HomePageReducer,
        LocalizationReducer,
        InformationReducer,
        ArticleReducer,
        FeesReducer,
        RegulationsReducer,
        NewsReducer,
        SearchReducer,
        PpnReducer,
        QuestionsReducer,
        ContactReducer,
        ComplaintReducer,
        MapReducer,
        OldMapReducer,
    }),
    compose(
        applyMiddleware(thunk),
        // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
);
