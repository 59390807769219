export const classNames = (classes) => classes.map((cl) => `${cl}`).join(' ')
export const getCoordsJson = (json) => json.features.map((feature) => {
    return feature?.geometry?.coordinates?.length ?  feature?.geometry?.coordinates.map((c) => ({
        "lng": c[0],
        "lat": c[1]
    })) : {};
})


export const getParksCoordsJson = (json) => json?.features?.length ? json.features.map((feature) => ({
    "lng": feature?.geometry?.coordinates[0],
    "lat": feature?.geometry?.coordinates[1]
})) : [];