import React from 'react';
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/styles/makeStyles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { classNames } from '../functions';
const useStyles = makeStyles((theme) => ({
  iconReadMore: {
    color: theme.palette.gray.icons,
    width: 30,
    height: 30,
    marginRight: 10,
  },
  readMoreBtn: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  linkReadMore: {
    color: 'inherit',
    alignItems: 'center',
    display: 'flex',
    textDecoration: 'none',
    transition: 'color 0.2s ease-in-out',
  },
}));

const ReadMoreComponent = ({ link }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
        <Box
          className={classNames([classes.linkReadMore, 'read-more'])}
        >
          <MoreHorizIcon className={classes.iconReadMore} />
          <Typography
            variant="body1"
            color="initial"
            className={classes.readMoreBtn}
          >
            {t('main_page.readMore')}
          </Typography>
        </Box>
    </>
  );
};

ReadMoreComponent.propTypes = {
  link: PropTypes.string,
};
export default React.memo(ReadMoreComponent);
