import { createMuiTheme } from '@material-ui/core/styles';

export const createTheme = () => {
    const theme = createMuiTheme({
        '*': {
            fontFamily: '"calibri", "sans-serif"',
        },
        typography: {
            fontSize: 12,
            fontFamily: '"calibri", "sans-serif"',
            'button': {
                fontSize: 14,
            },
            'body1': {
                fontSize: 14,
            },
            'h3': {
                fontSize: 22,
                marginBottom: 20,
            },
            'h2': {
                fontSize: 26,
                fontWeight: '400',
            },
            'h1': {
                fontSize: 26,
                fontFamily: '"myriad-pro-semiextended", "sans-serif"',
                fontWeight: '400',
            },
            'h6': {
                fontSize: 16,
                marginBottom: 10,
                fontWeight: 'bold',
            }
            // fontFamily: '"Boing", "Roboto", "Helvetica", "Arial", "sans-serif"',
        },
        palette: {
            map: {
                red: "#EA4C4C",
                yellow: "#EABE07"
            },
            primary: {
                // dark: '#305E9A',
                main: '#154899',
                // light: '#00000046',
            },
            // secondary: {
            //     dark: '#fff',
            //     main: '#FFDC00',
            //     light: '#ffeb3b',
            //     orange: '#FF963C',
            // },
            gray: {
                primary: '#EEEDED',
                secondary: "#C4C4C4",
                light: "#afafaf",
                lightest: '#F8F8F8',
                text: "#999999",
                icons: "#C4C4C4",
                footer: '#f5f5f5',
            },
            background: {
                default: '#ffffff',
                files: '#EEEDED',
                filesLight: '#e0e0e0',
            },
            chip: {
                primary: '#00000040'
            },
            text: {
                primary: '#000000',
                white: '#ffffff',
                input: '#00000050',
            },
            input: {
                background: '#C4C4C440'
            },
            paper: {
                
            }

        },
        overrides: {
            MuiPaper: {
                elevation1: {
                    // background: '#F8F8F8',
                    // overflow: 'hidden',
                    borderRadius: 0,
                    border: `none`
                }
            }
        },
        props: {
            MuiContainer: {
                // maxWidth: "md",
            },
        },
    })
    return {
        ...theme,
    }
}

const MuiTheme = createTheme();
export default MuiTheme