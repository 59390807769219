import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    title: {
        width: '100%',
        height: 40,
        marginBottom: 20,
    },
    cont: {
        width: '100%',
        height: 200, 
        marginTop: 10,
    },
    img: {
        width: '100%',
        height: 300,
        marginBottom: 20,
    }
}))

const NewsSkeletonComponent = (props) => {
 const classes = useStyles();

    return (
        <section>
           <Skeleton  variant='rect'  className={classes.img} />
           <Skeleton  variant='rect'  className={classes.title} />
            <Skeleton variant='rect' className={classes.cont} />
            <Skeleton variant='rect' className={classes.cont} />
            <Skeleton variant='rect' className={classes.cont} />
        </section>
    )
}

export default React.memo(NewsSkeletonComponent);