import { INFORMATION_API } from "../constants/api";
import { GET } from "../functions/superagentSending";

const initialState = {
    loading: false,
    data: {},
    error: '',
};


const FETCH_INFORMATION_REQUEST = 'FETCH_INFORMATION_REQUEST'
const FETCH_INFORMATION_SUCCESS = 'FETCH_INFORMATION_SUCCESS'
const FETCH_INFORMATION_FAILURE = 'FETCH_INFORMATION_FAILURE'


const fetchInformationRequest = () => ({
    type: FETCH_INFORMATION_REQUEST
})

const fetchInformationSuccess = data => ({
    type: FETCH_INFORMATION_SUCCESS,
    payload: data
})


const fetchInformationFailure = error => ({
    type: FETCH_INFORMATION_FAILURE,
    payload: error
})

const InformationReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_INFORMATION_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_INFORMATION_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload,
                error: '',
            }
        case FETCH_INFORMATION_FAILURE:
            return {
                loading: false,
                data: {},
                error: payload
            }
        default:
            return state
    }
}

export const FETCH_INFORMATION_REDUCER = (slug) => {
    return (dispatch) => {
        dispatch(fetchInformationRequest())
        GET(`${INFORMATION_API}/${slug}`).then((success) => {
            dispatch(fetchInformationSuccess(success?.body?.data))
        }).catch((error) => {
            dispatch(fetchInformationFailure(error))
            dispatch(fetchInformationFailure(''))
        })
    }
}

export default InformationReducer