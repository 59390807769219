export const createTheme = () => {
    return {
        fontSize: 16,
        'body1': {
            fontSize: 18,
        },
        'button': {
            fontSize: 18,
        },
        'h3': {
            fontSize: 26,
            marginBottom: 20,
        },
        'h2': {
            fontSize: 30,
            fontWeight: '400',
        },
        'h1': {
            fontSize: 30,
            fontWeight: '400',
        },
        'h6': {
            fontSize: 20,
            marginBottom: 10,
            fontWeight: 'bold',
        }
    }
}

const MuiTheme = createTheme();
export default MuiTheme;