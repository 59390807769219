import React from 'react';
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/styles/makeStyles'
import PropTypes from 'prop-types';
import SubjectIcon from '@material-ui/icons/Subject';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

const useStyles = makeStyles((theme) => ({
  fileWrapper: {
    background: theme.palette.background.files,
    padding: 10,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    textDecoration: 'none',
    marginBottom: 5,
    '&:nth-child(even)': {
      background: theme.palette.background.filesLight,
    },
  },
  fileIcon: {
    color: theme.palette.text.primary,
    marginRight: 10,
  },
}));

const FilesToDownloadComponent = ({ fileTitle, src, extract, pdf }) => {
  const classes = useStyles();

  return (
    <a
      className={classes.fileWrapper}
      href={src}
      target="_blank"
      rel="noreferrer"
    >
      {pdf ? <PictureAsPdfIcon className={classes.fileIcon}/> :extract ? (
        <SubjectIcon className={classes.fileIcon} />
      ) : (
          <VpnKeyIcon className={classes.fileIcon}  />
      )}
      <Typography variant="button" color='textPrimary'>{fileTitle}</Typography>
    </a>
  );
};

FilesToDownloadComponent.propTypes = {
  fileTitle: PropTypes.string,
  src: PropTypes.string,
  extract: PropTypes.bool,
};
export default React.memo(FilesToDownloadComponent);
