import React from 'react'
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Marker } from '@react-google-maps/api';

const InfoMarkersComponent = ({ handleMarker = () => {}}) => {
    const { data } = useSelector((state) => state.MapReducer)
    return (
       <>
            {data?.info_markers?.length
                ? data.info_markers.map((marker, key) =>
                    <Marker
                        position={{
                            lat: marker.lat,
                            lng: marker.lng
                        }}
                        icon={marker?.url}
                        animation={2}
                        key={key}
                        onClick={handleMarker(marker)}
                        options={{
                            opacity: marker.active ? 1 : 0,
                        }}
                    // onPositionChanged={handlePositionChange}
                    />
                )
                : ''}
       </>
    )
}


InfoMarkersComponent.propTypes = {
    handleMarker: PropTypes.func,
}
export default React.memo(InfoMarkersComponent);