import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/styles/makeStyles';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReadMoreLinkComponent from './ReadMoreLinkComponent';
import NewsItemTitleStartComponent from './NewsItemTitleStartComponent';

const useStyles = makeStyles((theme) => ({
    img: {
        width: '100%',
        height: 'auto',
        maxHeight: 300,
        objectFit: 'cover',
        transition: 'all 0.2s ease-in',
    },
    title: {
        marginTop: 10,
        marginBottom: 10,
    },
    cont: {
        marginBottom: 20,
        '& .lazy-load-image-background': {
            width: '100%',
            height: 'auto',
            maxHeight: 300,
            overflow: 'hidden',
            marginRight: 20,
            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
        },
    },
    desc: {
        marginBottom: 10,
    }
}))

const ItemReadAlsoComponent = ({ src, to, title, description, date, startTitle }) => {
    const classes = useStyles();

    return (
        <ReadMoreLinkComponent to={to} date={date}>
            <Box className={classes.cont}>
                {startTitle
                    ? <NewsItemTitleStartComponent title={title} />
                    : null
                }
                {src
                    ? <LazyLoadImage
                        src={src}
                        className={classes.img}
                        alt={title}
                        effect='blur'
                    />
                    : null
                }
                {startTitle
                    ? null
                    : <Typography variant='h3' className={classes.title}>
                        {title}
                    </Typography>
                }
                <Typography className={classes.desc}>
                    {description}
                </Typography>

            </Box>
        </ReadMoreLinkComponent>
    )
}


ItemReadAlsoComponent.propTypes = {
    src: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    to: PropTypes.string,
}
export default React.memo(ItemReadAlsoComponent);