import React, { useCallback, useEffect, useState } from "react";
import { Box, Container, IconButton, makeStyles } from "@material-ui/core";
import NiolMainLogoSvg from "../svg/NiolMainLogoSvg";
import NavLiComponent from "./NavLiComponent";
import { useDispatch, useSelector } from "react-redux";
import { FETCH_MENU_REDUCER } from '../reducers/MainReducer'
import { Link } from "react-router-dom";
import { MAIN_ROUTE } from "../constants/routes";
import MenuIcon from '@material-ui/icons/Menu';
import SideMenuComponent from "./SideMenuComponent";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  cont: {
    display: "flex",
    height: 150,
    paddingTop: 60,
    paddingBottom: 40,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      height: 'auto',
      paddingTop: 20,
      paddingBottom: 20,
      top: 0,
      position: 'sticky',
      background: theme.palette.background.default,
      zIndex: 4,
    },
  },
  logo: {
    width: 100,
    marginRight: 40,
  },
  nav: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    // justifyContent: 'space-between'
  },
  iconBlock: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  icon: {
    width: 25,
    height: 25,
  }
}));

const AppBarComponent = (props) => {
  const classes = useStyles();
  const {t} = useTranslation();
  const { data } = useSelector(state => state.MainReducer);
  const [mobileSwipeShow, setMobileSwipeShow] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!data?.menu?.length)
      dispatch(FETCH_MENU_REDUCER())
  }, [data, dispatch])

  const handleShowSideMenu = useCallback(() => {
    setMobileSwipeShow(!mobileSwipeShow)
  }, [mobileSwipeShow])
  return (
    <>
      <Container className={classes.cont}>
        <Box>
          <Link to={MAIN_ROUTE} title={t('main_page.title')}>
            <NiolMainLogoSvg className={classes.logo} />
          </Link>
        </Box>
        <Box component="nav" className={classes.nav}>
          {data?.menu?.length
            ? data.menu.map(({ title, link, children, external_link }, key) => (
              <NavLiComponent key={key} title={title} links={children} external_link={external_link} slug={link}  />
            ))
            : null}
        </Box>
        <Box className={classes.iconBlock}>
          <IconButton color='primary' onClick={handleShowSideMenu} title='menu'>
            <MenuIcon className={classes.icon} />
          </IconButton>
        </Box>
      </Container>
      <SideMenuComponent open={mobileSwipeShow} handleClose={handleShowSideMenu} menu={data?.menu} />
    </>
  );
};

export default React.memo(AppBarComponent);
