import React from 'react'
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import SubtitleComponent from './SubtitleComponent';
import { useTranslation } from 'react-i18next';
import ItemReadAlsoComponent from './ItemReadAlsoComponent';
import ReadAlsoSkeletonComponent from './skeletonComponents/ReadAlsoSkeletonComponent';


const ReadAlsoComponent = ({ items, loading }) => {
    const { t } = useTranslation();

    return (
        <>
            {
                loading ?
                    <Box mb='20px' mt='20px'>
                        <SubtitleComponent title={t('main.read_also')} />
                        <ReadAlsoSkeletonComponent />
                        <ReadAlsoSkeletonComponent />
                    </Box>
                    : items?.length ?
                        <Box>
                            <SubtitleComponent
                                title={t('main.read_also')}
                            />
                            {items.map(({ title, to, src, description }, key) =>
                                <ItemReadAlsoComponent
                                    title={title}
                                    to={to}
                                    src={src}
                                    description={description}
                                    key={key}
                                />
                            )}
                        </Box>
                        : null
            }
        </>
    )
}


ReadAlsoComponent.propTypes = {
    items: PropTypes.array,
    loading: PropTypes.bool,
}
export default React.memo(ReadAlsoComponent);