import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import articleCss from '../constants/articleCss';

const useStyles = makeStyles((theme) => ({
    title: { 
        '& * ': {
            ...theme.typography.h2,
            color: theme.palette.primary.main,
        }
    },
    desc: {
        ...articleCss(theme),
    }
}))

const MainPopupComponent = ({ open, desc, title, onClose = () => {}}) => {
 const classes = useStyles();
const {t} = useTranslation();
    return (
        <Dialog open={open} maxWidth='md' onClose={onClose}>
            {title ? <DialogTitle  classes={{ root: classes.title}}>{title}</DialogTitle> : null}
            <DialogContent>
                <Typography
                    variant="body1"
                    className={classes.desc}
                    dangerouslySetInnerHTML={{ __html: desc }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {t('btns.close')}
          </Button>
            </DialogActions>
        </Dialog>
    )
}


MainPopupComponent.propTypes = {
    open: PropTypes.bool,
    desc: PropTypes.string,
    title: PropTypes.string,
    onClose: PropTypes.func,
}
export default React.memo(MainPopupComponent);