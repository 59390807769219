import React, { useCallback, useEffect, useState } from 'react';
import MainContainerComponent from '../../components/MainContainerComponent';
import HeroImage from '../../imgs/heroImage.jpg';
import HeroImageMobile from '../../imgs/heroImageMobile.jpg';
import { useTranslation } from 'react-i18next';
// import SectionComponent from './components/SectionComponent';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_HOME_DATA_REDUCER } from '../../reducers/HomePageReducer';
// import NewsListWrapperComponent from '../../components/NewsListWrapperComponent';
import SectionSkeletonComponent from '../../components/skeletonComponents/SectionSkeletonComponent';
import loadable from '@loadable/component';
import LazyImgComponent from '../../components/LazyImgComponent';
import MainPopupComponent from '../../components/MainPopupComponent';
import { useCookies } from 'react-cookie';
import RecommendComponent from '../../components/RecommendComponent';

const SectionComponent = loadable(() => import('./components/SectionComponent'));
const NewsListWrapperComponent = loadable(() => import('../../components/NewsListWrapperComponent'));


const MainPage = (props) => {
	const { data, loading } = useSelector((state) => state.HomePageReducer);
	const popupCookieName = 'popup-information-shown'
	const [cookies, setCookie] = useCookies([popupCookieName]);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);


	useEffect(() => {
		if (data?.information && !cookies[popupCookieName]) {
			setOpen(true);
		}
	}, [data, cookies])
	useEffect(() => {
		dispatch(FETCH_HOME_DATA_REDUCER());
	}, [dispatch]);

	const onCLose = useCallback(() => {
		const date = new Date();
		setOpen(false)
		setCookie(popupCookieName, true, {
			expires: new Date(date.setDate(date.getDate() + 1)),
		});
	}, [setCookie])
	return (
		<MainContainerComponent
			heroImg={HeroImage}
			mobileImg={HeroImageMobile}
			showTitle
			stopLoadingHero
			loading={loading}
			gallery={data?.gallery}
			shorts={data?.shorts}
			rightPart={
				<div>
					<RecommendComponent
						loading={loading}
						items={data?.recommend}
					/>
				</div>
			}
			rightStartPart={
				<div>
					{
						data?.featured?.length ? (
							data.featured.map(({ src, title, to }, key) => (
								<LazyImgComponent
									key={key}
									to={to}
									src={src}
									alt={title}
								/>
							))
						) : null
					}
				</div>
			}
			leftPart={
				<NewsListWrapperComponent firstLarge loading={loading} items={data?.news || []} />
			}
			title={t('main_page.title')}
		>
			{loading ? (
				<>
					<SectionSkeletonComponent />
					<SectionSkeletonComponent />
				</>
			) :
				data?.sections?.length ? (
					data.sections.map(({ items, title, links }, key) => (
						<SectionComponent
							key={key}
							items={items}
							title={title}
							breadcrumbs={links}
						/>
					))
				) : null
			}
			<MainPopupComponent
				open={open}
				onClose={onCLose}
				title={data?.information?.title}
				desc={data?.information?.desc}
			/>
		</MainContainerComponent>
	)
}

export default React.memo(MainPage)
