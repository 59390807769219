import React from 'react'
import {  Polyline} from '@react-google-maps/api';
import { useSelector } from 'react-redux';

const ZonesComponent = (props) => {
    const { data } = useSelector((state) => state.MapReducer)
    return (
        <>
            {data?.zone?.length
                ? data.zone.map((z, key) =>
                    z?.coords?.length
                        ? z.coords.map((item, keyItem) =>
                            <Polyline
                                key={`${key}-${keyItem}`}
                                options={{
                                    strokeColor: z.color,
                                    strokeWeight: 3,
                                    strokeOpacity: z.active ? 1 : 0
                                }}
                                path={item}
                            >
                            </Polyline>
                        )
                        : null
                )
                : null
            }
            {data?.main_zone?.length
                ? data.main_zone.map((z, key) =>
                    z?.coords?.length
                        ? z.coords.map((item, keyItem) =>
                            <Polyline
                                key={`${key}-${keyItem}`}
                                options={{
                                    strokeColor: z.color,
                                    strokeWeight: 3,
                                    strokeOpacity: z.active ? 1 : 0
                                }}
                                path={item}
                            >
                            </Polyline>
                        )
                        : null
                )
                : null
            }
        </>
    )
}

export default React.memo(ZonesComponent);