import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/styles/makeStyles'
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 7,
  },
  day: {
    marginBottom: 7,
    '& a': {
      textDecoration: 'none',
      color: theme.palette.text.primary,
      transition: 'all 0.2s ease-in',
      '&:hover': {
        color: theme.palette.primary.main,
      }
    }
  },
  cont: {
    marginBottom: 20,
    '&.MuiGrid-item:nth-of-type(2)': {
      marginBottom: 40,
    },
  },
}));

const FooterItemComponent = ({ sm = 4, title, street, contacts }) => {
  const classes = useStyles();
  return (
    <Grid item  sm={sm} xs={12} className={classes.cont}>
      <Typography className={classes.title}>{title}</Typography>
      {street ?
        <Typography className={classes.day}
          dangerouslySetInnerHTML={{ __html: street }}
        />
        : null}
      {
        contacts
          ? <Typography className={classes.day}
            dangerouslySetInnerHTML={{ __html: contacts }}
          />
        : null
      }  
    </Grid>
  )
}


FooterItemComponent.propTypes = {
  title: PropTypes.string,
  sm: PropTypes.number,
  street: PropTypes.string,
  contacts: PropTypes.string,
}
export default React.memo(FooterItemComponent);