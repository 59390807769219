import React from 'react'
import PropTypes from 'prop-types';
import PaginationComponent from './PaginationComponent';
import NewsListItemSkeletonComponent from './skeletonComponents/NewsListItemSkeletonComponent';
import NewsListWrapperComponent from './NewsListWrapperComponent';
import ReadAlsoComponent from './ReadAlsoComponent';
import RecommendComponent from './RecommendComponent';
import MainContainerComponent from './MainContainerComponent';
import HeroImage from '../imgs/pagesHero.jpg';
import HeroImageMobile from '../imgs/pagesHeroMobile.jpg';

const ListComponent = ({ title, titlePagination, breadcrumbs, link, loading, children, loadingPagination, data, handleChangePage = () => { } }) => {
    return (
        <MainContainerComponent
            loading={loading}
            title={title}
            gallery={data?.gallery}
            smallHero
            breadcrumbs={[
                ...(data?.breadcrumbs?.length ? data?.breadcrumbs : []),
                {
                    title: breadcrumbs
                }
            ]}
            heroImg={HeroImage}
            mobileImg={HeroImageMobile}
            shorts={data?.shorts}
            showTitle
            leftPart={<>
                {
                    loading || loadingPagination
                        ? <>
                            {children}
                            <NewsListItemSkeletonComponent />
                            <NewsListItemSkeletonComponent />
                            <NewsListItemSkeletonComponent />
                            <NewsListItemSkeletonComponent />
                            <NewsListItemSkeletonComponent />
                        </>
                        : <>
                            {children}
                            <NewsListWrapperComponent items={data?.data || []} />
                        </>
                }
                {
                    !loading
                        ? <PaginationComponent
                            total={data?.meta?.last_page}
                            onChange={handleChangePage}
                            title={titlePagination}
                            current={data?.meta?.current_page}
                        />
                        : null
                }
            </>}
            rightPart={<>
                <ReadAlsoComponent
                    loading={loading}
                    items={data.also_read || []}
                />
                <RecommendComponent
                    loading={loading}
                    items={data?.recommend}
                />
            </>}
        />
    )
}


ListComponent.propTypes = {
    title: PropTypes.string,
    breadcrumbs: PropTypes.string,
    titlePagination: PropTypes.string,
    link: PropTypes.string,
    loading: PropTypes.bool,
    loadingPagination: PropTypes.bool,
    data: PropTypes.object,
    handleChangePage: PropTypes.func,
}
export default React.memo(ListComponent);