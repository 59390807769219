
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/styles/withStyles'

const ButtonComponent = withStyles((theme) => ({
  root: {
    borderRadius: 50,
    padding: '12px 50px',
    boxShadow: 'none',
  },
}))(Button);


export default ButtonComponent;