import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import MainContainerComponent from '../../components/MainContainerComponent';
import { FETCH_PPN_REDUCER } from '../../reducers/PpnReducer';
import HeroImage from '../../imgs/pagesHero.jpg';
import HeroImageMobile from '../../imgs/pagesHeroMobile.jpg';
import ChoiceItemSkeletonComponent from '../../components/skeletonComponents/ChoiceItemSkeletonComponent';
import { Grid, makeStyles } from '@material-ui/core';
import ChoiceContentComponent from '../../components/ChoiceContentComponent';

const useStyles = makeStyles((theme) => ({
    link: {
        color: 'inherit',
        textDecoration: 'none',
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
    contItem: {
        padding: 10,
        [theme.breakpoints.down('xs')]: {
            padding: 0,
        }
    },
    choiceCont: {
        marginTop: 80,
        [theme.breakpoints.down('xs')]: {
            marginTop: 0,
        }
    }
}))
const PpnPage = (props) => {
    const classes = useStyles();
    const { data, loading } = useSelector((state) => state.PpnReducer);
    const dispatch = useDispatch();
    const { slug } = useParams();

    useEffect(() => {
        dispatch(FETCH_PPN_REDUCER(slug))
    }, [dispatch, slug])
    return (
        <MainContainerComponent
            title={data?.title}
            smallHero
            showTitle
            breadcrumbs={[...(data?.breadcrumbs || []), {title: data?.title}]}
            heroImg={HeroImage}
            mobileImg={HeroImageMobile}
            hiddenAccess
        >
            <Grid container className={classes.choiceCont}>
                {loading
                    ? <>
                        <ChoiceItemSkeletonComponent />
                        <ChoiceItemSkeletonComponent />
                        <ChoiceItemSkeletonComponent />
                    </>
                    : data?.items?.length
                        ? data.items.map((item, key) =>
                            <Grid item md={4} sm={6} xs={12} key={key} className={classes.contItem}>
                                {item.external_link
                                    ? <a href={item.to} className={classes.link} title={item.title}>
                                        <ChoiceContentComponent
                                            title={item.title}
                                            image={item.image}
                                            color={item.color}
                                        />
                                    </a>
                                    : <Link to={item.to} className={classes.link} title={item.title}>
                                        <ChoiceContentComponent
                                            title={item.title}
                                            image={item.image}
                                            color={item.color}
                                        />
                                    </Link>
                                }

                            </Grid>
                        )
                        : null
                }
            </Grid>
        </MainContainerComponent>
    )
}


export default PpnPage;