import React, { useCallback } from 'react'
import { Box, IconButton, makeStyles, Paper, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import StreetPopupItemComponent from './StreetPopupItemComponent';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles((theme) => ({
    streetPopup: {
        padding: 20,
        width: 350,
        position: 'absolute',
        background: theme.palette.background.default,
        top: 60,
        left: 10,
        zIndex: 200,
        borderRadius: 19,
        [theme.breakpoints.down('xs')]: {
            width: 'calc(100% - 20px)',
        }
    },
    popupTitle: {
        marginRight: 10,
        fontSize: 22,
    },
    pointSvg: {
        width: 50,
        height: 50,
        objectFit: 'contain'
    },
    hr: {
        height: 10,
        width: 50,
        margin: 0,
        marginTop: 5,
        border: 'none'
    }
}))

const StreetPopupComponent = ({ street, cityCoords, handleClose = () => {} }) => {
    const { loadingStreet } = useSelector((state) => state.MapReducer)
    const classes = useStyles();
    const { t } = useTranslation();

    const handleClick = useCallback(() => {
        handleClose(true)
    }, [handleClose])
    return (
        <Paper className={classes.streetPopup} elevation={3}>
            <Box display='flex' justifyContent='flex-end'>
                <IconButton size='small' onClick={handleClick}>
                    <CancelIcon />
                </IconButton>
            </Box>
            <Box display='flex'  alignItems='center' mb='20px'>
                <Typography color='primary' className={classes.popupTitle}>{street?.title}</Typography>
                {street.icon_url
                    ? <img src={street.icon_url} alt={street.title} className={classes.pointSvg} />
                    : <hr className={classes.hr} style={{ background: street.color }} />
                    // : <PointSvg fill={street.color} className={classes.pointSvg} />
                }
            </Box>
            {street?.price ? <StreetPopupItemComponent
                loading={loadingStreet}
                name={t('map_page.price_name')}
                title={street?.price || '0 zł/h'}
            />
                : null
            }
            <StreetPopupItemComponent
                loading={loadingStreet}
                name={t('map_page.time_name')}
                title={`${street?.timeWork || t('map_page.all_time')}`}
            />
            {street?.description ? <StreetPopupItemComponent
                withoutFlex
                loading={loadingStreet}
                name={t('map_page.description_name')}
                title={street?.description}
            /> : null}
        </Paper>
    )
}


StreetPopupComponent.propTypes = {
    street: PropTypes.object,
    handleClose: PropTypes.func,
}
export default React.memo(StreetPopupComponent);