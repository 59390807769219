import React from 'react'
import Box from '@material-ui/core/Box'
import PropTypes from 'prop-types';
import SubtitleComponent from './SubtitleComponent';
import { useTranslation } from 'react-i18next';
import RecomSkeletonComponent from './skeletonComponents/RecomSkeletonComponent';
import LazyImgComponent from './LazyImgComponent';


const RecommendComponent = ({ items, loading }) => {
    const { t } = useTranslation();
    return (
        <>
            {
                loading ?
                    <Box mb='20px'>
                        <SubtitleComponent title={t('main.recommend')} />
                        <RecomSkeletonComponent />
                    </Box>
                    : items?.length
                        ?
                        <Box mb='20px'>
                            <SubtitleComponent title={t('main.recommend')} />
                            <Box>
                                {
                                    items.map(({ src, to, alt }, key) =>
                                        <LazyImgComponent
                                            external_link
                                            key={key}
                                            src={src}
                                            to={to}
                                            alt={alt}
                                        />
                                    )

                                }
                            </Box>
                        </Box>
                        : null
            }
        </>
    )
}


RecommendComponent.propTypes = {
    items: PropTypes.array,
    loading: PropTypes.bool,
}
export default React.memo(RecommendComponent);