import React, { useCallback } from 'react'
import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ButtonComponent from '../../../components/ButtonComponent';
import { classNames } from '../../../functions';
import { useTranslation } from 'react-i18next';
import CustomTextFieldComponent from '../../../components/CustomTextFieldComponent';
import { useDispatch, useSelector } from 'react-redux';
import { SEND_MAP_REDUCER } from '../../../reducers/MapReducer';
import {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';

const useStyles = makeStyles((theme) => ({
    autocompleteBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 40,

        [theme.breakpoints.down('xs')]: {
            display: 'block',
        },
    },
    resetBtn: {
        transition: 'all 0.2s ease-in',
        [theme.breakpoints.down('xs')]: {
            marginTop: 20,
        },
    },
    activeResetBtn: {
        opacity: 1,
    },
    disableResetBtn: {
        opacity: 0,
    },
    autocomplete: {
        width: 300,
        [theme.breakpoints.down('xs')]: {
            minWidth: '100%',
            width: '100%',
        }
    },
    textField: {
        minWidth: 400,

        [theme.breakpoints.down('sm')]: {
            minWidth: 300,
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: '100%',
            width: '100%',
        }
    },
    autocompleteCircle: {
        width: 5,
        height: 5,
        marginRight: 5,
        borderRadius: '50%'
    },
}))

const MapAutocompleterComponent = ({ 
    setStreet = () => {},
    setActiveCoords = () => {},
    setZoom = () => {},
    setActiveMarker = () => {},
    defaultZoom,
    autocompleteValue,
    setAutocompleteValue = () => {},
    street
}) => {
    const { streets, loadingStreet,  } = useSelector((state) => state.MapReducer)
    const dispatch =useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();

    const handleGetStreets = useCallback((e, v) => {
        if (e?.target?.value?.length > 2) {
            dispatch(SEND_MAP_REDUCER(e.target.value))
        }
    }, [dispatch])
    const changeStreet = useCallback((value, coords, zoom = 18) => {
        setActiveCoords({ ...value, coords });
        setActiveMarker({});
        setStreet(value)
        setZoom(zoom)
    }, [setZoom, setStreet, setActiveMarker, setActiveCoords])

    const handleResetCoords = useCallback(() => {
        setActiveCoords({});
        setStreet({});
        setActiveMarker({});
        setAutocompleteValue(null);
        setZoom(defaultZoom)
    }, [setZoom, setActiveMarker, setAutocompleteValue, setStreet, setActiveCoords, defaultZoom])

    const handleGetStreet = useCallback((option, value) => {
        if (value) {
            setAutocompleteValue(value);
            const fullStreet = `${value?.title} ${value?.title.includes('Szczecin') ? '' : ', Szczecin'}`
            if (value?.lat && value?.lng) {
                changeStreet(value, { lat: value?.lat, lng: value?.lng })
            }
            else {
                geocodeByAddress(fullStreet).then(results => {
                    return getLatLng(results[0])
                })
                    .then(latLng => {
                        changeStreet(value, latLng)
                    })
                    .catch(error => {
                    });
            }
        }
        return option === value
    }, [changeStreet, setAutocompleteValue])


    return (
        <Box className={classes.autocompleteBox}>
            <Autocomplete
                id="combo-box-demo"
                options={loadingStreet ? [] : streets}
                openOnFocus
                loading={loadingStreet}
                loadingText={<Box>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </Box>}
                noOptionsText={t('form.no_options')}
                disableClearable
                clearOnBlur={false}
                filterOptions={(options, state) => options}
                getOptionLabel={(option) => option.title}
                renderOption={(option) =>
                    <React.Fragment>
                        <span className={classes.autocompleteCircle} style={{ background: option.color }}></span>
                        {option.title}
                    </React.Fragment>
                }
                getOptionSelected={(option, value) => option.title}
                onChange={handleGetStreet}
                value={autocompleteValue}
                className={classes.autocomplete}
                // style={{ width: 300 }}
                renderInput={(params) => <CustomTextFieldComponent {...params} className={classes.textField} onChange={handleGetStreets} placeholder={t('form.street')} variant="outlined" />}
            />
            <ButtonComponent
                variant='contained'
                color='primary'
                className={classNames([classes.resetBtn, street?.title ? classes.activeResetBtn : classes.disableResetBtn])}
                onClick={handleResetCoords}
            >
                {t('btns.reset')}
            </ButtonComponent>
        </Box>
    )
}


MapAutocompleterComponent.propTypes = {
    name: PropTypes.string,
}
export default React.memo(MapAutocompleterComponent);