import { FEES_API } from "../constants/api";
import { GET } from "../functions/superagentSending";

const initialState = {
    loading: false,
    data: {},
    error: '',
};


const FETCH_FEES_REQUEST = 'FETCH_FEES_REQUEST'
const FETCH_FEES_SUCCESS = 'FETCH_FEES_SUCCESS'
const FETCH_FEES_FAILURE = 'FETCH_FEES_FAILURE'


const fetchFeesRequest = () => ({
    type: FETCH_FEES_REQUEST
})

const fetchFeesSuccess = data => ({
    type: FETCH_FEES_SUCCESS,
    payload: data
})


const fetchFeesFailure = error => ({
    type: FETCH_FEES_FAILURE,
    payload: error
})

const FeesReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_FEES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_FEES_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload,
                error: '',
            }
        case FETCH_FEES_FAILURE:
            return {
                loading: false,
                data: {},
                error: payload
            }
        default:
            return state
    }
}

export const FETCH_FEES_REDUCER = (slug) => {
    return (dispatch) => {
        dispatch(fetchFeesRequest())
        GET(`${FEES_API}/${slug}`).then((success) => {
            dispatch(fetchFeesSuccess(success?.body?.data))
        }).catch((error) => {
            dispatch(fetchFeesFailure(error))
            dispatch(fetchFeesFailure(''))
        })
    }
}

export default FeesReducer