import React, { useCallback } from 'react'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SideMenuItemComponent from './SideMenuItemComponent';
import NiolMainLogoSvg from "../svg/NiolMainLogoSvg";
import { Link } from 'react-router-dom';
import { MAIN_ROUTE } from '../constants/routes';
import IconButton  from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { useTranslation } from 'react-i18next';
import AccessContentComponent from './AccessContentComponent';
import SearchComponent from './SearchComponent';
import LngsComponent from './LngsComponent';

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: theme.palette.background.default,
        overflowX: 'hidden',
        height: '100%',
        maxHeight: '100vh',
        top: 0,
        bottom: 0,
        border: 'none',
    },
    list: {
        width: 320,
    },
    logo: {
        width: 100,
        marginLeft: 16,
    },
    logoCont: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 20,
        marginBottom: 20,
    },
    icon: {
        width: 25,
        height: 25,
    }
}))

const SideMenuComponent = ({ open, menu, handleClose = () => { }, onOpen = () => { } }) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const onClose = useCallback(() => {
        document.querySelector('body').style.overflow = 'auto'
        handleClose()
    }, [handleClose])
    return (
      <SwipeableDrawer
        anchor="left"
        open={open}
        hysteresis={0.4}
        onClose={onClose}
        onOpen={onClose}
        classes={{
          paper: classes.paper,
        }}
      >
        <Box>
          <Container className={classes.mainContainer}>
            <Box className={classes.logoCont}>
              <Link to={MAIN_ROUTE} title={t('main_page.title')}>
                <NiolMainLogoSvg className={classes.logo} />
              </Link>
              <IconButton color="primary" onClick={onClose}>
                <MenuIcon className={classes.icon} />
              </IconButton>
            </Box>
            <Box className={classes.listCont}>
              <List className={classes.list}>
                {menu?.length
                  ? menu.map((item, key) => (
                      <SideMenuItemComponent
                        handleClose={onClose}
                        key={key}
                        title={item.title}
                        link={item.link}
                        external_link={item.external_link}
                        items={item.children}
                      />
                    ))
                  : ''}
              </List>
            </Box>
            <Box>
              <AccessContentComponent />
              <LngsComponent />
              <SearchComponent />
            </Box>
          </Container>
        </Box>
      </SwipeableDrawer>
    );
}


export default React.memo(SideMenuComponent);