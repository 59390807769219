import * as React from "react"

function PlLngSvg(props) {
    return (
        <svg
            id="PlLngSvg_Warstwa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x={0}
            y={0}
            viewBox="0 0 40 40"
            xmlSpace="preserve"
            {...props}
        >
            <style>{".PlLngSvg_st3{fill:#c0bdbd}"}</style>
            <circle cx={20} cy={20} r={20} fill="#f1f1f1" />
            <defs>
                <circle id="PlLngSvg_SVGID_1_" cx={20} cy={20} r={17.8} />
            </defs>
            <clipPath id="PlLngSvg_SVGID_2_">
                <use xlinkHref="#PlLngSvg_SVGID_1_" overflow="visible" />
            </clipPath>
            <g clipPath="url(#PlLngSvg_SVGID_2_)">
                <path
                    d="M48.1 1.9v17.8h-.2c-.5 0-.9-.2-1.4-.2H-8.9c-.4 0-.7 0-1 .1h-.2V1.8c.1 0 .1 0 .1-.1h57.8c.1.1.2.2.3.2z"
                    fill="#fff"
                />
                <path
                    className="PlLngSvg_st3"
                    d="M47.9 19.7h.2v17.4c0 .5-.1.6-.6.6h-57.6v-18h.2v17c0 .7.2.9.9.9h56.5c.4.1.5-.1.5-.5-.1-5.8-.1-11.6-.1-17.4zM-9.9 1.7c0 .1 0 .1-.1.1s0-.1.1-.1zM48.1 1.9c-.1 0-.1 0-.1-.1.1-.1.1 0 .1.1z"
                />
                <path
                    d="M47.9 19.7V37c0 .4-.1.6-.5.5H-9.1c-.7 0-.9-.2-.9-.9v-17c.3-.1.7-.1 1-.1h55.4c.6.1 1.1.3 1.5.2z"
                    fill="#c31918"
                />
            </g>
            <radialGradient
                id="PlLngSvg_SVGID_3_"
                cx={20.229}
                cy={8430.724}
                r={23.58}
                gradientTransform="translate(0 -8421.642)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0} stopColor="#fff" stopOpacity={0} />
                <stop offset={0.157} stopColor="#fdfcfc" stopOpacity={0.157} />
                <stop offset={0.29} stopColor="#f8f2f1" stopOpacity={0.29} />
                <stop offset={0.414} stopColor="#efe1e0" stopOpacity={0.414} />
                <stop offset={0.532} stopColor="#e2c9c7" stopOpacity={0.533} />
                <stop offset={0.647} stopColor="#d1aaa7" stopOpacity={0.648} />
                <stop offset={0.759} stopColor="#bd8480" stopOpacity={0.76} />
                <stop offset={0.868} stopColor="#a45852" stopOpacity={0.87} />
                <stop offset={0.974} stopColor="#89251e" stopOpacity={0.975} />
                <stop offset={0.998} stopColor="#821810" />
            </radialGradient>
            <circle cx={20} cy={20} r={17.8} fill="url(#PlLngSvg_SVGID_3_)" />
        </svg>
    )
}

export default PlLngSvg
