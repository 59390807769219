import React from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import { Link } from 'react-router-dom';
import { classNames } from '../functions';

const useStyles = makeStyles((theme) => ({
    link: {
        textDecoration: 'none',
        color: theme.palette.text.primary,
        transition: 'all 0.2s ease-in',
        '&:hover': {
            color: theme.palette.primary.main,
        }
    }
}))

const LinkComponent = ({ to, className = '', children, external_link, title }) => {
    const classes = useStyles();

    return (
        <>
            {external_link
                ? <a className={classNames([classes.link, className])} href={to} target="_blank" rel="noreferrer" title={title}>{children}</a>
                : <Link className={classNames([classes.link, className])} to={to} >{children}</Link>
            }
        </>
    )
}

export default React.memo(LinkComponent);