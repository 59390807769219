import React, { useCallback, useMemo } from 'react'
import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/styles/makeStyles'
import IconButton from '@material-ui/core/IconButton'
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_MAIN_REDUCER } from '../reducers/MainReducer';

const useStyles = makeStyles((theme) => ({
    icon: {
        // width: 50,
        // height: 50,
        width: 30,
        height: 30,
    },
    cont: {
        margin: '0px 5px',
        maxWidth: 100,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }
}))

const AccContBtnComponent = ({ Icon, title, ActiveIcon, type, onClick = () => {} }) => {
    const classes = useStyles();
    const mainReducer = useSelector(state => state.MainReducer.data);
    const dispatch = useDispatch();
    const active = useMemo(() => mainReducer[type], [type, mainReducer])
    const handleUpdateType = useCallback(() => {
        onClick();
        const newValue = active ? '' : true
        localStorage.setItem(type, newValue)
        dispatch(UPDATE_MAIN_REDUCER(type, newValue))
    }, [type, active, dispatch, onClick])
    return (
        <Box className={classes.cont}>
            <IconButton color='inherit' onClick={handleUpdateType} title={title} >
                {active
                    ? <ActiveIcon className={classes.icon} />
                    : <Icon className={classes.icon} />
                }
            </IconButton>
        </Box>
    )
}


AccContBtnComponent.propTypes = {
    Icon: PropTypes.object,
    ActiveIcon: PropTypes.object,
    title: PropTypes.string,
    type: PropTypes.string,
}
export default React.memo(AccContBtnComponent);