import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/styles/makeStyles';
import PropTypes from 'prop-types';
import MainContainerComponent from './MainContainerComponent';
import HeroImage from '../imgs/404.jpg';

const useStyles = makeStyles((theme) => ({
    number: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontSize: 260,
        fontWeight: 'bold',
        lineHeight: 1,
        [theme.breakpoints.down('xs')]: {
            fontSize: 140,
        }
    },
    text: {
        textAlign: 'center',
        fontSize: 22,
    }
}))

const ErrorComponent = ({ number, title}) => {
 const classes = useStyles();

    return (
        <MainContainerComponent
            heroImg={HeroImage}
            mobileImg={HeroImage}
            // title={title}
            // hiddenAccess
            smallHero
            leftPart={
                <Box>
                    <Typography className={classes.number}>{number}</Typography>
                    <Typography className={classes.text}>{title}</Typography>
                </Box>
            }
            breadcrumbs={[{
                title: number,
            }]}
        />
    )
}


ErrorComponent.propTypes = {
    number: PropTypes.string,
    title: PropTypes.string,
}
export default React.memo(ErrorComponent);