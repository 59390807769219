import React from 'react'
import {  useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import { MarkerClusterer, Marker } from '@react-google-maps/api';
import { useSelector } from 'react-redux';

const ParkingsComponent = ({ activeParkings }) => {
    const {  data } = useSelector((state) => state.MapReducer)
    const theme = useTheme();
    return (
        <>
            {data?.parking?.length && activeParkings
                ?
                <MarkerClusterer
                    options={
                        {
                            styles: [
                                {
                                    url: `${window.location.origin}/imgs/clusterMap.svg`,
                                    width: 55,
                                    height: 55,
                                    // textSize: 15,
                                    // fontWeight: 'bold',
                                    fontFamily: theme.typography.fontFamily,
                                    anchorText: [5, 5],
                                    textColor: 'white'
                                },
                            ],
                        }
                    }
                >
                    {clusterer => [
                        ...(data?.parking.map((p, key) => (
                            p?.coords?.length
                                ? p.coords.map((coords, coordsKey) =>
                                    <Marker
                                        key={`${key}-${coordsKey}`}
                                        position={coords}
                                        clusterer={clusterer}
                                        icon={p.url}
                                        animation={2}
                                    // onPositionChanged={handlePositionChange}
                                    />
                                )
                                : []
                        ))),

                    ]

                    }
                </MarkerClusterer>
                : ''
            }
        </>
    )
}


ParkingsComponent.propTypes = {
    activeParkings: PropTypes.bool,
}
export default React.memo(ParkingsComponent);