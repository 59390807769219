import * as React from "react"

function EnLngSvg(props) {
    return (
        <svg
            id="EnLngSvg_Warstwa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x={0}
            y={0}
            viewBox="0 0 40 40"
            xmlSpace="preserve"
            {...props}
        >
            <style>
                {
                    ".EnLngSvg_st2{fill:#c81530}.EnLngSvg_st3{fill:#fffefe}.EnLngSvg_st4{fill:#232f5d}"
                }
            </style>
            <circle cx={20} cy={20} r={20} fill="#f1f1f1" />
            <defs>
                <circle id="EnLngSvg_SVGID_1_" cx={20} cy={20} r={17.8} />
            </defs>
            <clipPath id="EnLngSvg_SVGID_2_">
                <use xlinkHref="#EnLngSvg_SVGID_1_" overflow="visible" />
            </clipPath>
            <g clipPath="url(#EnLngSvg_SVGID_2_)">
                <path
                    className="EnLngSvg_st2"
                    d="M23.6 38h-7.2V23.9c0-.3-.1-.3-.3-.3H-16v-7.2h32.1c.3 0 .3-.1.3-.3V2.4c0-.3.1-.3.3-.3h6.5c.3 0 .3 0 .3.3v13.7c0 .3.1.4.4.4h31.7c.3 0 .4.1.4.4v6.5c0 .3-.1.4-.4.4H23.9c-.3 0-.3.1-.3.3V38z"
                />
                <path
                    className="EnLngSvg_st3"
                    d="M23.6 38V23.9c0-.3.1-.3.3-.3h31.7c.3 0 .4-.1.4-.4v-6.5c0-.3-.1-.4-.4-.4H23.9c-.3 0-.4-.1-.4-.4V2.2c0-.3-.1-.3-.3-.3h-6.5c-.3 0-.3 0-.3.3v13.7c0 .3-.1.3-.3.3H-16v-2.4H-.2c0-.1-.1-.1-.1-.1-5.2-2.6-10.3-5.2-15.5-7.7-.1 0-.1-.1-.2-.1V4.5c.1 0 .2.1.3.1l18 9c.2.1.4.1.6.1h5v-.1C-.1 9.6-8 5.6-16 1.7v-.1h71.8c.2 0 .3.1.3.3v35.5c0 .1.1.2-.1.3v-2.4c0-.2 0-.3-.2-.4-6.1-3-12.1-6.1-18.2-9.1-.1-.1-.3-.1-.5-.1H32c.2.1.2.1.3.2 7.8 3.9 15.5 7.8 23.3 11.6.1 0 .2.1.3.2h-8c-.1-.1-.2-.1-.3-.2-7.1-3.6-14.2-7.1-21.4-10.7-.1 0-.2-.1-.3-.1v11c-.7.3-1.5.3-2.3.3zM26 13c7.3-3.7 14.6-7.3 21.9-11H26.2c-.2 0-.2.1-.2.3V13zM-7.9 2s0 .1 0 0C-.6 5.7 6.6 9.3 13.9 13v-.3V2.4c0-.3-.1-.3-.3-.3H-7.7c0-.1-.1-.1-.2-.1zm63.8.1h-5.2c-.1 0-.2.1-.4.1-7.8 3.9-15.7 7.8-23.5 11.7-.1 0-.2 0-.2.2h4.9c.2 0 .4 0 .6-.1 6-3 11.9-6 17.8-8.9l6-3zM40.1 14c.1.1.1.1 0 0 5.3.1 10.4.1 15.6.1.2 0 .2-.1.2-.3V6.4c0-.1 0-.2-.1-.2-5.1 2.5-10.4 5.1-15.7 7.8zM56 34c0-.1 0-.1 0 0v-7.7c0-.3-.1-.2-.3-.2H40.1c5.3 2.6 10.6 5.2 15.9 7.9z"
                />
                <path
                    className="EnLngSvg_st3"
                    d="M-16.1 23.6H16c.3 0 .3.1.3.3V38h-2.4V27c-.1.1-.2.1-.3.1C6.5 30.7-.6 34.2-7.7 37.8c-.1 0-.2.1-.3.1h-2.6c0-.1.2-.1.3-.2C-2.6 34 5.2 30.1 13 26.2c.1 0 .2-.1.3-.2h-5c-.2 0-.4 0-.6.1l-23.4 11.7c-.1 0-.2.1-.2.2-.2 0-.1-.1-.1-.2V34c.1.1.2 0 .2-.1 5.2-2.6 10.3-5.2 15.5-7.7.1 0 .1 0 .1-.1H-16c-.1-.9-.1-1.7-.1-2.5z"
                />
                <path
                    className="EnLngSvg_st4"
                    d="M26 38V27c.1.1.2.1.3.1 7.1 3.6 14.2 7.1 21.4 10.7.1 0 .2.1.3.2H26zM-7.9 38c.1 0 .2-.1.3-.1 7.1-3.6 14.2-7.1 21.3-10.7.1 0 .2-.1.3-.1v11C6.7 38-.6 38-7.9 38z"
                />
                <path
                    className="EnLngSvg_st2"
                    d="M-16 38c.1-.1.2-.1.2-.2L7.6 26.1c.2-.1.4-.1.6-.1h5c-.2.1-.2.1-.3.2C5.1 30.1-2.6 34-10.4 37.8c-.1 0-.2.1-.3.2H-16z"
                />
                <path
                    className="EnLngSvg_st4"
                    d="M-16.1 6.1c.1-.1.2 0 .2.1 5.2 2.6 10.3 5.2 15.5 7.7l.1.1h-15.8V6.1zM-16.1 26H-.3c0 .1-.1.1-.1.1-5.2 2.6-10.3 5.2-15.5 7.7-.1 0-.1.1-.2.1V26z"
                />
                <path
                    className="EnLngSvg_st2"
                    d="M55.9 38c-.1-.1-.2-.1-.3-.2-7.8-3.9-15.5-7.8-23.3-11.6-.1 0-.2-.1-.3-.2h5.1c.2 0 .3 0 .5.1 6.1 3 12.1 6.1 18.2 9.1.2.1.2.2.2.4 0 .8 0 1.6-.1 2.4.1 0 .1 0 0 0zM-16.1 2c8 4 15.9 8 23.9 11.9v.1h-5c-.2 0-.4 0-.6-.1l-18-9c-.1-.1-.2-.1-.3-.1V2z"
                />
                <path
                    className="EnLngSvg_st4"
                    d="M26 13v-1.6-9.1c0-.2 0-.3.2-.3h21.7C40.6 5.7 33.3 9.4 26 13zM-7.9 2h21.6c.2 0 .3 0 .3.3V12.9C6.7 9.4-.6 5.7-7.9 2c0 .1 0 0 0 0z"
                />
                <path
                    className="EnLngSvg_st2"
                    d="M55.9 2.1c-2.1 1-4.1 2-6.1 3C44 8 38.1 11 32.3 13.9c-.2.1-.4.1-.6.1h-4.9c0-.1.1-.1.2-.2C34.8 9.9 42.7 6 50.5 2.1c.1-.1.2-.1.4-.1h5c-.1 0-.1 0 0 .1z"
                />
                <path
                    className="EnLngSvg_st4"
                    d="M40.1 14c5.3-2.6 10.5-5.3 15.8-7.9.1.1.1.2.1.2v7.4c0 .2 0 .3-.2.3H40.1zM56 34c-5.3-2.6-10.6-5.3-15.8-7.9.1-.1.2 0 .2 0h15.4c.1 0 .3 0 .3.2v7.6c-.1 0-.1 0-.1.1z"
                />
            </g>
            <radialGradient
                id="EnLngSvg_SVGID_3_"
                cx={20.242}
                cy={8430.754}
                r={23.58}
                gradientTransform="translate(0 -8421.642)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0} stopColor="#fff" stopOpacity={0} />
                <stop offset={0.164} stopColor="#fcfcfc" stopOpacity={0.098} />
                <stop offset={0.302} stopColor="#f1f1f5" stopOpacity={0.182} />
                <stop offset={0.431} stopColor="#e0e0e7" stopOpacity={0.259} />
                <stop offset={0.554} stopColor="#c8c7d5" stopOpacity={0.334} />
                <stop offset={0.674} stopColor="#a8a7bd" stopOpacity={0.405} />
                <stop offset={0.79} stopColor="#82809f" stopOpacity={0.475} />
                <stop offset={0.902} stopColor="#55537d" stopOpacity={0.543} />
                <stop offset={0.998} stopColor="#29265b" stopOpacity={0.6} />
            </radialGradient>
            <circle cx={20} cy={20} r={17.8} fill="url(#EnLngSvg_SVGID_3_)" />
        </svg>
    )
}

export default EnLngSvg
