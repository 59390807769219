import React from 'react'
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import articleCss from '../../../constants/articleCss';

const useStyles = makeStyles((theme) => ({
    name: {
        color: theme.palette.gray.text,
        marginRight: 5,
    },
    skeleton: {
        width: '50%',
    },
    cont: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            display: 'block',

        }
    },
    title: {
        ...articleCss(theme),
        marginBottom: 0,
        '& p': {
            marginTop: '5px !important',
            marginBottom: '5px !important',
        }
    }
}))

const StreetPopupItemComponent = ({ name, title, loading, withoutFlex }) => {
    const classes = useStyles();

    return (
        <Box className={withoutFlex ? '' : classes.cont}>
            <Typography className={classes.name}>{name}</Typography>
            {loading
                ? <Skeleton className={classes.skeleton} />
                : <Typography
                    className={classes.title}
                    dangerouslySetInnerHTML={{ __html: title }}
                />
            }
        </Box>
    )
}


StreetPopupItemComponent.propTypes = {
    name: PropTypes.string,
    loading: PropTypes.bool,
    withoutFlex: PropTypes.bool,
    title: PropTypes.string,
}
export default React.memo(StreetPopupItemComponent);