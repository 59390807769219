import React from 'react'
import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/styles/makeStyles'
import PropTypes from 'prop-types';
// import SubtitleComponent from './SubtitleComponent';
import AccContBtnComponent from './AccContBtnComponent';
// import HeadsetIcon from '@material-ui/icons/Headset';
// import ToggleOffIcon from '@material-ui/icons/ToggleOff';
// import ToggleOnIcon from '@material-ui/icons/ToggleOn';
// import Brightness6Icon from '@material-ui/icons/Brightness6';
// import Brightness7Icon from '@material-ui/icons/Brightness7';
// ACCESS_LISTEN
import { ACCESS_CONTARST, ACCESS_TEXT } from '../constants';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import FormatSizeIcon from '@material-ui/icons/FormatSize';
const useStyles = makeStyles((theme) => ({
    btnsCont: {
        display: 'flex',
        // justifyContent: 'space-between',
        alignItems: 'base-line',
    },
    content: {
        opacity: 0,
    }
}))

const AccessContentComponent = (props) => {
    const classes = useStyles();
    // const handleClick = useCallback(() => {
    // }, [])
    return (
        <Box>
            {/* Ułatwienie w dostępie do treści */}
            {/* <SubtitleComponent title={t('main.access_content')} /> */}
            <Box className={classes.btnsCont}>
                <AccContBtnComponent
                    Icon={FormatSizeIcon}
                    ActiveIcon={FormatSizeIcon}
                    type={ACCESS_TEXT}
                    // title={t('main.access_text')}
                />
                <AccContBtnComponent
                    Icon={VisibilityIcon}
                    ActiveIcon={VisibilityOffIcon}
                    type={ACCESS_CONTARST}
                    // title={t('main.access_contrast')}
                />
                {/* <AccContBtnComponent
                    onClick={handleClick}
                    Icon={HeadsetIcon}
                    ActiveIcon={HeadsetIcon}
                    type={ACCESS_LISTEN}
                    title={t('main.access_listen')}
                /> */}
            </Box>
        </Box>
    )
}


AccessContentComponent.propTypes = {
    name: PropTypes.string,
}
export default React.memo(AccessContentComponent);