import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
// MAP_ROUTE
import { MAP_ROUTE, ARTICLE_ROUTE, COMPLAINT_ROUTE, FEES_ROUTE, MAP_OLD_ROUTE, REGULATIONS_ROUTE, MAIN_ROUTE, CONTACT_ROUTE, PPN_ROUTE, ERROR_ROUTE, NEWS_ROUTE, QUESTIONS_ROUTE, INFORMATION_ROUTE, LOCALIZATION_ROUTE, SEARCH_ROUTE } from "./constants/routes";
import MainPage from "./pages/mainPage/MainPage";
import ArticlePage from "./pages/articlePage/ArticlePage";
import MapPage from "./pages/mapPage/MapPage";
import PpnPage from "./pages/ppnPage/PpnPage";
import NewsPage from "./pages/newsPage/NewsPage";
import QuestionsPage from "./pages/questionsPage/QuestionsPage";
import InformationPage from "./pages/informationPage/InformationPage";
import SearchPage from "./pages/searchPage/SearchPage";
import LocalizationPage from "./pages/localizationPage/LocalizationPage";
import ContactPage from "./pages/contactPage/ContactPage";
import EmptyPage from "./pages/emptyPage/EmptyPage";
import FeesPage from "./pages/feesPage/FeesPage";
import RegulationsPage from "./pages/regulationsPage/RegulationsPage";
import ErrorPage from "./pages/errorPage/ErrorPage";
import ComplaintPage from "./pages/complaintPage/ComplaintPage";
import OldMapPage from "./pages/oldMapPage/OldMapPage";

const Root = (props) => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={MAP_ROUTE} component={MapPage} />
        <Route exact path={MAP_OLD_ROUTE} component={OldMapPage} />
        <Route exact path={MAIN_ROUTE} component={MainPage} />
        <Route exact path={ARTICLE_ROUTE} component={ArticlePage} />
        <Route exact path={FEES_ROUTE} component={FeesPage} />
        <Route exact path={REGULATIONS_ROUTE} component={RegulationsPage} />
        <Route exact path={PPN_ROUTE} component={PpnPage} />
        <Route exact path={NEWS_ROUTE} component={NewsPage} />
        <Route exact path={QUESTIONS_ROUTE} component={QuestionsPage} />
        <Route exact path={INFORMATION_ROUTE} component={InformationPage} />
        <Route exact path={LOCALIZATION_ROUTE} component={LocalizationPage} />
        <Route exact path={SEARCH_ROUTE} component={SearchPage} />
        <Route exact path={CONTACT_ROUTE} component={ContactPage} />
        <Route exact path={COMPLAINT_ROUTE} component={ComplaintPage} />
        <Route exact path={ERROR_ROUTE} component={ErrorPage} />
        <Route exact path={ERROR_ROUTE} component={EmptyPage} />
        <Route component={EmptyPage} />
      </Switch>
    </BrowserRouter>
  );
};

export default Root;
