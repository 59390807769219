import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { classNames } from '../functions';

const useStyles = makeStyles((theme) => ({
    root: {
        marginLeft: 9,
        '& .politic-link': {
            color: theme.palette.primary.main,
            textDecoration: 'none',
            transition: 'all 0.2s ease',
        },
        [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-start',
        },
    },
    checkbox: {
        padding: 0,
    },
    formControl: {
        marginLeft: 0,
        alignItems: 'flex-start',
    }
}))

const CheckboxComponent = ({
    label,
    route,
    formikProps,
    id,
    color = 'primary',
    onBlur = () => { },
    onChange = () => { },
    customHelperText,
    classRoute = '',
    classRoot = '',
}) => {
    const value = formikProps.values[id];
    const error = formikProps.errors[id];
    const touched = formikProps.touched[id];
    const classes = useStyles();
    const isError = () => {
        return error && Boolean(touched) ? true : false
    }
    const handleBlur = (event) => {
        onBlur(event);
        formikProps.handleBlur(event)
    }
    const handleChange = (event) => {
        onChange(event);
        formikProps.handleChange(event)
    }
    return (
        <FormControl error={customHelperText ? Boolean(customHelperText) : isError()}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name={id}
                        id={id}
                        className={classes.checkbox}
                        color={color}

                    />
                }
                className={classes.formControl}
                label={<Typography className={classNames([classes.root, classRoot, classRoute])} dangerouslySetInnerHTML={{ __html: label }} />}
            />
            <FormHelperText>{customHelperText ? customHelperText : isError() ? error : ''}</FormHelperText>
        </FormControl>
    )
}


export default React.memo(CheckboxComponent);