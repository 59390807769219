import { SEARCH_API } from "../constants/api";
import { GET } from "../functions/superagentSending";

const initialState = {
    loading: false,
    loadingPagination: false,
    data: {},
    error: '',
};


const FETCH_SEARCH_REQUEST = 'FETCH_SEARCH_REQUEST'
const FETCH_SEARCH_PAG_REQUEST = 'FETCH_SEARCH_PAG_REQUEST'
const FETCH_SEARCH_SUCCESS = 'FETCH_SEARCH_SUCCESS'
const FETCH_SEARCH_FAILURE = 'FETCH_SEARCH_FAILURE'


const fetchSearchRequest = () => ({
    type: FETCH_SEARCH_REQUEST
})
const fetchSearchPagRequest = () => ({
    type: FETCH_SEARCH_PAG_REQUEST
})

const fetchSearchSuccess = data => ({
    type: FETCH_SEARCH_SUCCESS,
    payload: data
})


const fetchSearchFailure = error => ({
    type: FETCH_SEARCH_FAILURE,
    payload: error
})

const SearchReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_SEARCH_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_SEARCH_PAG_REQUEST:
            return {
                ...state,
                loadingPagination: true
            }
        case FETCH_SEARCH_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingPagination: false,
                data: payload,
                error: '',
            }
        case FETCH_SEARCH_FAILURE:
            return {
                loading: false,
                data: {},
                error: payload
            }
        default:
            return state
    }
}

export const FETCH_SEARCH_REDUCER = (name, page) => {
    return (dispatch) => {
        if (page)
            dispatch(fetchSearchPagRequest())
        else
            dispatch(fetchSearchRequest())
        GET(`${SEARCH_API}/${name}`, {page}).then((success) => {
            dispatch(fetchSearchSuccess(success?.body))
        }).catch((error) => {
            dispatch(fetchSearchFailure(error))
            dispatch(fetchSearchFailure(''))
        })
    }
}

export default SearchReducer