import React from "react";
import NewsListItemComponent from "./NewsListItemComponent";
import PropTypes from "prop-types";
import NewsListItemSkeletonComponent from "./skeletonComponents/NewsListItemSkeletonComponent";
import ItemReadAlsoComponent from "./ItemReadAlsoComponent";
import ReadAlsoSkeletonComponent from "./skeletonComponents/ReadAlsoSkeletonComponent";

const NewsListWrapperComponent = ({ link = '', items = [], loading, firstLarge }) => {
  return (
    <>

      {loading
        ? <>
          {firstLarge ? <ReadAlsoSkeletonComponent /> : <NewsListItemSkeletonComponent />}
          <NewsListItemSkeletonComponent />
          <NewsListItemSkeletonComponent />
        </>
        : items?.length
          ? items.map(({ title, to, slug, src, date, description }, key) => (
            firstLarge && key === 0
              ? <ItemReadAlsoComponent
                key={key}
                date={date}
                src={src}
                startTitle
                title={title}
                to={to || slug}
                description={description}
              />
              : <NewsListItemComponent
                key={key}
                date={date}
                src={src}
                title={title}
                link={to || slug}
                description={description}
              />
          ))
          : null}
    </>
  );
};

NewsListItemComponent.propTypes = {
  items: PropTypes.array,
  loading: PropTypes.bool,
  firstLarge: PropTypes.bool,
  link: PropTypes.string,
};

export default React.memo(NewsListWrapperComponent);
