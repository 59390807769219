import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import makeStyles from '@material-ui/styles/makeStyles'
import React from 'react'
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: 5,
        '& .MuiSelect-outlined': {
            borderRadius: 50,

        },
        
        '& input': {
            padding: 20,
        },
        '& fieldset': {
            border: 'none',
        },
        '&>div': {
            borderRadius: 50,
            background: theme.palette.input.background,
        },
        '& label.Mui-focused': {
            color: theme.palette.text.primary,
        },
    },
    label: {
        color: theme.palette.text.input,
        left: 20,
        top: -5,
    },
    shrink: {
        opacity: 0,
    },
    paper: {
        background: theme.palette.background.default, 
    }
}))

const SelectComponent = ({
    items,
    customHelperText,
    variant = 'outlined',
    formikProps,
    fullWidth = true,
    id,
    placeholder,
    onBlur = () => { },
    onChange = () => { },
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const value = formikProps.values[id];
    const error = formikProps.errors[id];
    const touched = formikProps.touched[id];
    const isError = () => {
        return error && Boolean(touched) ? true : false
    }
    const handleBlur = (event) => {
        onBlur(event);
        formikProps.handleBlur(event)
    }
    const handleChange = (event) => {
        onChange(event);
        formikProps.handleChange(event)
    }
    return (
        <FormControl className={classes.formControl} fullWidth={fullWidth} error={customHelperText ? Boolean(customHelperText) : isError()}>
            <InputLabel classes={{
                root: classes.label,
                shrink: classes.shrink
            }} id={`${id}-label`}>{placeholder || t(`form.${id}`)}</InputLabel>
            <Select
                labelId={`${id}-label`}
                name={id}
                variant={variant}
                error={customHelperText ? Boolean(customHelperText) : isError()}
                // placeholder={placeholder || t(`form.${id}`)}
                value={value}
                onBlur={handleBlur}
                MenuProps={{ classes: { paper: classes.paper } }}
                onChange={handleChange}
            >
                {items?.length ?
                    items.map((item, key) => <MenuItem key={key} value={item.value}>{item.title}</MenuItem>)
                    : null}
            </Select>
            <FormHelperText className={isError() ? '' : classes.hiddenHelperText}>{customHelperText ? customHelperText : isError() ? error : ' '}</FormHelperText>
        </FormControl>
    )
}


export default SelectComponent;