import React from "react";
import { makeStyles, Typography, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReadMoreLinkComponent from "./ReadMoreLinkComponent";
import NewsItemTitleStartComponent from "./NewsItemTitleStartComponent";

const useStyles = makeStyles((theme) => ({
  newImg: {
    objectFit: "cover",
    width: 190,
    height: 130,
    // marginRight: 20,
    transition: 'all 0.2s ease-in',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 20,
    paddingTop: 10,
    alignItems: "start",
    '& .lazy-load-image-background': {
      minWidth: 190,
      height: 130,
      overflow: 'hidden',
      marginRight: 20,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      paddingBottom: 10,
    },

  },
  desc: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
    },
  },
  newsItemWrapper: {
    padding: 0,
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
    },
  },
}));

const NewsListItemComponent = ({ title, date, src, link, description }) => {
  const classes = useStyles();
  return (
    <ReadMoreLinkComponent to={link} date={date}>
      <Box className={classes.newsItemWrapper}>
        <NewsItemTitleStartComponent
          title={title}
        />
        <Box className={classes.content}>
          {/* temporary img src from file */}
          {src ? <LazyLoadImage src={src} alt={title} className={classes.newImg} effect='blur' /> : null}
          <Typography variant="body1" className={classes.desc}>{description}</Typography>
        </Box>
      </Box>
    </ReadMoreLinkComponent>
  );
};

NewsListItemComponent.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  link: PropTypes.string,
  src: PropTypes.string,
  description: PropTypes.string,
};

export default NewsListItemComponent;
