import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    img: {
        width: '100%',
        height: 200,
    }
}))

const RecomSkeletonComponent = (props) => {
 const classes = useStyles();

    return (
        <Skeleton variant="rect" className={classes.img}  />
    )
}

export default React.memo(RecomSkeletonComponent);