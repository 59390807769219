import React, { useEffect } from 'react'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import makeStyles from '@material-ui/styles/makeStyles'
import PropTypes from 'prop-types';
import TextFieldComponent from './TextFieldComponent';
import { useFormik } from 'formik';
// import * as Yup from 'yup';
// import { useTranslation } from 'react-i18next';
import SearchIcon from '@material-ui/icons/Search';
import { useHistory } from 'react-router-dom';
import { SEARCH_NAME_ROUTE } from '../constants/routes';


// const searchSchema = (t) => Yup.object().shape({
//     search: Yup.string()
//         .required(t('validation.required'))
//         .min(4, t('validation.min', { item: 4 }))
// });

const useStyles = makeStyles((theme) => ({
    label: {
        display: 'none',
    },
    form: {
        [theme.breakpoints.down('xs')]: {
            marginTop: 8,
            marginBottom: 8,
            width: '100%',
        },
    }
}))

const SearchComponent = (props) => {
    const classes = useStyles();
    const history = useHistory();
    // const { t } = useTranslation();
    const formik = useFormik({
        // validationSchema: searchSchema(t),
        initialValues: {
            search: '',
        },
        onSubmit: values => {
            history.push(`${SEARCH_NAME_ROUTE}/${values.search}`)
        },
    });
    useEffect(() => {
        formik.resetForm();
        // eslint-disable-next-line
    }, [])
    return (
        <form onSubmit={formik.handleSubmit} className={classes.form}>
            <label >
                <TextFieldComponent
                    hiddenError
                    formikProps={formik}
                    id='search'
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end" >
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={formik.handleSubmit}

                                >
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>

                    }}
                // size='small'
                />
            </label>
        </form>
    )
}


SearchComponent.propTypes = {
    name: PropTypes.string,
}
export default React.memo(SearchComponent);