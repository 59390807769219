// LANGS 

export const PL_LNG = 'pl';
export const EN_LNG = 'en';
export const DE_LNG = 'de';


// ACCESS CONTENT

export const ACCESS_TEXT = "accessText";
export const ACCESS_CONTARST = "accessContrast";
export const ACCESS_LISTEN = "accessListen";

// PHONE NUMBERS

export const PHONE_FOOTER = '91 480 90 00';
export const FAX_FOOTER = '91 480 90 30';

// EMAIL 

export const OFFICE_EMAIL_FOOTER = 'biuro@spp.szczecin.pl'

// WEBSITES
export const SHOP_URL_FOOTER = 'https://e-sklep.spp.szczecin.pl/';
export const NIOL_URL_FOOTER = 'http://niol.szczecin.pl/';

// http://nspp.spp.szczecin.pl/
export const API_URL = process.env.NODE_ENV === 'production' ? '' : '/cms';


// REGEXES

// eslint-disable-next-line
export const ZIP_CODE_REGEX = /^\d{2}-\d{3}$/im

// ZONES

export const WITHOUT_ZONE = 0;
export const YELLOW_ZONE = 2;
export const RED_ZONE = 1;