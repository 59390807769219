import { NEWS_API } from "../constants/api";
import { GET } from "../functions/superagentSending";

const initialState = {
    loading: false,
    data: {},
    error: '',
};


const FETCH_ARTICLE_REQUEST = 'FETCH_ARTICLE_REQUEST'
const FETCH_ARTICLE_SUCCESS = 'FETCH_ARTICLE_SUCCESS'
const FETCH_ARTICLE_FAILURE = 'FETCH_ARTICLE_FAILURE'


const fetchArticleRequest = () => ({
    type: FETCH_ARTICLE_REQUEST
})

const fetchArticleSuccess = data => ({
    type: FETCH_ARTICLE_SUCCESS,
    payload: data
})


const fetchArticleFailure = error => ({
    type: FETCH_ARTICLE_FAILURE,
    payload: error
})

const ArticleReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_ARTICLE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_ARTICLE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload,
                error: '',
            }
        case FETCH_ARTICLE_FAILURE:
            return {
                loading: false,
                data: {},
                error: payload
            }
        default:
            return state
    }
}

export const FETCH_ARTICLE_REDUCER = (slug) => {
    return (dispatch) => {
        dispatch(fetchArticleRequest())
        GET(`${NEWS_API}/${slug}`).then((success) => {
            dispatch(fetchArticleSuccess(success?.body?.data))
        }).catch((error) => {
            dispatch(fetchArticleFailure(error))
            dispatch(fetchArticleFailure(''))
        })
    }
}

export default ArticleReducer