import React from 'react'
import { useTranslation } from 'react-i18next';
import ErrorComponent from '../../components/ErrorComponent';

const EmptyPage = (props) => {
    const {t} = useTranslation();
    return (
        <ErrorComponent 
        number={'404'}
        title={t('empty_page.title')}
        />
    )
}

export default React.memo(EmptyPage);