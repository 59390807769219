import React, { useCallback, useState } from 'react'
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { classNames } from '../functions';
import List from '@material-ui/core/List'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import LinkComponent from './LinkComponent';

const useStyles = (size, widthSize, name) => makeStyles((theme) => ({
    secondaryItems: {
        opacity: 0,
        background: theme.palette.background.default,
        zIndex: 4,
        transition: 'all 0.1s ease-in',
        paddingTop: 4,
        position: "absolute",
        top: 20,
        left: '100%'
    },
    root: {
        width: widthSize,
        flex: 1,
        textDecoration: 'none',
    },


    primary: {
        fontSize: size,
        cursor: 'pointer',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 550,
        position: 'relative',
        display: 'inline-block',
    },
    back: {
        fontSize: 12,
        textTransform: 'uppercase',
        cursor: 'pointer',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 550,
    },
    item: {
        width: 320,
        position: 'initial',
    },
    showSubMenu: {
        opacity: 1,
        left: 0,
        width: '100vw',
        height: 'calc(100vh - 120px)',
        background: theme.palette.background.default,
        zIndex: 1,
        top: 20,
    },
    backIcon: {
        minWidth: 20
    },
    backItem: {
        marginLeft: -15,
        cursor: 'pointer'
    },
    itemIcon: {
        cursor: 'pointer'
    },
    activeLink: {
        textShadow: 'none',
        '&::before': {
            opacity: '0',
            transform: 'translateY(-2px)',
        },
        '&::after': {
            opacity: '1',
            transform: 'translateY(0px)',
        },
    },
    link: {
        color: theme.palette.text.primary,
        textDecoration: 'none',
    }
}))

const SideMenuItemComponent = ({ handleClose = () => { }, title, external_link, items, size = 18, widthSize = 320, pageLink, link = '' }) => {
    const { t } = useTranslation();
    const classes = useStyles(size, widthSize, title)();
    const [show, setShow] = useState(false);


    const handleShow = useCallback((hasItems) => {
        if (hasItems)
            setShow(!show)
    }, [show])
    return (
        <ListItem className={classes.item} >
            {
                <ListItemText
                    primary={link
                        ? <LinkComponent external_link={external_link} to={link} className={classes.link} title={title}>{title} </LinkComponent>
                        : title
                    }
                    onClick={() => handleShow(items?.length)}
                    classes={{
                        root: classes.root,
                        primary: classes.primary
                    }} />

            }
            {items?.length ? <ListItemIcon onClick={() => handleShow(true)} className={classes.itemIcon}>
                <ArrowForwardIosIcon color='primary' />
            </ListItemIcon> : ''}
            {items?.length
                ? <List className={classNames([classes.secondaryItems, 'hoverEffect', show ? classes.showSubMenu : ''])}>
                    <ListItem className={classNames([classes.item, classes.backItem])}>
                        <ListItemIcon className={classes.backIcon} onClick={handleShow}>
                            <ArrowBackIosIcon color='primary' />
                        </ListItemIcon>
                        <ListItemText
                            primary={t('btns.back')}
                            onClick={handleShow}
                            classes={{
                                root: classes.root,
                                primary: classes.back
                            }} />
                    </ListItem>
                    {items.map((item, key) =>
                        <SideMenuItemComponent
                            key={key}
                            size={size - 4}
                            widthSize={widthSize - 20}
                            title={item.title}
                            items={item.links}
                            link={item.link}
                        />
                    )}
                </List>
                : ''}
        </ListItem>
    )
}


export default React.memo(SideMenuItemComponent);