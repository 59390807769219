import React from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { classNames } from '../../../functions';
import PointSvg from '../../../svg/PointSvg';

const useStyles = makeStyles((theme) => ({
    cont: {
        display: 'flex',
        alignItems: 'center',
        margin: 10,
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
        }
    },
    title: {
        color: theme.palette.primary.main,
        marginLeft: 20,
        transition: 'all 0.2s ease-in',
        cursor: 'pointer',
        fontSize: theme.typography.h6.fontSize,
    },
    icon: {
        width: 60,
        height: 60,
        minWidth: 60,
        minHeight: 60
    },
    disactivate: {
        textDecoration: 'line-through',
    }
}))

const LegendTitleComponent = ({ title, Icon, color, active, onClick = () => {}}) => {
 const classes = useStyles();

    return (
        <Box className={classes.cont} onClick={onClick}>
            {Icon 
            ? <Icon  />
            : <PointSvg
                    className={classes.icon}
                    fill={color}
            />
            }
            <Typography className={classNames([classes.title, active ? '' : classes.disactivate ])}>{title}</Typography>
        </Box>
    )
}


LegendTitleComponent.propTypes = {
   name: PropTypes.string,
}
export default React.memo(LegendTitleComponent);