import React, { useCallback, useEffect, useState, } from 'react'
import { makeStyles, Paper, Typography } from '@material-ui/core';
import { GoogleMap, useJsApiLoader, Polyline, Polygon, Marker, MarkerClusterer } from '@react-google-maps/api';
import MainContainerComponent from '../../components/MainContainerComponent';
import HeroImage from '../../imgs/heroImage.jpg';
import HeroImageMobile from '../../imgs/heroImageMobile.jpg';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core';
import SubtitleComponent from '../../components/SubtitleComponent';
import {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { RED_ZONE, WITHOUT_ZONE, YELLOW_ZONE } from '../../constants';
import PointSvg from '../../svg/PointSvg';
import redStreetMarker from '../../svg/redStreetMarker.svg';
import yellowStreetMarker from '../../svg/yellowStreetMarker.svg';
import StreetPopupItemComponent from './components/StreetPopupItemComponent';
import Skeleton from '@material-ui/lab/Skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_MAP_REDUCER, SEND_MAP_REDUCER } from '../../reducers/OldMapReducer';
import MapLoaderComponent from './components/MapLoaderComponent';
import LazyLoad from 'react-lazyload';
import { Autocomplete } from '@material-ui/lab';
import CustomTextFieldComponent from '../../components/CustomTextFieldComponent';
import redPointSvg from '../../svg/redPoint.svg';
import yellowPointSvg from '../../svg/yellowPoint.svg';
import mapStyle from '../../constants/mapStyle';
import ButtonComponent from '../../components/ButtonComponent';
import { classNames } from '../../functions';
import LegendTitleComponent from './components/LegendTitleComponent';
import ClusterLegendIconSvg from '../../svg/ClusterLegendIconSvg';
import RedLegendIconSvg from '../../svg/RedLegendIconSvg';
import YellowLegendIconSvg from '../../svg/YellowLegendIconSvg';

const useStyles = makeStyles((theme) => ({
    autocompleteBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 40,

        [theme.breakpoints.down('xs')]: {
            display: 'block',
        },
    },
    resetBtn: {
        transition: 'all 0.2s ease-in',
        [theme.breakpoints.down('xs')]: {
            marginTop: 20,
        },
    },
    activeResetBtn: {
        opacity: 1,
    },
    disableResetBtn: {
        opacity: 0,
    },
    autocomplete: {
        width: 300,
        [theme.breakpoints.down('xs')]: {
            minWidth: '100%',
            width: '100%',
        }
    },
    textField: {
        minWidth: 400,

        [theme.breakpoints.down('sm')]: {
            minWidth: 300,
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: '100%',
            width: '100%',
        }
    },
    btn: {
        marginBottom: 17,
        marginLeft: 20,
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
        }
    },
    streetPopup: {
        padding: 20,
        width: 350,
        position: 'absolute',
        background: theme.palette.background.default,
        top: 60,
        left: 10,
        zIndex: 200,
        borderRadius: 19,
        [theme.breakpoints.down('xs')]: {
            width: 'calc(100% - 20px)',
        }
    },
    popupTitle: {
        fontSize: 22,
    },
    pointSvg: {
        width: 50,
        height: 50,
    },
    autocompleteCircle: {
        width: 5,
        height: 5,
        marginRight: 5,
        borderRadius: '50%'
    },
    legendCont: {
        marginTop: 80,
        marginBottom: 80,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            marginTop: 40,
            marginBottom: 40,
        }
    },
    legendTitles: {
        display: 'flex',
        paddingLeft: 20,
        flexWrap: 'wrap',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 0,
        }
    },
    legendTitle: {
        marginBottom: 0,
        [theme.breakpoints.down('xs')]: {
            marginBottom: 20,
        }
    }
}))

const containerStyle = {
    width: '100%',
    height: '80vh'
};

const defaultZoom = 14;
const MapPage = (props) => {
    const { streets, loadingStreet, loading, data } = useSelector((state) => state.OldMapReducer)
    const [street, setStreet] = useState({});
    const [autocompleteValue, setAutocompleteValue] = useState(null);
    const [zoom, setZoom] = useState(defaultZoom);
    const [googleMapLoading, setGoogleMapLoading] = useState(true);
    const theme = useTheme();
    const dispatch = useDispatch();
    const [activeCoords, setActiveCoords] = useState();
    const { t } = useTranslation();
    const classes = useStyles();
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=geometry,drawing,places`
    })
    useEffect(() => {
        dispatch(FETCH_MAP_REDUCER())
    }, [dispatch])

    const findZone = useCallback((zone) => {
        switch (true) {
            case zone === WITHOUT_ZONE:
                return theme.palette.gray.text
            case zone === RED_ZONE:
                return theme.palette.map.red
            case zone === YELLOW_ZONE:
                return theme.palette.map.yellow
            default:
                return theme.palette.gray.text
        }
    }, [theme,])
    const onLoad = useCallback((map) => {
        setGoogleMapLoading(false)
    }, [])
    const handleGetStreets = useCallback((e, v) => {
        if (e?.target?.value?.length > 2) {
            dispatch(SEND_MAP_REDUCER(e.target.value))
        }
    }, [dispatch])
    const changeStreet = useCallback((value, coords, zoom = 18) => {
        setActiveCoords({ zone: value.zone, coords });
        setStreet(value)
        setZoom(zoom)
    }, [])
    const handleGetStreet = useCallback((option, value) => {
        if (value) {
            setAutocompleteValue(value);
            const fullStreet = `${value?.title} ${value?.title.includes('Szczecin') ? '' : ', Szczecin'}`
            if (value?.lat && value?.lng) {
                changeStreet(value, { lat: value?.lat, lng: value?.lng })
            }
            else {
                geocodeByAddress(fullStreet).then(results => {
                    return getLatLng(results[0])
                })
                    .then(latLng => {
                        changeStreet(value, latLng)
                    })
                    .catch(error => {
                    });
            }
        }
        return option === value
    }, [changeStreet])

    const handleResetCoords = useCallback(() => {
        setActiveCoords({});
        setStreet({});
        setAutocompleteValue(null);
        setZoom(defaultZoom)
    }, [])

    return (
        <MainContainerComponent
            heroImg={HeroImage}
            hiddenSearch
            mobileImg={HeroImageMobile}
            breadcrumbs={[{
                title: t('map_page.title')
            }]}
        >
            <Box mt='40px'>
                <SubtitleComponent title={t('map_page.search_title')} />
                <Box className={classes.autocompleteBox}>
                    <Autocomplete
                        id="combo-box-demo"
                        options={loadingStreet ? [] : streets}
                        openOnFocus
                        loading={loadingStreet}
                        loadingText={<Box>
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                        </Box>}
                        noOptionsText={t('form.no_options')}
                        disableClearable
                        clearOnBlur={false}
                        filterOptions={(options, state) => options}
                        getOptionLabel={(option) => option.title}
                        renderOption={(option) =>
                            <React.Fragment>
                                <span className={classes.autocompleteCircle} style={{ background: findZone(option.zone) }}></span>
                                {option.title}
                            </React.Fragment>
                        }
                        getOptionSelected={(option, value) => option.title}
                        onChange={handleGetStreet}
                        value={autocompleteValue}
                        className={classes.autocomplete}
                        // style={{ width: 300 }}
                        renderInput={(params) => <CustomTextFieldComponent {...params} className={classes.textField} onChange={handleGetStreets} placeholder={t('form.street')} variant="outlined" />}
                    />
                    <ButtonComponent
                        variant='contained'
                        color='primary'
                        className={classNames([classes.resetBtn, street?.title ? classes.activeResetBtn : classes.disableResetBtn])}
                        onClick={handleResetCoords}
                    >
                        {t('btns.reset')}
                    </ButtonComponent>
                </Box>
                <LazyLoad height={500} placeholder={<Box position='relative' width='100%' height='80vh'><MapLoaderComponent show={true} /> </Box>}>
                    {isLoaded ?
                        <Box position='relative'>
                            <MapLoaderComponent
                                show={loading || googleMapLoading}
                            />
                            {street?.title
                                ? <Paper className={classes.streetPopup} elevation={3}>
                                    <Box display='flex' justifyContent='space-between' alignItems='center' mb='20px'>
                                        <Typography color='primary' className={classes.popupTitle}>{street?.title}</Typography>
                                        <PointSvg fill={findZone(street?.zone)} className={classes.pointSvg} />
                                    </Box>
                                    <StreetPopupItemComponent
                                        loading={loadingStreet}
                                        name={t('map_page.price_name')}
                                        title={street?.price || '0 zł/h'}
                                    />
                                    <StreetPopupItemComponent
                                        loading={loadingStreet}
                                        name={t('map_page.time_name')}
                                        title={`${street?.timeWork || t('map_page.all_time')}`}
                                    />
                                    {street?.description ? <StreetPopupItemComponent
                                        withoutFlex
                                        loading={loadingStreet}
                                        name={t('map_page.description_name')}
                                        title={street?.description}
                                    /> : null}
                                </Paper>
                                : null
                            }
                            <GoogleMap
                                id='map'
                                mapContainerStyle={containerStyle}
                                options={{ styles: mapStyle }}
                                center={activeCoords?.coords?.lat
                                    ? activeCoords?.coords
                                    : {
                                        lat: 53.42894,
                                        lng: 14.55302
                                    }}
                                zoom={zoom}
                                onLoad={onLoad}
                            >
                                {activeCoords?.coords?.lat
                                    ? <Marker
                                        position={activeCoords?.coords}
                                        icon={activeCoords?.zone === RED_ZONE ? redStreetMarker : yellowStreetMarker}
                                        animation={2}
                                    // onPositionChanged={handlePositionChange}
                                    />
                                    : ''}
                                {data?.redZoneParking?.length
                                    ?
                                    <MarkerClusterer
                                        options={
                                            {
                                                styles: [
                                                    {
                                                        url: `${window.location.origin}/imgs/clusterMap.svg`,
                                                        width: 55,
                                                        height: 55,
                                                        // textSize: 15,
                                                        // fontWeight: 'bold',
                                                        fontFamily: theme.typography.fontFamily,
                                                        anchorText: [5, 5],
                                                        textColor: 'white'
                                                    },
                                                ],
                                            }
                                        }
                                    >
                                        {clusterer => [
                                            ...(data?.redZoneParking.map((pM, key) => (
                                                <Marker
                                                    key={key}
                                                    position={pM}
                                                    clusterer={clusterer}
                                                    icon={redPointSvg}
                                                    animation={2}
                                                // onPositionChanged={handlePositionChange}
                                                />))),
                                            ...(data?.yellowZoneParking.map((pM, key) => (
                                                <Marker
                                                    key={`y-${key}`}
                                                    position={pM}
                                                    clusterer={clusterer}
                                                    icon={yellowPointSvg}
                                                    animation={2}
                                                // onPositionChanged={handlePositionChange}
                                                />)))
                                        ]

                                        }
                                    </MarkerClusterer>
                                    : ''
                                }
                                {data?.yellowZone?.length
                                    ? data.yellowZone.map((item, key) =>
                                        <Polyline
                                            key={key}
                                            options={{
                                                strokeColor: theme.palette.map.yellow,
                                                strokeWeight: 3,
                                            }}
                                            path={item}
                                        >
                                        </Polyline>
                                    )
                                    : null
                                }
                                {data?.redZone?.length
                                    ? data.redZone.map((item, key) =>
                                        <Polyline
                                            key={key}
                                            options={{
                                                strokeColor: theme.palette.map.red,
                                                strokeWeight: 3,
                                            }}
                                            path={item}
                                        >
                                        </Polyline>
                                    )
                                    : null
                                }

                                <></>
                                {data?.zone?.length ? data.zone.map((s, key) =>
                                    <Polygon
                                        key={key}
                                        paths={[s.reverse()]}
                                        options={{
                                            strokeColor: "#000000",
                                            strokeOpacity: 0.8,
                                            strokeWeight: 3,
                                            fillColor: "#000000",
                                            fillOpacity: 0
                                        }}
                                    ></Polygon>
                                ) : null}

                            </GoogleMap>
                        </Box>
                        : <></>
                    }
                </LazyLoad>
            </Box>
            <Box className={classes.legendCont}>
                <Box>
                    <SubtitleComponent
                        title={t('map_page.legend_title')}
                        className={classes.legendTitle}
                    />
                </Box>
                <Box className={classes.legendTitles}>
                    <LegendTitleComponent
                        title={t('map_page.cluster_legend_title')}
                        Icon={ClusterLegendIconSvg}
                        active
                    />
                    <LegendTitleComponent
                        title={t('map_page.red_legend_title')}
                        active
                        Icon={RedLegendIconSvg}
                    />
                    <LegendTitleComponent
                        title={t('map_page.yellow_legend_title')}
                        active
                        Icon={YellowLegendIconSvg}
                    />
                </Box>
            </Box>
        </MainContainerComponent >
    )
}


export default React.memo(MapPage);