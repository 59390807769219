import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ArticleComponent from '../../components/ArticleComponent';
import { FETCH_LOCALIZATION_REDUCER } from '../../reducers/LocalizationReducer';

const LocalizationPage = (props) => {
    const { data, loading } = useSelector((state) => state.LocalizationReducer);
    const dispatch = useDispatch();
    const { slug } = useParams();

    useEffect(() => {
        dispatch(FETCH_LOCALIZATION_REDUCER(slug))
    }, [dispatch, slug]) 
    return (
        <ArticleComponent
            data={data}
            loading={loading}
        />
    )
}


export default LocalizationPage;