import React from 'react'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/styles/makeStyles'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReadMoreComponent from './ReadMoreComponent';
import { classNames } from '../functions';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    date: {
        transition: 'all 0.2s ease-in',
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
        '&:hover': {
            '& img': {
                '-webkit-transform': 'scale(1.3)',
                transform: 'scale(1.3)',
            },
            '& .read-more': {
                color: theme.palette.primary.main,
                '& svg': {
                    fill: theme.palette.primary.main,
                },
            },
            '& .date': {
                color: theme.palette.primary.main,
            }
        }
    },
    newsContent: {
        display: "flex",
        justifyContent: "space-between",
        color: theme.palette.gray.icons,
        padding: '5px 0',
        alignItems: "center",
        marginBottom: 20,
    },
}))

const ReadMoreLinkComponent = ({ to, date, children }) => {
    const classes = useStyles();

    return (
        <Link to={to} className={classes.link} title='Read more'>
            {children}
            <Divider />
            <Box className={classes.newsContent}>
                <ReadMoreComponent
                />
                {date ? <Typography variant="body1" color="initial" className={classNames([classes.date, 'date'])}>
                    {moment(date).format('DD MMMM YYYY')}
                </Typography>
                    : null
                }
            </Box>
        </Link>
    )
}


ReadMoreLinkComponent.propTypes = {
    to: PropTypes.string,
    date: PropTypes.string,
}
export default React.memo(ReadMoreLinkComponent);