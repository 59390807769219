import * as React from "react"

function YellowLegendIconSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={49.372}
            height={59.119}
            viewBox="0 0 49.372 59.119"
            {...props}
        >
            <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 371">
                <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 364">
                    <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 297">
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 341"
                            d="M42.142 7.23A24.687 24.687 0 007.23 42.142l16.64 16.639a1.155 1.155 0 001.633 0l16.639-16.639a24.686 24.686 0 000-34.911z"
                            fill="#eabe07"
                        />
                    </g>
                </g>
                <g
                    data-name="placeholder"
                    style={{
                        isolation: "isolate",
                    }}
                >
                    <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 300">
                        <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 299">
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 342"
                                d="M39.546 9.826a21.015 21.015 0 106.155 14.86 20.877 20.877 0 00-6.155-14.86zm-1.633 28.087a18.706 18.706 0 115.483-13.227 18.583 18.583 0 01-5.483 13.227z"
                                fill="#f2f2f2"
                            />
                        </g>
                    </g>
                    <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 302">
                        <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 301">
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 343"
                                d="M27.082 17.007h-4.65a1.155 1.155 0 00-1.155 1.155v5.037a1.155 1.155 0 001.155 1.155h4.654a3.673 3.673 0 100-7.346zm0 5.037h-3.5v-2.726h3.5a1.364 1.364 0 010 2.728z"
                                fill="#f2f2f2"
                            />
                        </g>
                    </g>
                    <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 304">
                        <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 303">
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 344"
                                d="M27.085 12.81h-8.851a1.155 1.155 0 00-1.155 1.155v21.443a1.155 1.155 0 001.155 1.155h4.2a1.155 1.155 0 001.155-1.155v-6.857h3.5a7.87 7.87 0 100-15.741zm0 13.432h-4.654a1.155 1.155 0 00-1.155 1.155v6.857h-1.891V15.119h7.7a5.561 5.561 0 110 11.122z"
                                fill="#f2f2f2"
                            />
                        </g>
                    </g>
                    <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 306">
                        <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 305">
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 345"
                                d="M11.268 15.605a1.155 1.155 0 00-1.577.423 17.284 17.284 0 00-1.973 5.209 1.155 1.155 0 102.263.46 14.974 14.974 0 011.71-4.514 1.155 1.155 0 00-.423-1.578z"
                                fill="#f2f2f2"
                            />
                        </g>
                    </g>
                    <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 308">
                        <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 307">
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 346"
                                d="M8.523 23.5a1.155 1.155 0 00-1.155 1.155v.031a1.155 1.155 0 102.309 0v-.031A1.155 1.155 0 008.523 23.5z"
                                fill="#f2f2f2"
                            />
                        </g>
                    </g>
                    <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 310">
                        <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 309">
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 347"
                                d="M37.559 34.529a1.155 1.155 0 00-1.63.1 14.981 14.981 0 01-3.738 3.055 1.155 1.155 0 101.155 2 17.284 17.284 0 004.313-3.525 1.155 1.155 0 00-.1-1.63z"
                                fill="#f2f2f2"
                            />
                        </g>
                    </g>
                    <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 312">
                        <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 311">
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 348"
                                d="M39.259 31.767a1.155 1.155 0 00-1.576.425l-.014.024a1.155 1.155 0 102 1.157l.017-.03a1.155 1.155 0 00-.427-1.576z"
                                fill="#f2f2f2"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default YellowLegendIconSvg
