import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    title: {
        height: 40,
        marginBottom: 20,
        
    },
    accordion: {
        height: 200,
        marginBottom: 40,
    }
}))

const AccordionSkeletonComponent = (props) => {
 const classes = useStyles();

    return (
        <section>
           <Skeleton variant='rect' className={classes.title} />
           <Skeleton variant='rect' className={classes.accordion} />
        </section>
    )
}


export default React.memo(AccordionSkeletonComponent);