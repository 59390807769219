import React from 'react'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types';

const SubtitleComponent = ({ title, variant ='h3', className= ''}) => {

    return (
        <Typography color='primary' variant={variant} className={className}>
            {title}
        </Typography>
    )
}


SubtitleComponent.propTypes = {
    title: PropTypes.string,
}
export default React.memo(SubtitleComponent);