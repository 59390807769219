import React, { useCallback, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/styles/makeStyles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PlLngSvg from '../svg/PlLngSvg';
import EnLngSvg from '../svg/EnLngSvg';
import DeLngSvg from '../svg/DeLngSvg';
import LngBtnComponent from './LngBtnComponent';
import { EN_LNG, PL_LNG, DE_LNG } from '../constants';
import { MAIN_ROUTE } from '../constants/routes';
import { FETCH_HOME_DATA_REDUCER } from '../reducers/HomePageReducer';
import { useHistory, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { FETCH_MENU_REDUCER } from '../reducers/MainReducer';

const useStyles = makeStyles((theme) => ({
    cont: {
        display: 'flex',
        alignItems: 'center',
        margin: '0 20px',
    },
    title: {
        marginRight: 10,
    },
    lng: {
        width: 100,
        height: 100,
    }
}))

const LngsComponent = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { pathname, search } = useLocation();

    useEffect(() => {
        let params = new URLSearchParams(search);
        const lang = params.get('lang')
        if (lang && lang.toLowerCase() !== i18n.language) {
            handleChangeLng(lang)
        }
        // eslint-disable-next-line
    }, [search, i18n])

    const handleChangeLng = useCallback((lng) => {
        i18n.changeLanguage(lng);
        localStorage.setItem('lang', lng);
        history.push({ search: `?lang=${lng}` })
        dispatch(FETCH_MENU_REDUCER())
        if (pathname === MAIN_ROUTE)
            dispatch(FETCH_HOME_DATA_REDUCER())
        else
            history.push(MAIN_ROUTE)

    }, [i18n, history, pathname, dispatch])

    return (
        <Box className={classes.cont}>
            <Typography className={classes.title}>{t('main.lng')}</Typography>
            <LngBtnComponent Icon={DeLngSvg} lng={DE_LNG} handleChangeLng={handleChangeLng} />
            <LngBtnComponent Icon={EnLngSvg} lng={EN_LNG} handleChangeLng={handleChangeLng} />
            <LngBtnComponent Icon={PlLngSvg} lng={PL_LNG} handleChangeLng={handleChangeLng} />
        </Box>
    )
}


LngsComponent.propTypes = {
    name: PropTypes.string,
}
export default React.memo(LngsComponent);