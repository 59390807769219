import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import Root from './Root';
import muiTheme from './themes/MuiTheme';
import textTheme from './themes/TextTheme';
import contrastTheme from './themes/ContrastTheme';
import { useSelector } from 'react-redux';
import { ACCESS_CONTARST, ACCESS_TEXT } from './constants';
import moment from 'moment';
import 'moment/locale/pl';
import { CookiesProvider } from 'react-cookie';
import { useEffect } from 'react';

function App() {
  const mainData = useSelector((state) => state.MainReducer.data);
  useEffect(() => {
    moment.locale('pl');
  }, []);
  return (
    <CookiesProvider>
      <MuiThemeProvider
        theme={{
          ...muiTheme,
          typography: {
            ...muiTheme.typography,
            ...(mainData[ACCESS_TEXT] ? textTheme : {}),
          },
          palette: {
            ...muiTheme.palette,
            ...(mainData[ACCESS_CONTARST] ? contrastTheme : {}),
          },
        }}
      >
        <CssBaseline />
        <Root />
      </MuiThemeProvider>
    </CookiesProvider>
  );
}

export default App;
