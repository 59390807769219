import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ArticleComponent from '../../components/ArticleComponent';
import { FETCH_FEES_REDUCER } from '../../reducers/FeesReducer';


const FeesPage = (props) => {
    const { data, loading } = useSelector((state) => state.FeesReducer);
    const dispatch = useDispatch();
    const { slug } = useParams();

    useEffect(() => {
        dispatch(FETCH_FEES_REDUCER(slug));
    }, [dispatch, slug]);
    return <ArticleComponent data={data} loading={loading} />
}


export default React.memo(FeesPage);