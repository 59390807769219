import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/styles/makeStyles'
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import HeroImg from '../../imgs/pagesHero.jpg';
import MainContainerComponent from '../../components/MainContainerComponent';
import SubtitleComponent from '../../components/SubtitleComponent';
import { useDispatch, useSelector } from 'react-redux';

import { FETCH_CONTACT_REDUCER } from '../../reducers/ContactReducer';
import QrcodeContact from '../../imgs/qrcodeContact.png'

import {
    GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3'
import ContactFormComponent from './components/ContactFormComponent';
import FooterItemComponent from '../../components/FooterItemComponent';

const useStyles = makeStyles((theme) => ({
    qrcode: {
        width: 'auto',
        maxWidth: '100%',
    }
}))




const ContactPage = (props) => {
    const classes = useStyles();
    const { footer} = useSelector((state) => state.MainReducer.data)
    const { t } = useTranslation();
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(FETCH_CONTACT_REDUCER());
    }, [dispatch])
    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA}>
        <MainContainerComponent
            heroImg={HeroImg}
            mobileImg={HeroImg}
            breadcrumbs={[{
                title: t('contact_page.title'),
            }]}
            smallHero
            rightPart={<Box mt='40px'>
                {footer?.length
                    ? footer.map((item, key) =>
                        <FooterItemComponent
                            key={key}
                            sm={12}
                            title={item?.title}
                            contacts={item?.contacts}
                            street={item?.street}
                        />
                    )
                    : null
                }
               <Box>
                    <img 
                    src={QrcodeContact} 
                    alt={t('contact_page.qr')}
                    className={classes.qrcode}
                    />
               </Box>
            </Box>}
            leftPart={<Box >
                <SubtitleComponent title={t('contact_page.title')} variant='h1' />
                <ContactFormComponent />
            </Box>}>
        </MainContainerComponent>
        </GoogleReCaptchaProvider>
    )
}


ContactPage.propTypes = {
    name: PropTypes.string,
}
export default React.memo(ContactPage);