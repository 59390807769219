import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import SectionItemSkeletonComponent from './SectionItemSkeletonComponent';

const useStyles = makeStyles((theme) => ({
  
    text: {
        marginTop: 20,
        marginBottom: 20,
        width: '100%',
        height: 40,
    },
    header: {
        width: '100%', 
        marginBottom: 20,
        height: 60,
    }
}))

const SectionSkeletonComponent = (props) => {
    const classes = useStyles();

    return (
        <Box>
            <Skeleton variant="text" className={classes.header} /> 
            <Grid container spacing={4}>
                <SectionItemSkeletonComponent />
                <SectionItemSkeletonComponent />
                <SectionItemSkeletonComponent />
                <SectionItemSkeletonComponent />
                <SectionItemSkeletonComponent />
                <SectionItemSkeletonComponent />
            </Grid>
            
            <Skeleton variant="text" className={classes.text} /> 
        </Box>
    )
}

export default React.memo(SectionSkeletonComponent);