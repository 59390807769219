import React from 'react'
import { FormControl, FormControlLabel, FormHelperText, FormLabel, makeStyles, Radio, RadioGroup } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.primary.main,
        marginBottom: 10,
        fontWeight: 'bold',
    }
}))

const RadioComponent = ({
    items,
    formikProps,
    id,
    customHelperText,
    placeholder,
    onBlur = () => { },
    onChange = () => { },
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const value = formikProps.values[id];
    const error = formikProps.errors[id];
    const touched = formikProps.touched[id];
    const isError = () => {
        return error && Boolean(touched) ? true : false
    }
    const handleBlur = (event) => {
        onBlur(event);
        formikProps.handleBlur(event)
    }
    const handleChange = (event) => {
        onChange(event);
        formikProps.handleChange(event)
    }
    return (
        <FormControl component="fieldset" error={customHelperText ? Boolean(customHelperText) : isError()}>
            {/* <SubtitleComponent title={placeholder || t(`form.${id}`)} variant='h3' /> */}
            <FormLabel component="legend" className={classes.title}>{placeholder || t(`form.${id}`)}</FormLabel>
            <RadioGroup
                name={id}
                id={id}
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
            >
                {items?.length
                    ? items.map(({ title, value }, key) =>
                        <FormControlLabel 
                        
                        value={value.toString()} key={key} control={<Radio color='primary' />} label={title} />
                    )
                    : null}
            </RadioGroup>
            <FormHelperText>{customHelperText ? customHelperText : isError() ? error : ''}</FormHelperText>
        </FormControl>
    )
}


RadioComponent.propTypes = {
    name: PropTypes.string,
}
export default React.memo(RadioComponent);