import * as React from "react"

function NiolMainLogoSvg(props) {
    return (
        <svg
            id="NiolMainLogoSvg_Warstwa_1"
            xmlns="http://www.w3.org/2000/svg"
            x={0}
            y={0}
            viewBox="0 0 676.1 310.4"
            xmlSpace="preserve"
            {...props}
        >
            <style>{".NiolMainLogoSvg_st2{fill:#fff}"}</style>
            <path fill="#d71921" d="M0 0h285.3v310.4H0z" />
            <path fill="#1e398c" d="M273.1 0h403v310.4h-403z" />
            <path
                className="NiolMainLogoSvg_st2"
                d="M209.4 102.9c.9-5.6 1.9-10.2 2.8-15.8l2.8-13.9c-4.6 3.7-7.4 7.4-11.1 11.1-7.4 8.4-16.7 15.8-27.9 16.7-7.4.9-13.9.9-21.4.9-2.8.9-4.6 1.9-7.4 2.8-2.8 1.9-5.6 3.7-9.3 4.6-5.6.9-8.4-.9-11.1-5.6-.9-.9-2.8-1.9-3.7-1.9H93.4c-7.4 0-13.9 1.9-20.4 5.6-12.1 7.4-14.9 21.4-6.5 31.6l3.7 3.7c1.9-11.1 9.3-15.8 18.6-17.6 7.4-.9 14.9-.9 21.4 0 12.1 1.9 23.2 4.6 35.3 7.4 7.4 1.9 13.9.9 17.6-7.4 2.8-4.6 7.4-6.5 12.1-3.7 10.2 5.6 16.7 13.9 16.7 26 0 9.3-3.7 16.7-9.3 24.1-9.3 11.1-20.4 20.4-31.6 29.7-7.4 7.4-14.9 13.9-22.3 21.4-6.5 6.5-10.2 14.9-9.3 24.1 0 11.1 10.2 20.4 19.5 19.5-4.6-5.6-1.9-10.2.9-14.9.9-1.9 1.9-3.7 3.7-5.6 10.2-11.1 21.4-21.4 32.5-32.5 11.1-10.2 22.3-21.4 29.7-34.4 13.9-24.1 7.4-49.2-17.6-62.2-1.9-.9-4.6-1.9-4.6-4.6h14.9c6.4.2 10.1-2.6 11-9.1z"
            />
            <path
                className="NiolMainLogoSvg_st2"
                d="M120.3 180.9c-2.8.9-4.6.9-8.4 1.9 3.5 1.6 1.9 1 4.7 2 8.4 3.7 16.7 2.6 25-3 8.4-5.6 11.1-13.9 10.2-23.2 0-8.4-9.3-15.8-19.5-16.7s-20.4-.9-30.6-1.9c1.9 3.7 5.6 5.6 9.3 7.4 3.7 1.9 7.4 2.8 11.1 4.6 7.4 3.7 10.2 8.4 10.2 14.9s-4.6 12.1-12 14zM118.4 85.2c-2.8-.9-6.5-2.8-8.4-5.6-3.7-2.8-6.5-7.4-10.2-11.1-4.6-5.6-10.2-5.6-17.6-4.6 5.6 9.3 11.1 17.6 16.7 26 2.8 4.6 7.4 6.5 13 6.5h52c5.6-.9 11.1-2.8 13.9-7.4 4.6-6.5 8.4-13.9 13-20.4.9-.9 1.9-2.8 2.8-4.6-7.4-1.9-13.9 0-18.6 5.6-4.6 4.6-8.4 9.3-13 13.9-2.8 3.7-8.4 3.7-11.1 1.9-3.7-2.8-3.7-6.5-2.8-10.2 1.9-3.7.9-7.4-1.9-10.2-2.8-3.7-5.6-6.5-8.4-9.3-6.5 6.5-13.9 10.2-10.2 21.4 1.9 5.3-3.6 10-9.2 8.1z"
            />
            <g>
                <path
                    className="NiolMainLogoSvg_st2"
                    d="M425.2 127.5c-25.4 0-37.9 19.6-37.9 19.6l-.8 1.1V130h-34.2v120h40.1v-78.7s2.4-12.5 18.2-13.3 19.2 15 19.2 15v76.2h37.1s-.4-54.6 0-78.3c.4-23.8-16.3-43.4-41.7-43.4zM492.7 129.6h39.2v120h-39.2zM511.9 75.8c-11.5 0-20.8 9.3-20.8 20.8s9.3 20.8 20.8 20.8 20.8-9.3 20.8-20.8-9.3-20.8-20.8-20.8zM571.7 249.1c-8.8 0-15.9 7.5-15.9 16.8 0 9.2 7.1 16.7 15.9 16.7s15.9-7.5 15.9-16.7c0-9.3-7.1-16.8-15.9-16.8zm.2 25.8c-3.3 0-6-4-6-9s2.7-9 6-9 6 4 6 9c0 4.9-2.7 9-6 9zM590.4 236.3H602v45.9h-11.6z"
                />
            </g>
        </svg>
    )
}

export default NiolMainLogoSvg
