import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
const useStyles = makeStyles((theme) => ({
    img: {
        width: '100%',
        height: 200,
    },
}))

const SectionItemSkeletonComponent = (props) => {
 const classes = useStyles();

    return (
        <Grid item md={4} sm={6} xs={12} >
            <Skeleton variant="rect" className={classes.img} />
        </Grid>
    )
}

export default React.memo(SectionItemSkeletonComponent);