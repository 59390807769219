import React, { useCallback, useEffect, useState, } from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import MainContainerComponent from '../../components/MainContainerComponent';
import HeroImage from '../../imgs/heroImage.jpg';
import HeroImageMobile from '../../imgs/heroImageMobile.jpg';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import SubtitleComponent from '../../components/SubtitleComponent';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_MAP_REDUCER } from '../../reducers/MapReducer';
import MapLoaderComponent from './components/MapLoaderComponent';
import LazyLoad from 'react-lazyload';
import mapStyle from '../../constants/mapStyle';
import LegendContainerComponent from './components/LegendContainerComponent';
import MapAutocompleterComponent from './components/MapAutocompleterComponent';
import StreetPopupComponent from './components/StreetPopupComponent';
import ParkingsComponent from './components/ParkingsComponent';
import InfoMarkersComponent from './components/InfoMarkersComponent';
import PolygonsComponent from './components/PolygonsComponent';
import ZonesComponent from './components/ZonesComponent';

const containerStyle = {
    width: '100%',
    height: '80vh'
};

const defaultZoom = 14;
const szczecin_zone = {
    lat: 53.42894,
    lng: 14.55302
};
const MapPage = (props) => {
    const { loading } = useSelector((state) => state.MapReducer)
    const [street, setStreet] = useState({});
    const [zoneCoords, setZoneCoords] = useState(szczecin_zone);
    const [activeMarker, setActiveMarker] = useState({});
    const [activeParkings, setActiveParkings] = useState(false);
    const [zoom, setZoom] = useState(defaultZoom);
    const [autocompleteValue, setAutocompleteValue] = useState(null);
    const [googleMapLoading, setGoogleMapLoading] = useState(true);
    const dispatch = useDispatch();
    const [activeCoords, setActiveCoords] = useState();
    const { t } = useTranslation();
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=geometry,drawing,places`
    })
    useEffect(() => {
        dispatch(FETCH_MAP_REDUCER())
    }, [dispatch])

    const onLoad = useCallback((map) => {
        setGoogleMapLoading(false)
    }, [])
    const handleToggleParkings = useCallback((map) => {
        setActiveParkings(!activeParkings)
    }, [activeParkings])

    const handleMarker = useCallback((marker) => (e) => {
        setStreet({})
        setActiveCoords({ ...marker, coords: { lat: marker?.lat, lng: marker?.lng } })
        setActiveMarker(marker)
    }, [])

    const handleZoom = useCallback((coords) => {
        if (coords) {
            setZoneCoords(coords)
            setZoom(15)
        } else {
            setZoneCoords(szczecin_zone)
            setZoom(defaultZoom)
        }
    }, [])

    const handleCloseAllPopups = useCallback((zooming) => {
        setActiveCoords({});
        setStreet({});
        setActiveMarker({});
        setAutocompleteValue(null)
        if (zooming){
            setZoom(defaultZoom)
            setZoneCoords(szczecin_zone)
        }
    }, [])
    return (
        <MainContainerComponent
            heroImg={HeroImage}
            hiddenSearch
            mobileImg={HeroImageMobile}
            breadcrumbs={[{
                title: t('map_page.title')
            }]}
        >
            <Box mt='40px'>
                <SubtitleComponent title={t('map_page.search_title')} />
                <MapAutocompleterComponent
                    setStreet={setStreet}
                    autocompleteValue={autocompleteValue}
                    setAutocompleteValue={setAutocompleteValue}
                    setActiveMarker={setActiveMarker}
                    setActiveCoords={setActiveCoords}
                    setZoom={setZoom}
                    street={street}
                    defaultZoom={defaultZoom}
                />
                <LegendContainerComponent
                    handleCloseAllPopups={handleCloseAllPopups}
                    activeParkings={activeParkings}
                    handleZoom={handleZoom}
                    handleToggleParkings={handleToggleParkings}
                />
                <LazyLoad height={500} placeholder={<Box position='relative' width='100%' height='80vh'><MapLoaderComponent show={true} /> </Box>}>
                    {isLoaded ?
                        <Box position='relative'>
                            <MapLoaderComponent
                                show={loading || googleMapLoading}
                            />
                            {street?.title
                                ? <StreetPopupComponent
                                    handleClose={handleCloseAllPopups}
                                    street={street}
                                />
                                : null
                            }
                            {activeMarker?.title
                                ? <StreetPopupComponent
                                    handleClose={handleCloseAllPopups}
                                    street={activeMarker}
                                />
                                : null
                            }
                            <GoogleMap
                                id='map'
                                mapContainerStyle={containerStyle}
                                options={{ styles: mapStyle }}
                                center={activeCoords?.coords?.lat
                                    ? activeCoords?.coords
                                    : zoneCoords}
                                zoom={zoom}
                                onLoad={onLoad}
                            >
                                {activeCoords?.coords?.lat
                                    ? <Marker
                                        position={{
                                            lat: parseFloat(activeCoords?.coords?.lat),
                                            lng: parseFloat(activeCoords?.coords?.lng),
                                        }}
                                        icon={activeCoords?.url}
                                    // animation={2}
                                    // onPositionChanged={handlePositionChange}
                                    />
                                    : ''}
                                <InfoMarkersComponent handleMarker={handleMarker} />
                                <ParkingsComponent
                                    activeParkings={activeParkings}
                                />

                                <ZonesComponent />
                                <PolygonsComponent />
                            </GoogleMap>
                        </Box>
                        : <></>
                    }
                </LazyLoad>
            </Box>

        </MainContainerComponent >
    )
}


export default React.memo(MapPage);