import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ArticleComponent from '../../components/ArticleComponent';
import { FETCH_INFORMATION_REDUCER } from '../../reducers/InformationReducer';

const InformationPage = (props) => {
    const { data, loading } = useSelector((state) => state.InformationReducer);
    const dispatch = useDispatch();
    const { slug } = useParams();

    useEffect(() => {
        dispatch(FETCH_INFORMATION_REDUCER(slug))
    }, [dispatch, slug])
    return (
        <ArticleComponent
            loading={loading}
            data={data}
        />
    )
}


export default InformationPage;