import * as React from "react"

function DeLngSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 40 40"
            {...props}
        >
            <circle cx={20} cy={20} r={20} fill="#f1f1f1" />
            <defs>
                <circle id="DeLngSvg_a" cx={20} cy={20} r={17.8} />
            </defs>
            <clipPath id="DeLngSvg_b">
                <use xlinkHref="#DeLngSvg_a" overflow="visible" />
            </clipPath>
            <g clipPath="url(#DeLngSvg_b)">
                <path
                    d="M50 26v11.8c0 .2 0 .2-.2.2H-9.9c-.2 0-.2 0-.2-.2V2.2c0-.1-.1-.2 0-.2v23.7c0 .3.1.3.3.3H50z"
                    fill="#fecd00"
                />
                <path d="M-10 14v-.3V2h59.8c.2 0 .2 0 .2.2V14h-60z" />
                <path
                    d="M-10 14h59.9v12H-9.8c-.2 0-.3 0-.3-.3v-2.3-9.1c.1-.1.1-.2.1-.3z"
                    fill="#dc0000"
                />
            </g>
            <radialGradient
                id="DeLngSvg_c"
                cx={20.215}
                cy={8430.761}
                r={23.58}
                gradientTransform="translate(0 -8421.642)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0} stopColor="#fff" stopOpacity={0} />
                <stop offset={0.158} stopColor="#fefdfc" stopOpacity={0.095} />
                <stop offset={0.291} stopColor="#f9f7f1" stopOpacity={0.175} />
                <stop offset={0.416} stopColor="#f2ede0" stopOpacity={0.25} />
                <stop offset={0.535} stopColor="#e7dec7" stopOpacity={0.322} />
                <stop offset={0.65} stopColor="#dacba7" stopOpacity={0.391} />
                <stop offset={0.762} stopColor="#c9b480" stopOpacity={0.459} />
                <stop offset={0.872} stopColor="#b69952" stopOpacity={0.525} />
                <stop offset={0.978} stopColor="#9f7a1e" stopOpacity={0.588} />
                <stop offset={0.998} stopColor="#9b7413" stopOpacity={0.6} />
            </radialGradient>
            <circle cx={20} cy={20} r={17.8} fill="url(#DeLngSvg_c)" />
        </svg>
    )
}

export default DeLngSvg
