import React, { useCallback } from 'react'
import { Box, makeStyles } from '@material-ui/core';
import SubtitleComponent from '../../../components/SubtitleComponent';
import { useTranslation } from 'react-i18next';
import ParkingsLegenedSvg from '../../../svg/ParkingsLegenedSvg';
import { useDispatch, useSelector } from 'react-redux';
import LegendTitleComponent from './LegendTitleComponent';
import { TOGGLE_MAP_ZONE_REDUCER } from '../../../reducers/MapReducer';

const useStyles = makeStyles((theme) => ({
    legendCont: {
        marginTop: 40,
        marginBottom: 40,
    },
    legendTitles: {
        display: 'flex',
        flexWrap: 'wrap',
    },
}))

const LegendContainerComponent = ({ activeParkings, handleCloseAllPopups = () => {}, handleZoom = () => { }, handleToggleParkings = () => { } }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { data } = useSelector((state) => state.MapReducer)
    const dispatch = useDispatch();
    const handleToggleZone = useCallback((id, active, zoom, coords) => (e) => {
        handleCloseAllPopups();
        handleZoom(!active && coords?.length && zoom ? coords[1][0] : false)
        dispatch(TOGGLE_MAP_ZONE_REDUCER(id))
    }, [dispatch, handleZoom, handleCloseAllPopups])
    return (
        <Box className={classes.legendCont}>
            <Box>
                <SubtitleComponent
                    title={t('map_page.legend_title')}
                    className={classes.legendTitle}
                />
            </Box>
            <Box className={classes.legendTitles}>
                {data?.main_zone?.length
                    ? data.main_zone.map(({ title, id, color, active, zoom, coords }, key) =>
                        <LegendTitleComponent
                            active={active}
                            title={title}
                            justColor
                            onClick={handleToggleZone(id, active, zoom, coords)}
                            color={color}
                            key={key}
                        />
                    )
                    : null
                }
            </Box>
            <Box className={classes.legendTitles}>
                <LegendTitleComponent
                    title={t('map_page.cluster_legend_title')}
                    Icon={ParkingsLegenedSvg}
                    active={activeParkings}
                    onClick={handleToggleParkings}
                />
                {data?.zone?.length
                    ? data.zone.map(({ title, id, color, active, zoom, coords, icon_url }, key) =>
                        <LegendTitleComponent
                            active={active}
                            title={title}
                            src={icon_url}
                            onClick={handleToggleZone(id, active, zoom, coords)}
                            color={color}
                            key={key}
                        />
                    )
                    : null
                }
            </Box>
        </Box>
    )
}

export default React.memo(LegendContainerComponent);