import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/styles/makeStyles';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import FilterComponent from './FilterComponent';

const useStyles = (color) => makeStyles((theme) => ({
    iconImg: {
        width: '70%',
        height: '70%',
        objectFit: 'cover',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        margin: 'auto',
    },
    img: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        top: 0,
        left: 0,
        position: 'absolute',
    },
    boxWithoutImg: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        top: 0,
        left: 0,
        position: 'absolute',
        background: color || theme.palette.primary.main
    },
    imgCont: {
        position: 'relative',
        '&::after': {
            content: "''",
            display: 'block',
            paddingBottom: '100%',
        },
        '& > span': {
            display: 'flex !important',
        },
    },
    title: {
        fontSize: 22,
        marginTop: 10,
        marginBottom: 10,
    },
}))

const ChoiceContentComponent = ({ image, title, color }) => {
    const classes = useStyles(color)();

    return (
        <>
            <Box className={classes.imgCont}>
                {!color && image
                    ? <LazyLoadImage
                        effect="blur"
                        src={image}
                        alt={title}
                        className={classes.img}
                    />
                    : <Box className={classes.boxWithoutImg}>
                        <img
                            src={image}
                            alt={title}
                            className={classes.iconImg}
                        />
                    </Box>
                }
                <FilterComponent />
            </Box>
            <Typography className={classes.title}>
                {title}
            </Typography>
        </>
    )
}


ChoiceContentComponent.propTypes = {
    image: PropTypes.string,
    color: PropTypes.string,
    title: PropTypes.string,
}
export default React.memo(ChoiceContentComponent);