import React from 'react'
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import LinkComponent from './LinkComponent';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const useStyles = makeStyles((theme) => ({
    links: {
        marginTop: 20,
        display: 'block',
    },
    img: {
        width: '100%',
        objectFit: 'cover',
    }
}))


const LazyImgComponent = ({ src, alt, to, external_link }) => {
    const classes = useStyles();
    return (
        <LinkComponent to={to} className={classes.links} external_link={external_link}>
            <LazyLoadImage
                className={classes.img}
                src={src}
                alt={alt}
            />
        </LinkComponent>
    )
}


LazyImgComponent.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    to: PropTypes.string,
}
export default React.memo(LazyImgComponent);