import React from 'react'
import { useTranslation } from 'react-i18next';
import ErrorComponent from '../../components/ErrorComponent';

const ErrorPage = (props) => {
    const { t } = useTranslation();
    return (
        <ErrorComponent
            number='500'
            title={t('error_page.title')}
        />
    )
}

export default React.memo(ErrorPage);