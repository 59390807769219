import { MAIN_API } from '../constants/api';
import { GET } from '../functions/superagentSending';

const initialState = {
	loading: false,
	data: {},
	error: '',
};

const FETCH_DATA_REQUEST = 'FETCH_DATA_REQUEST';
const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE';

const fetchDataRequest = () => ({
	type: FETCH_DATA_REQUEST,
});

const fetchDataSuccess = (data) => ({
	type: FETCH_DATA_SUCCESS,
	payload: data,
});

const fetchDataFailure = (error) => ({
	type: FETCH_DATA_FAILURE,
	payload: error,
});

const HomePageReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case FETCH_DATA_REQUEST:
			return {
				...state,
				loading: true,
			};
		case FETCH_DATA_SUCCESS:
			return {
				...state,
				loading: false,
				data: payload,
				error: '',
			};
		case FETCH_DATA_FAILURE:
			return {
				loading: false,
				data: {},
				error: payload,
			};
		default:
			return state;
	}
};

export const FETCH_HOME_DATA_REDUCER = () => {
	return (dispatch) => {
		dispatch(fetchDataRequest());
		GET(MAIN_API)
			.then((success) => {
				dispatch(fetchDataSuccess(success?.body?.data));
			})
			.catch((error) => {
				fetchDataFailure(error);
				fetchDataFailure('');
			});
	};
};

export default HomePageReducer;
