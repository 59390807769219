import * as React from "react"

function ParkingsLegenedSvg(props) {
    return (
        <svg
            viewBox="0 0 71 71"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            fillRule="evenodd"
            clipRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit={2}
            {...props}
        >
            <g transform="matrix(1.00057 0 0 1.00148 21.6 11.794)">
                <clipPath id="ParkingsLegenedSvga">
                    <path d="M0 0h49.372v59.119H0z" />
                </clipPath>
                <g clipPath="url(#ParkingsLegenedSvga)">
                    <path
                        d="M42.142 7.23A24.695 24.695 0 0024.686-.001C11.143-.001-.001 11.143-.001 24.686A24.695 24.695 0 007.23 42.142l16.64 16.639a1.16 1.16 0 001.633 0l16.639-16.639c9.576-9.576 9.576-25.335 0-34.911V7.23z"
                        fill="#ea4c4c"
                        fillRule="nonzero"
                    />
                    <path
                        d="M39.546 9.826a21.022 21.022 0 00-14.86-6.155c-11.528 0-21.015 9.486-21.015 21.015 0 11.528 9.487 21.015 21.015 21.015s21.015-9.487 21.015-21.015a20.889 20.889 0 00-6.155-14.86zm-1.633 28.087a18.713 18.713 0 01-13.223 5.475c-10.262 0-18.706-8.444-18.706-18.706 0-10.262 8.444-18.706 18.706-18.706 10.262 0 18.706 8.444 18.706 18.706v.004a18.59 18.59 0 01-5.483 13.227z"
                        fill="#f2f2f2"
                        fillRule="nonzero"
                    />
                    <path
                        d="M27.082 17.007h-4.65a1.16 1.16 0 00-1.155 1.155v5.037a1.16 1.16 0 001.155 1.155h4.654a3.69 3.69 0 003.673-3.673 3.69 3.69 0 00-3.673-3.673l-.004-.001zm0 5.037h-3.5v-2.726h3.5a1.37 1.37 0 011.364 1.364 1.37 1.37 0 01-1.364 1.364v-.002z"
                        fill="#f2f2f2"
                        fillRule="nonzero"
                    />
                    <path
                        d="M27.085 12.81h-8.851a1.16 1.16 0 00-1.155 1.155v21.443a1.16 1.16 0 001.155 1.155h4.2a1.16 1.16 0 001.155-1.155v-6.857h3.5c4.318 0 7.87-3.553 7.87-7.87 0-4.318-3.552-7.871-7.87-7.871h-.004zm0 13.432h-4.654a1.16 1.16 0 00-1.155 1.155v6.857h-1.891V15.119h7.7c3.051 0 5.561 2.51 5.561 5.561s-2.51 5.561-5.561 5.561v.001zM11.268 15.605a1.161 1.161 0 00-1.577.423 17.284 17.284 0 00-1.973 5.209 1.16 1.16 0 001.126 1.413c.558 0 1.04-.404 1.137-.953a14.98 14.98 0 011.71-4.514 1.156 1.156 0 00-.423-1.578zM8.523 23.5a1.16 1.16 0 00-1.155 1.155v.065a1.16 1.16 0 001.155 1.155 1.16 1.16 0 001.155-1.155l-.001-.034v-.031c0-.633-.521-1.154-1.154-1.155zM37.559 34.529a1.16 1.16 0 00-1.63.1 14.99 14.99 0 01-3.738 3.055 1.156 1.156 0 00-.597 1.011 1.16 1.16 0 001.155 1.155c.21 0 .417-.057.597-.166a17.295 17.295 0 004.313-3.525 1.16 1.16 0 00-.1-1.63zM39.259 31.767a1.16 1.16 0 00-1.576.425l-.014.024a1.152 1.152 0 00-.155.579 1.155 1.155 0 002.155.578l.017-.03a1.16 1.16 0 00-.427-1.576z"
                        fill="#f2f2f2"
                        fillRule="nonzero"
                    />
                </g>
            </g>
            <use
                xlinkHref="#ParkingsLegenedSvg_Image2"
                width={55.346}
                height={65.044}
                transform="scale(.98833 .98551)"
            />
            <g transform="translate(1.101 1) scale(1.00057)">
                <clipPath id="ParkingsLegenedSvgb">
                    <path d="M0 0h49.372v59.119H0z" />
                </clipPath>
                <g clipPath="url(#ParkingsLegenedSvgb)">
                    <path
                        d="M42.142 7.23A24.695 24.695 0 0024.686-.001C11.143-.001-.001 11.143-.001 24.686A24.695 24.695 0 007.23 42.142l16.64 16.639a1.16 1.16 0 001.633 0l16.639-16.639c9.576-9.576 9.576-25.335 0-34.911V7.23z"
                        fill="#eabe07"
                        fillRule="nonzero"
                    />
                    <path
                        d="M39.546 9.826a21.022 21.022 0 00-14.86-6.155c-11.528 0-21.015 9.486-21.015 21.015 0 11.528 9.487 21.015 21.015 21.015s21.015-9.487 21.015-21.015a20.889 20.889 0 00-6.155-14.86zm-1.633 28.087a18.713 18.713 0 01-13.223 5.475c-10.262 0-18.706-8.444-18.706-18.706 0-10.262 8.444-18.706 18.706-18.706 10.262 0 18.706 8.444 18.706 18.706v.004a18.59 18.59 0 01-5.483 13.227z"
                        fill="#f2f2f2"
                        fillRule="nonzero"
                    />
                    <path
                        d="M27.082 17.007h-4.65a1.16 1.16 0 00-1.155 1.155v5.037a1.16 1.16 0 001.155 1.155h4.654a3.69 3.69 0 003.673-3.673 3.69 3.69 0 00-3.673-3.673l-.004-.001zm0 5.037h-3.5v-2.726h3.5a1.37 1.37 0 011.364 1.364 1.37 1.37 0 01-1.364 1.364v-.002z"
                        fill="#f2f2f2"
                        fillRule="nonzero"
                    />
                    <path
                        d="M27.085 12.81h-8.851a1.16 1.16 0 00-1.155 1.155v21.443a1.16 1.16 0 001.155 1.155h4.2a1.16 1.16 0 001.155-1.155v-6.857h3.5c4.318 0 7.87-3.553 7.87-7.87 0-4.318-3.552-7.871-7.87-7.871h-.004zm0 13.432h-4.654a1.16 1.16 0 00-1.155 1.155v6.857h-1.891V15.119h7.7c3.051 0 5.561 2.51 5.561 5.561s-2.51 5.561-5.561 5.561v.001zM11.268 15.605a1.161 1.161 0 00-1.577.423 17.284 17.284 0 00-1.973 5.209 1.16 1.16 0 001.126 1.413c.558 0 1.04-.404 1.137-.953a14.98 14.98 0 011.71-4.514 1.156 1.156 0 00-.423-1.578zM8.523 23.5a1.16 1.16 0 00-1.155 1.155v.065a1.16 1.16 0 001.155 1.155 1.16 1.16 0 001.155-1.155l-.001-.034v-.031c0-.633-.521-1.154-1.154-1.155zM37.559 34.529a1.16 1.16 0 00-1.63.1 14.99 14.99 0 01-3.738 3.055 1.156 1.156 0 00-.597 1.011 1.16 1.16 0 001.155 1.155c.21 0 .417-.057.597-.166a17.295 17.295 0 004.313-3.525 1.16 1.16 0 00-.1-1.63zM39.259 31.767a1.16 1.16 0 00-1.576.425l-.014.024a1.152 1.152 0 00-.155.579 1.155 1.155 0 002.155.578l.017-.03a1.16 1.16 0 00-.427-1.576z"
                        fill="#f2f2f2"
                        fillRule="nonzero"
                    />
                </g>
            </g>
            <defs>
                <image
                    id="ParkingsLegenedSvg_Image2"
                    width={56}
                    height={66}
                    xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAABCCAYAAAACPxW5AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAEV0lEQVRogd2b7XLiMAxFD+Gjpfv+j9pdoHTZH43ItSI7gcQQ9s543BYDPkiWJZuumK6V9KVmYy7tz5eBhvSTJzfluSugCdpaHlNISEH+At9tr80es/GTJnnPczzMGti0bS1NAdWCBvIt7ex6BYY7QDc3jrdJKtTWtY00tWRkQQU8A1/SztIbrD5/lMYCqtXUUjvgre13DtCsV3JRtZACnlxT0JvW5xhAb7WtgL07QLWcwkEf0IOqJQ3sCBzaXkFHu+wQoMGZO+5aKG1vdJaL1pyHU2nE1DVpH6J5xbYFPbSvZdYchCwBKtyGzmJ7aeqWEVgJzh6zCTYtoC6BnMsf2+cMQuYAFW7bwu2BjwBOrTYElHsvUyO9BrIoKpuKkBFgZLk98IsO0NabvaGf6L3S19B9NFrTqixkzoL26UVwb3Rw3h3nkkbtXFYEacLwTSAPqNHSAsoHKdyWOPTXkL3+2v3dbzP6e2JFBdTtQNfdnjRaPgpO5wUppN9aFBTpkydpUDHLmWu+0wWUR8KpcvtplMNetZYnldadhetnwamidaiWLAKaa+q6s4iZi2CPlN9f1VU1Z01cVLeFHR2cuaZGzCUoZ0FfgQDpxurzTN3El6iGLtvRXDiZs0ZEBbT8L8ocliCN+FbVWEsCYeMGal23RDAvzbjChN+7qK/IlxA1c4rKOIW8WtDD1U7D5pQv57wFExctZexLVmn+qwjw0anYFGkCrt7Ys6AfdG9t9yz5surK1AQDorJkyfIGSuY+VG+9kkKGpWYpc+i6Bv9XXeAHMHfx8WoKGRp50Jf/rwLqL3J6gH5AWPovXL6yvzI1pEWjH7B0RcZJil4PGFbFC5c/gNL59yx4lkGvZMWsgQzQHrQrrORTePiUx0ndUq/e7PYpdFG9gNS7uKVKjaN3iFcP1Chqg/w93BKtqIFF7xNPuOVl+6BaUAfrZePSpK6ZuwXunWxrVREdXSwhEdcldQL+AL/bdiR10eTg15cddgSgdZaNfZbUNe2K2wAPBLHD39pAHzKq8p8B6dedwX22/REXQaHvorjfS1/seYY8nLqmWS9RZEFTVCnnvhZSW6V1d6ALiL2IXwI0+eDz6GMNvx0c+AEruqYptwb1xfXvEWBNyBycumZxKytZMNrgo8OdWpAR3GfbFK6YN49xUV8APwKyZDlzS90Sbv6ejL7JGM15QVpacxpURpV1Yyyob6x9zopTIGeFg9sAbQLaw3yQs8PB7YA2Ee1hOmQVOLgP0CakPdwPWQ0O7ge0iWkPt0NWhYNpgDZB6w1kLGR1OJgOaBOlncRYSH9MUgUO5gGE8ZDIOJ84zw4H8wHCMKSOs6OGqnAwLyCMg6zulqq5ASGOriu6dfdFWo1Xg4M6gJAeL0A/YkYFa5Urg1v/82VICnUiddkTPx+o/az/D1HtPmRuQOhXIXb6bBWHP2qvetlTAxBi97Rgk7uuq6LaZyrRZn8JWtUJ1NZQLlpV/wB5c38v0YEatQAAAABJRU5ErkJggg=="
                />
            </defs>
        </svg>
    )
}

export default ParkingsLegenedSvg
