import React from 'react'
import { Polygon } from '@react-google-maps/api';
import { useSelector } from 'react-redux';


const PolygonsComponent = (props) => {
    const { data } = useSelector((state) => state.MapReducer)
    return (
       <>
            {data?.border?.length ? data.border.map((s, key) =>
                <Polygon
                    key={key}
                    paths={[s.reverse()]}
                    options={{
                        strokeColor: "#000000",
                        strokeOpacity: 0.8,
                        strokeWeight: 3,
                        fillColor: "#000000",
                        fillOpacity: 0
                    }}
                ></Polygon>
            ) : null}

       </>
    )
}

export default React.memo(PolygonsComponent);