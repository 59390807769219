import { DEV_MAP_API, STREET_API } from '../constants/api';
import { GET } from '../functions/superagentSending';
// import redZone from '../pages/mapPage/redZone.json'
// import yellowZone from '../pages/mapPage/yellowZone.json'
// import zone from '../jsons/szczecinCoords.json'
const initialState = {
    loading: false,
    loadingStreet: false,
    data: {
        //     redZone,
        //     yellowZone,
        //     zone,
        //     redZoneParking: [
        //         {
        //         "lng": 14.5567767,
        //         "lat": 53.4302224
        //     },
        //         {
        //             "lng": 14.5593034,
        //             "lat": 53.4286802
        //         }, {
        //             "lng": 14.5599739,
        //             "lat": 53.4301185
        //         }, {
        //             "lng": 14.5602743,
        //             "lat": 53.4307513
        //         }, {
        //             "lng": 14.5602636,
        //             "lat": 53.4307833
        //         }, {
        //             "lng": 14.5600759,
        //             "lat": 53.4307801
        //         }, {
        //             "lng": 14.5600008,
        //             "lat": 53.4307481
        //         }, {
        //             "lng": 14.5599632,
        //             "lat": 53.430697
        //         }
        // ],
        // yellowZoneParking: [
        //     {
        //         "lng": 14.5561896,
        //         "lat": 53.422149
        //     }, {
        //         "lng": 14.5560609,
        //         "lat": 53.4222449
        //     }, {
        //         "lng": 14.5559214,
        //         "lat": 53.4223376
        //     }, {
        //         "lng": 14.5558463,
        //         "lat": 53.4224015
        //     }, {
        //         "lng": 14.5558624,
        //         "lat": 53.4224686
        //     },
        //     {
        //         "lng": 14.5486792,
        //         "lat": 53.4349282
        //     }, {
        //         "lng": 14.5486416,
        //         "lat": 53.4349762
        //     }, {
        //         "lng": 14.5485665,
        //         "lat": 53.4349921
        //     }, {
        //         "lng": 14.5487543,
        //         "lat": 53.4358071
        //     }, {
        //         "lng": 14.5487865,
        //         "lat": 53.4359924
        //     }, {
        //         "lng": 14.5488079,
        //         "lat": 53.436181
        //     }
        // ],
    },
    streets: [],
    error: '',
};

const FETCH_MAP_REQUEST = 'FETCH_MAP_REQUEST';
const SEND_STREET_REQUEST = 'SEND_STREET_REQUEST';
const FETCH_MAP_SUCCESS = 'FETCH_MAP_SUCCESS';
const SEND_STREET_SUCCESS = 'SEND_STREET_SUCCESS';
const FETCH_MAP_FAILURE = 'FETCH_MAP_FAILURE';
const TOGGLE_MAP_ZONE_SUCCESS = 'TOGGLE_MAP_ZONE_SUCCESS';

const fetchMapRequest = () => ({
    type: FETCH_MAP_REQUEST,
});
const sendStreetRequest = () => ({
    type: SEND_STREET_REQUEST,
});

const fetchMapSuccess = (data) => ({
    type: FETCH_MAP_SUCCESS,
    payload: data,
});
const toggleMapZoneSuccess = (id) => ({
    type: TOGGLE_MAP_ZONE_SUCCESS,
    payload: id,
});
const sendStreetSuccess = (data) => ({
    type: SEND_STREET_SUCCESS,
    payload: data,
});

const fetchMapFailure = (error) => ({
    type: FETCH_MAP_FAILURE,
    payload: error,
});

const MapPageReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_MAP_REQUEST:
            return {
                ...state,
                loading: true,
                loadingStreet: false,
            };
        case SEND_STREET_REQUEST:
            return {
                ...state,
                loading: false,
                loadingStreet: true,
            };
        case SEND_STREET_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingStreet: false,
                streets: payload,
                error: '',
            };
        case FETCH_MAP_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingStreet: false,
                data: payload,
                error: '',
            };
        case FETCH_MAP_FAILURE:
            return {
                ...state,
                loading: false,
                loadingStreet: false,
                data: {},
                streets: [],
                error: payload,
            };
        default:
            return state;
    }
};

export const FETCH_MAP_REDUCER = () => {
    return (dispatch) => {
        dispatch(fetchMapRequest());
        GET(DEV_MAP_API)
            .then((success) => {
                dispatch(fetchMapSuccess(success?.body));
            })
            .catch((error) => {
                fetchMapFailure(error);
                fetchMapFailure('');
            });
    };
};
export const TOGGLE_MAP_ZONE_REDUCER = (id) => {
    return (dispatch) => {
        dispatch(toggleMapZoneSuccess(id))
    };
};
export const SEND_MAP_REDUCER = (name) => {
    return (dispatch) => {
        window.stop();
        dispatch(sendStreetRequest());
        GET(`${STREET_API}/${name}`)
            .then((success) => {
                dispatch(sendStreetSuccess(success?.body?.data));
            })
            .catch((error) => {
                fetchMapFailure(error);
                fetchMapFailure('');
            });
    };
};

export default MapPageReducer;
