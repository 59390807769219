import React, { useCallback } from 'react'
import TextFieldComponent from '../../../components/TextFieldComponent';
import CheckboxComponent from '../../../components/CheckboxComponent';
import SendBtnComponent from '../../../components/SendBtnComponent';
import ButtonComponent from '../../../components/ButtonComponent';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import { SEND_COMPLAINT_REDUCER } from '../../../reducers/ComplaintReducer';
import { ZIP_CODE_REGEX } from '../../../constants';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SubtitleComponent from '../../../components/SubtitleComponent';
import { PRIVACY_ROUTE } from '../../../constants/routes';
// import SelectsCardComponent from '../../../components/SelectsCardComponent';
// import SelectComponent from '../../../components/SelectComponent';
import RadioComponent from '../../../components/RadioComponent';
import useLngUrlEnd from '../../../hooks/useLngUrlEnd';

const useStyles = makeStyles((theme) => ({
    form: {
        marginTop: 20,
    },
    textarea: {
        '& .MuiOutlinedInput-multiline': {
            padding: '18.5px 20px',
            borderRadius: 30,
        }
    },
    addressCont: {
        '& > div': {
            padding: '0 4px',
        }
    },
    uploadCont: {
        marginTop: 10,
        marginBottom: 20,
    },
    uploadImage: {
        display: 'none',
    },
}))

const complaintSchema = (t) =>
    Yup.object().shape({
        first_name: Yup.string()
            .required(t('validation.required'))
            .min(2, t('validation.min', { item: 2 })),
        last_name: Yup.string()
            .required(t('validation.required'))
            .min(2, t('validation.min', { item: 2 })),
        email: Yup.string()
            .when('type_answer', {
                is: (typeAnswer) => typeAnswer === 'online',
                then: Yup.string()
                    .required(t('validation.required'))
                    .email(t('validation.email')),
                otherwise: Yup.string(),
            }),

        street: Yup.string().when('type_answer', {
            is: (typeAnswer) => typeAnswer === 'offline',
            then: Yup.string().required(t('validation.required')),
            otherwise: Yup.string(),
        }),
        house_number: Yup.string().when('type_answer', {
            is: (typeAnswer) => typeAnswer === 'offline',
            then: Yup.string().required(t('validation.required')),
            otherwise: Yup.string(),
        }),
        zip_code: Yup.string().when('type_answer', {
            is: (typeAnswer) => typeAnswer === 'offline',
            then: Yup.string().required(t('validation.required'))
            .matches(ZIP_CODE_REGEX, t('validation.zip_code')),
            otherwise: Yup.string(),
        }),
        city: Yup.string().when('type_answer', {
            is: (typeAnswer) => typeAnswer === 'offline',
            then: Yup.string().required(t('validation.required')),
            otherwise: Yup.string(),
        }),
        complaint_topic: Yup.string().required(t('validation.required')),
        register_number: Yup.string().required(t('validation.required')),
        notification_number: Yup.string().required(t('validation.required')),
        type_answer: Yup.string().required(t('validation.required')),
        complaint_message: Yup.string()
            .required(t('validation.required'))
            .min(10, t('validation.min', { item: 10 })),
        agreement: Yup.boolean().oneOf([true], t('validation.required')),
    });

const ComplaintFormComponent = (props) => {
    const { data, sendLoading, complaintSuccess, errorSend } = useSelector((state) => state.ComplaintReducer)
    const { executeRecaptcha } = useGoogleReCaptcha();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const currentLng = useLngUrlEnd(i18n);
    const classes = useStyles();
    const formik = useFormik({
        validationSchema: complaintSchema(t),
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
            complaint_topic: '',
            street: '',
            house_number: '',
            zip_code: '',
            city: '',
            notification_number: '',
            apartment_number: '',
            register_number: '',
            type_answer: '',
            complaint_message: '',
            agreement: false,
            files: [],
        },
        onSubmit: (values) => {
            executeRecaptcha('complaint_form').then((token) => {
                dispatch(SEND_COMPLAINT_REDUCER({ ...values, _recaptcha: token }));
            });
        },
    });

    const handleUploadFile = useCallback((e) => {
        const files = [];
        if (e?.target?.files?.length) {
            Object.values(e.target.files).forEach((file) => {
                files.push(file);
            })
            formik.setFieldValue('files', files)
        }
    }, [formik])

    const handleDeleteFile = useCallback(() => {
        formik.setFieldValue('files', [])
    }, [formik])
    return (
        <form onSubmit={formik.handleSubmit} className={classes.form}>
            <Box mb='20px' hidden={!errorSend}>
                <Alert severity="error">{t('complaint_page.error')}</Alert>
            </Box>
            <TextFieldComponent
                id='first_name'
                autoComplete='given-name'
                formikProps={formik}
            />
            <TextFieldComponent
                id='last_name'
                autoComplete='family-name'
                formikProps={formik}
            />
            <RadioComponent
                formikProps={formik}
                id='complaint_topic'
                items={data}
            />
            <TextFieldComponent
                id='notification_number'
                formikProps={formik}
            />
            <TextFieldComponent
                id='register_number'
                formikProps={formik}
            />
            <RadioComponent
                formikProps={formik}
                id='type_answer'
                items={[
                    {
                        title: t('form.online_answer'),
                        value: 'online'
                    },
                    {
                        title: t('form.offline_answer'),
                        value: 'offline'
                    },
                ]}
            />
            <TextFieldComponent
                id='email'
                autoComplete='email'
                type='email'
                formikProps={formik}
            />
            <Box mt='10px' mb='10px'>
                <SubtitleComponent title={t('complaint_page.address_title')} variant='h3' />
            </Box>
            <Grid container className={classes.addressCont}>
                <Grid item md={6} xs={12}>
                    <TextFieldComponent
                        id='street'
                        autoComplete='street-address'
                        formikProps={formik}
                    />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                    <TextFieldComponent
                        id='house_number'
                        formikProps={formik}
                    />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                    <TextFieldComponent
                        id='apartment_number'
                        formikProps={formik}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextFieldComponent
                        id='zip_code'
                        autoComplete='postal-code'
                        formikProps={formik}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextFieldComponent
                        id='city'
                        autoComplete='address-level2'
                        formikProps={formik}
                    />
                </Grid>
            </Grid>
            <Box mt='10px'>
                <TextFieldComponent
                    id='complaint_message'
                    multiline
                    className={classes.textarea}
                    rows={8}
                    formikProps={formik}
                />
                <Box className={classes.uploadCont}>
                    <input
                        accept="image/*"
                        className={classes.uploadImage}
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={handleUploadFile}
                    />
                    <label htmlFor="contained-button-file">

                        {formik?.values?.files?.length
                            ? <ButtonComponent
                                onClick={handleDeleteFile}
                                variant="contained"
                                color="default"
                                component="span"
                                startIcon={<DeleteIcon />}
                            >
                                {t('btns.uploaded')}
                            </ButtonComponent>
                            : <ButtonComponent variant="contained" color="primary" component="span">
                                {t('btns.upload')}
                            </ButtonComponent>
                        }
                    </label>
                </Box>
                <CheckboxComponent
                    formikProps={formik}
                    id="agreement"
                    label={t('form.agreement', {
                        href: `${window.location.origin}${PRIVACY_ROUTE}${currentLng}`,
                    })}
                />
            </Box>
            <Box mt='10px' >
                <SendBtnComponent
                    sendLoading={sendLoading}
                    formikProps={formik}
                    message={t('success.contact_form')}
                    showSuccess={complaintSuccess}
                />
            </Box>
        </form>
    )
}

export default React.memo(ComplaintFormComponent);