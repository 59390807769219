import superagent from 'superagent';
import { API_URL, PL_LNG } from '../constants';

const getLowerCaseLng = (lang) => (lang ? lang.toLowerCase() : PL_LNG);
const getLang = () => localStorage.getItem('lang') || PL_LNG;
export const POST = async (url, data = {}) => {
   const lang = getLowerCaseLng(getLang());
    return superagent
        .post(`${API_URL}/${lang}/api${url}`)
        .set('Accept', 'application/json')
        .set('enctype', 'multipart/form-data')
        .send(data);
};
export const GET = async (url, query = {}) => {
    const lang = getLowerCaseLng(getLang());
    return superagent
      .get(`${API_URL}/${lang}/api${url}`)
      .set('Accept', 'application/json')
      .query(query);
};
