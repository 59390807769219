import React  from 'react'
import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/styles/makeStyles'
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    icon: {
        width: 25,
        height: 25,
    },
    btn: {
        padding: 4,
    }
}))

const LngBtnComponent = ({ Icon, lng, handleChangeLng = () => {}}) => {
    const classes = useStyles();
  
  
    return (
        <IconButton className={classes.btn} onClick={() => handleChangeLng(lng)}>
            <Icon className={classes.icon} />
        </IconButton>
    )
}


LngBtnComponent.propTypes = {
    Icon: PropTypes.func,
    lng: PropTypes.string,
}
export default React.memo(LngBtnComponent);