import React, { useEffect, useMemo } from 'react'
import Box from '@material-ui/core/Box'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import makeStyles from '@material-ui/styles/makeStyles'
import useTheme from '@material-ui/styles/useTheme'
import PropTypes from 'prop-types';
import AppBarComponent from './AppBarComponent';
// import GalleryComponent from './GalleryComponent';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { COMPLAINT_ROUTE, ERROR_ROUTE, MAIN_ROUTE, EMPTY_ROUTE } from '../constants/routes';
import { useTranslation } from 'react-i18next';
import LinkComponent from './LinkComponent';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import LngsComponent from './LngsComponent';
// import ShortsComponent from './ShortsComponent';
import AccessContentComponent from './AccessContentComponent';
import SearchComponent from './SearchComponent';
import { Skeleton } from '@material-ui/lab';
import loadable from '@loadable/component'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const CookiesComponent = loadable(() => import('./CookiesComponent'))
const PopupComponent = loadable(() => import('./PopupComponent'));
const FooterComponent = loadable(() => import('./FooterComponent'))
const GalleryComponent = loadable(() => import('./GalleryComponent'))
const ShortsComponent = loadable(() => import('./ShortsComponent'))

const useStyles = (smallHero) => makeStyles((theme) => ({
  langsCont: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
    //  /   justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column",
      alignItems: 'baseline',
      width: '100%',
    },
  },
  headerGrid: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column-reverse",
      alignItems: 'baseline',
    },
  },
  img: {
    width: '100%',
    height: smallHero ? 230 : 'calc(90vh - 150px)',
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      height: '60vh',
    },
    [theme.breakpoints.down('xs')]: {
      height: smallHero ? 230 : 370,
    },

  },
  paper: {
    margin: 'auto',
    boxShadow: 'none',
    marginTop: -40,
    background: theme.palette.background.default,
    position: 'relative',
    zIndex: 1,
    width: 'calc(100% - 40px)',
    minHeight: 300,
    paddingTop: 20,
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 20px)',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: -40,
      borderBottom: `40px solid ${theme.palette.background.default}`,
      borderLeft: `40px solid transparent`,
    }
  },
  headerCont: {
    marginTop: 20,
  },
  divider: {
    marginTop: 5,
    marginBottom: 20,
  },
  downIcon: {
    marginLeft: 5,
  },
  leftPart: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 20,
    },
  },
  breadcrumbTitle: {
    color: `${theme.palette.text.primary}99`,
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.body1.fontSize,
      color: theme.palette.text.primary
    },
  },
  breadcrumbs: {
    '& li:last-child': {
      maxWidth: '100%',
    }
  },
  breadcrumbsCont: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 5
    },
  }
}))


const MainContainerComponent = ({
  stopLoadingHero,
  heroImg,
  mobileImg,
  sideMenu,
  subMenu,
  rightStartPart,
  loading,
  title,
  smallHero,
  children,
  showTitle,
  breadcrumbs,
  rightPart,
  leftPart,
  hiddenAccess,
  hiddenSearch,
  complaint,
  gallery,
  shorts
}) => {
  const classes = useStyles(smallHero)();
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  const matchXs = useMediaQuery(theme.breakpoints.down('xs'));
  const state = useSelector(state => state);
  const errorsArray = useMemo(() => Object.keys(state).filter((key) => state[key].error).map((key) => state[key].error), [state]);
  useEffect(() => {
    window.scrollTo(0, 0)
    // eslint-disable-next-line
  }, [window.location.href])
  useEffect(() => {
    if (errorsArray.length) {
      if (errorsArray[0]?.status === 404)
        history.push(EMPTY_ROUTE)
      else
        history.push(ERROR_ROUTE)
    }
  }, [errorsArray, history])
  return (
    <Box>
      <AppBarComponent />
      {stopLoadingHero ? (
        <img
          src={matchXs && mobileImg ? mobileImg : heroImg}
          alt={title}
          className={classes.img}
        />
      ) : loading ? (
        <Skeleton variant="rect" className={classes.img} />
      ) : (
        <img
          src={matchXs && mobileImg ? mobileImg : heroImg}
          alt={title}
          className={classes.img}
        />
      )}

      <Container>
        <Paper className={classes.paper}>
          {showTitle ? (
            <Typography variant="h1" color="primary">
              {title}
            </Typography>
          ) : null}
          <Box className={classes.headerCont} component="header">
            <Grid
              container
              justify="space-between"
              alignItems="center"
              className={classes.headerGrid}
            >
              <Grid
                item
                md={6}
                sm={4}
                xs={12}
                className={classes.breadcrumbsCont}
              >
                {matchXs ? (
                  <LinkComponent
                    className={classes.breadcrumbTitle}
                    noWrap
                    to={MAIN_ROUTE}
                  >
                    {' '}
                    {t('main.breadcrumb')}{' '}
                  </LinkComponent>
                ) : (
                  <Breadcrumbs
                    className={classes.breadcrumbs}
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                  >
                    <Box display="flex" alignItems="center">
                      <LinkComponent to={MAIN_ROUTE}>
                        {t('main.breadcrumb')}
                      </LinkComponent>
                      {!breadcrumbs?.length ? (
                        <KeyboardArrowDownIcon className={classes.downIcon} />
                      ) : null}
                    </Box>
                    {breadcrumbs?.length
                      ? breadcrumbs.map(({ to, title }, key) =>
                        key + 1 === breadcrumbs?.length ? (
                          <Typography
                            key={key}
                            className={classes.breadcrumbTitle}
                            noWrap
                          >
                            {' '}
                            {title}{' '}
                          </Typography>
                        ) : (
                          <LinkComponent to={to} key={key}>
                            {title}
                          </LinkComponent>
                        )
                      )
                      : null}
                  </Breadcrumbs>
                )}
              </Grid>
              {!matchXs ? (
                <Grid
                  item
                  md={4}
                  sm={8}
                  xs={12}
                  className={classes.langsCont}
                >
                  <AccessContentComponent />
                  <LngsComponent />
                  {!hiddenSearch ? <SearchComponent /> : null}
                </Grid>
              ) : null}
            </Grid>
            <Divider className={classes.divider} />
          </Box>
          <Grid container justify="space-between">
            <Grid item md={6} xs={12} className={classes.leftPart}>
              {leftPart}
            </Grid>
            <Grid item md={4} xs={12}>
              {rightStartPart}
              {subMenu?.length || sideMenu?.length || complaint ? (
                <Box>
                  <ShortsComponent
                    loading={loading}
                    title={t('main.side_menu')}
                    shorts={[
                      ...(sideMenu?.length ? sideMenu : []),
                      ...(subMenu?.length ? subMenu : []),
                      ...(complaint
                        ? [
                          {
                            title: t('main.complaint'),
                            to: COMPLAINT_ROUTE,
                          },
                        ]
                        : []),
                    ]}
                  />
                </Box>
              ) : null}
              <GalleryComponent loading={loading} slides={gallery || []} />
              {subMenu?.length || sideMenu?.length || complaint ? null : (
                <ShortsComponent loading={loading} shorts={shorts || []} />
              )}
              {/* {!hiddenAccess ? <AccessContentComponent /> : null} */}
              {rightPart}
            </Grid>
          </Grid>
          <Box>{children}</Box>
        </Paper>
      </Container>
      <CookiesComponent />
      <FooterComponent />
      <PopupComponent />
    </Box>
  );
}


MainContainerComponent.propTypes = {
  heroImg: PropTypes.string,
  mobileImg: PropTypes.string,
  title: PropTypes.string,
  showTitle: PropTypes.bool,
  hiddenAccess: PropTypes.bool,
  loading: PropTypes.bool,
  smallHero: PropTypes.bool,
  complaint: PropTypes.bool,
  hiddenSearch: PropTypes.bool,
  breadcrumbs: PropTypes.array,
  sideMenu: PropTypes.array,
  subMenu: PropTypes.array,
  rightPart: PropTypes.object,
  leftPart: PropTypes.object,
}
export default MainContainerComponent;