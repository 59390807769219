import React from 'react'
import Box from '@material-ui/core/Box'
import PropTypes from 'prop-types';
import MainContainerComponent from './MainContainerComponent';
import RecommendComponent from './RecommendComponent';
import ReadAlsoComponent from './ReadAlsoComponent';
import NewsComponent from './NewsComponent';
import HeroImage from '../imgs/pagesHero.jpg';
import HeroImageMobile from '../imgs/pagesHeroMobile.jpg'

const ArticleComponent = ({ data, breadcrumbs = [], loading }) => {
    return (
        <MainContainerComponent
            heroImg={HeroImage}
            mobileImg={HeroImageMobile}
            loading={loading}
            title={data?.title}
            smallHero
            complaint={data?.complaint}
            gallery={data?.gallery}
            shorts={data?.side_menu?.length ? [] : data?.shorts}
            sideMenu={data?.side_menu}
            subMenu={data?.sub_menu}
            breadcrumbs={[
                ...(data?.breadcrumbs?.length ? data?.breadcrumbs : []),
                ...breadcrumbs,
                {
                    title: data?.title,
                }
            ]}
            rightPart={<Box>
                <RecommendComponent
                    loading={loading}
                    items={data?.recommend}
                />
                <ReadAlsoComponent
                    loading={loading}
                    items={data.also_read || []}
                />
            </Box>}
            leftPart={<NewsComponent
                heroImg={data?.image}
                mobileImg={data?.image_mobile}
                loading={loading}
                title={data?.title}
                content={data?.content}
                date={data?.date}
                files={data?.files}
                author={data?.author}
            />}
        />
    )
}


ArticleComponent.propTypes = {
    data: PropTypes.object,
    loading: PropTypes.bool,
}
export default React.memo(ArticleComponent);