import React from 'react'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/styles/makeStyles'
import PropTypes from 'prop-types';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

const useStyles = makeStyles((theme) => ({
    cont: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 10,
        background: theme.palette.gray.lightest,
    },
    arrowCont: {
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        marginLeft: 5,
        marginRight: 5,
    },
    total: {
        color: theme.palette.primary.main,
    }
}))

const PaginationComponent = ({ title, current = 1, total = 1, onChange = () => { } }) => {
    const classes = useStyles();

    return (
        <Box className={classes.cont}>
            <Typography>
                {title}
            </Typography>
            <Box className={classes.arrowCont}>
                {current > 1
                    ? <IconButton onClick={() => onChange(current - 1)}>
                        <KeyboardArrowLeftIcon />
                    </IconButton>
                    : null
                }
                <Typography className={classes.title}>
                    {current}/<span className={classes.total}>{total}</span>
                </Typography>
                {current < total
                    ? <IconButton onClick={() => onChange(current + 1)}>
                        <KeyboardArrowRightIcon />
                    </IconButton>
                    : null
                }
            </Box>
        </Box>
    )
}


PaginationComponent.propTypes = {
    title: PropTypes.string,
    current: PropTypes.number,
    total: PropTypes.number,
    onChange: PropTypes.func,
}
export default React.memo(PaginationComponent);