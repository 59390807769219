
import TextField from '@material-ui/core/TextField'
import withStyles from '@material-ui/styles/withStyles'

const CustomTextFieldComponent = withStyles((theme) => ({
    root: {
        marginTop: 5,
        '& input': {
            padding: 20,
            minWidth: 100,
            "&::placeholder": {
                opacity: 1,
                color: theme.palette.text.input,
            },
        },
        '& textarea': {
            "&::placeholder": {
                opacity: 1,
                color: theme.palette.text.input,
            },
        },
        '& fieldset': {
            border: 'none',
        },
        '&>div': {
            borderRadius: 50,
            background: theme.palette.input.background,
        },
        '& label.Mui-focused': {
            color: theme.palette.text.primary,
        },
    },
}))(TextField);


export default CustomTextFieldComponent;