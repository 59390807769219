import React from 'react'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import makeStyles from '@material-ui/styles/makeStyles'
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    img: {
        minWidth: 190,
        height: 130,
        marginRight: 20,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    desc: {
        width: '100%',
        height: 120,
        [theme.breakpoints.down('xs')]: {
            marginTop: 10,
        },
    },
    content: {
        display: "flex",
        justifyContent: "space-between",
        paddingBottom: 10,
        paddingTop: 10,
        alignItems: "start",
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            paddingBottom: 10,
        },
    },
    btns: {
        width: '100%',
        height: 40,
        marginTop: 10,
        marginBottom: 40,
    },
    header: {
        width: '100%',
        height: 40,
        marginBottom: 5,
    }
}))

const NewsListItemSkeletonComponent = (props) => {
    const classes = useStyles();

    return (
        <Box>
            <Skeleton variant="rect" className={classes.header} />
            <Box className={classes.content}>
                <Skeleton variant="rect" className={classes.img} />
                <Skeleton variant="rect" className={classes.desc} />
            </Box>
            <Divider />
            <Skeleton variant="rect" className={classes.btns} />
        </Box>
    )
}

export default React.memo(NewsListItemSkeletonComponent);