import { COMPLAINT_API } from "../constants/api";
import { GET, POST } from "../functions/superagentSending";

const initialState = {
    loading: false,
    data: [],
    error: '',
    sendLoading: false,
    errorSend: '',
    complaintSuccess: false,
};

const FETCH_COMPLAINT_SUCCESS = 'FETCH_COMPLAINT_SUCCESS'
const FETCH_COMPLAINT_REQUEST = 'FETCH_COMPLAINT_REQUEST'
const FETCH_COMPLAINT_FAILURE = 'FETCH_COMPLAINT_FAILURE'
const SEND_COMPLAINT_REQUEST = 'SEND_COMPLAINT_REQUEST'
const SEND_COMPLAINT_SUCCESS = 'SEND_COMPLAINT_SUCCESS'
const SEND_COMPLAINT_FAILURE = 'SEND_COMPLAINT_FAILURE'

const sendComplaintRequest = () => ({
    type: SEND_COMPLAINT_REQUEST
})
const sendComplaintSuccess = data => ({
    type: SEND_COMPLAINT_SUCCESS,
    payload: data
})
const sendComplaintFailure = error => ({
    type: SEND_COMPLAINT_FAILURE,
    payload: error
})

const fetchComplaintSuccess = (data) => ({
    type: FETCH_COMPLAINT_SUCCESS,
    payload: data
})

const fetchComplaintRequest = () => ({
    type: FETCH_COMPLAINT_REQUEST
})

const fetchComplaintFailure = error => ({
    type: FETCH_COMPLAINT_FAILURE,
    payload: error
})

const ComplaintReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_COMPLAINT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_COMPLAINT_SUCCESS:
            return {
                ...state,
                loading: false,
                sendLoading: false,
                data: payload,
                error: '',
                errorSend: '',
            }
        case FETCH_COMPLAINT_FAILURE:
            return {
                loading: false,
                sendLoading: false,
                data: [],
                error: payload,
                errorSend: '',
            }
        case SEND_COMPLAINT_SUCCESS:
            return {
                ...state,
                sendLoading: false,
                complaintSuccess: true,
            }
        case SEND_COMPLAINT_REQUEST:
            return {
                ...state,
                sendLoading: true,
                complaintSuccess: false,

            }
        case SEND_COMPLAINT_FAILURE:
            return {
                loading: false,
                sendLoading: false,
                data: [],
                error: '',
                errorSend: payload,
            }
        default:
            return state
    }
}

export const FETCH_COMPLAINT_REDUCER = () => {
    return (dispatch) => {
        dispatch(fetchComplaintRequest())
        GET(COMPLAINT_API).then((success) => {
            dispatch(fetchComplaintSuccess(success.body))
        }).catch((error) => {
            dispatch(fetchComplaintFailure(error));
            dispatch(fetchComplaintFailure(''));
        })
    }
}

export const SEND_COMPLAINT_REDUCER = (data) => {
    return (dispatch) => {
        const fd = new FormData();
        Object.keys(data).forEach((item) => {
            if (item !== 'files')
                fd.append(item, data[item])
            else
                data[item].forEach((file) => {
                    fd.append(`${item}[]`, file)
                })

        })
        dispatch(sendComplaintRequest())
        POST(COMPLAINT_API, fd).then((success) => {
            dispatch(sendComplaintSuccess())
        }).catch((error) => {
            dispatch(sendComplaintFailure(error));
        })
    }
}

export default ComplaintReducer