import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ArticleComponent from '../../components/ArticleComponent';
import { FETCH_REGULATIONS_REDUCER } from '../../reducers/RegulationsReducer';


const RegulationsPage = (props) => {
    const { data, loading } = useSelector((state) => state.RegulationsReducer);
    const dispatch = useDispatch();
    const { slug } = useParams();

    useEffect(() => {
        dispatch(FETCH_REGULATIONS_REDUCER(slug));
    }, [dispatch, slug]);
    return <ArticleComponent data={data} loading={loading} />
}


export default React.memo(RegulationsPage);