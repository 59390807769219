import React from 'react'
import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/styles/makeStyles'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextFieldComponent from '../../../components/TextFieldComponent';
import SendBtnComponent from '../../../components/SendBtnComponent';
import CheckboxComponent from '../../../components/CheckboxComponent';
import Alert from '@material-ui/lab/Alert';
import { SEND_CONTACT_REDUCER } from '../../../reducers/ContactReducer';
import { useGoogleReCaptcha, } from 'react-google-recaptcha-v3'
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PRIVACY_ROUTE } from '../../../constants/routes';
import useLngUrlEnd from '../../../hooks/useLngUrlEnd';
import RadioComponent from '../../../components/RadioComponent';
import SelectComponent from '../../../components/SelectComponent';

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: 20,
  },
  textarea: {
    '& .MuiOutlinedInput-multiline': {
      padding: '18.5px 20px',
      borderRadius: 30,
    }
  },
  btn: {
    marginTop: 10,
  },
}))

const contactSchema = (t) => Yup.object().shape({
  first_name: Yup.string()
    .required(t('validation.required'))
    .min(2, t('validation.min', { item: 2 })),
  last_name: Yup.string()
    .required(t('validation.required'))
    .min(2, t('validation.min', { item: 2 })),
  topic: Yup.string()
    .required(t('validation.required')),
  agreement: Yup.boolean()
    .oneOf([true], t('validation.required')),
  email: Yup.string()
    .required(t('validation.required'))
    .email(t('validation.email')),
  message: Yup.string()
    .required(t('validation.required'))
    .min(10, t('validation.min', { item: 10 })),
});


const ContactFormComponent = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const currentLng = useLngUrlEnd(i18n);
  const { data, sendLoading, contactSuccess, errorSend } = useSelector((state) => state.ContactReducer)
  const formik = useFormik({
    validationSchema: contactSchema(t),
    initialValues: {
      first_name: '',
      last_name: '',
      topic: '',
      email: '',
      message: '',
      agreement: false,
    },
    onSubmit: values => {
      executeRecaptcha("contact_form").then((token) => {
        dispatch(SEND_CONTACT_REDUCER({ ...values, _recaptcha: token }));
      })
      // console.log(values);
    },
  });
  return (
    <form onSubmit={formik.handleSubmit} className={classes.form}>
      <Box mb="20px" hidden={!errorSend}>
        <Alert severity="error">{t('contact_page.error')}</Alert>
      </Box>
      <TextFieldComponent
        id="first_name"
        autoComplete="given-name"
        formikProps={formik}
      />
      <TextFieldComponent
        id="last_name"
        autoComplete="family-name"
        formikProps={formik}
      />
      <RadioComponent
        formikProps={formik}
        id='topic'
        items={data}
      />
      <SelectComponent formikProps={formik} id="topic" items={data} />
      <TextFieldComponent
        id="email"
        autoComplete="email"
        type="email"
        formikProps={formik}
      />
      <TextFieldComponent
        id="message"
        multiline
        className={classes.textarea}
        rows={8}
        formikProps={formik}
      />
      <CheckboxComponent
        formikProps={formik}
        id="agreement"
        label={t('form.agreement', {
          href: `${window.location.origin}${PRIVACY_ROUTE}${currentLng}`,
        })}
      />
      <Box mt="10px">
        <SendBtnComponent
          sendLoading={sendLoading}
          formikProps={formik}
          message={t('success.contact_form')}
          showSuccess={contactSuccess}
        />
      </Box>
    </form>
  );
}

export default React.memo(ContactFormComponent);