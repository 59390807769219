import { NEWS_API } from "../constants/api";
import { GET } from "../functions/superagentSending";

const initialState = {
    loading: false,
    loadingPagination: false,
    data: {},
    error: '',
};


const FETCH_NEWS_REQUEST = 'FETCH_NEWS_REQUEST'
const FETCH_NEWS_PAGIN_REQUEST = 'FETCH_NEWS_PAGIN_REQUEST'
const FETCH_NEWS_SUCCESS = 'FETCH_NEWS_SUCCESS'
const FETCH_NEWS_FAILURE = 'FETCH_NEWS_FAILURE'


const fetchNewsRequest = () => ({
    type: FETCH_NEWS_REQUEST
})
const fetchNewsPaginRequest = () => ({
    type: FETCH_NEWS_PAGIN_REQUEST
})

const fetchNewsSuccess = data => ({
    type: FETCH_NEWS_SUCCESS,
    payload: data
})


const fetchNewsFailure = error => ({
    type: FETCH_NEWS_FAILURE,
    payload: error
})

const NewsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_NEWS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_NEWS_PAGIN_REQUEST:
            return {
                ...state,
                loadingPagination: true
            }
        case FETCH_NEWS_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingPagination: false,
                data: payload,
                error: '',
            }
        case FETCH_NEWS_FAILURE:
            return {
                loading: false,
                data: {},
                error: payload
            }
        default:
            return state
    }
}

export const FETCH_NEWS_REDUCER = (page) => {
    return (dispatch) => {
        if (page)
            dispatch(fetchNewsPaginRequest())
        else
            dispatch(fetchNewsRequest())
        GET(NEWS_API, { page }).then((success) => {
            dispatch(fetchNewsSuccess(success?.body))
        }).catch((error) => {
            dispatch(fetchNewsFailure(error))
            dispatch(fetchNewsFailure(''))
        })
    }
}

export default NewsReducer