import React from 'react';
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/styles/makeStyles';
import PropTypes from 'prop-types';
import PersonIcon from '@material-ui/icons/Person';
import EventNoteIcon from '@material-ui/icons/EventNote';
import ShareIcon from '@material-ui/icons/Share';
import moment from 'moment';
import { classNames } from '../functions';

const useStyles = (share) =>
  makeStyles((theme) => ({
    newsInfoWrapper: {
      padding: '15px 0',
      display: 'flex',
      alignItems: 'center',
      marginBottom: 20,
      color: theme.palette.gray.text,
      justifyContent: share ? 'space-between' : 'flex-start',
      paddingTop: 0,
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'start',
      },
    },
    newsInfo: {
      display: 'flex',
      alignItems: 'center',
      '&:first-child': {
        marginRight: 20,
      },
      [theme.breakpoints.down('xs')]: {
        marginBottom: 10,
      },
    },
    newsInfoIcon: {
      width: 25,
      height: 25,
      marginRight: 10,
      color: theme.palette.gray.icons,
      transition: 'all 0.2s ease-in',
    },
    shareIcon: {
      '&:hover': {
        color: theme.palette.primary.main,
      }
    },
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap',
        marginTop: 10,
      },
    },
  }));

const NewsInfoComponent = ({ author, date, share }) => {
  const classes = useStyles(share)();
  return (
    <>
      {author || date || share
        ? <Box className={classes.newsInfoWrapper}>
          {share ? (
            <Box>
              <a href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`} target="_blank" rel="noreferrer">
                <ShareIcon className={classNames([classes.newsInfoIcon, classes.shareIcon])} />
              </a>
            </Box>
          ) : null}
          <Box className={classes.wrapper}>
            {date ? (
              <Box className={classes.newsInfo}>
                <EventNoteIcon className={classes.newsInfoIcon} />
                <Typography variant="subtitle1">{moment(date).format('DD MMMM YYYY')}</Typography>
              </Box>
            ) : null}
            {author ? (
              <Box className={classes.newsInfo}>
                <PersonIcon className={classes.newsInfoIcon} />
                <Typography variant="subtitle1">aut. {author}</Typography>
              </Box>
            ) : null}
          </Box>
        </Box>
        : null
      }
    </>
  );
};

NewsInfoComponent.propTypes = {
  date: PropTypes.string,
  author: PropTypes.string,
  printSrc: PropTypes.string,
  shareSrc: PropTypes.string,
};
export default React.memo(NewsInfoComponent);
