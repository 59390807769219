import * as React from "react"

function ClusterLegendIconSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={64}
            height={64}
            viewBox="0 0 64 64"
            {...props}
        >
            <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 465">
                <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 464">
                    <g
                        data-name="\u042D\u043B\u043B\u0438\u043F\u0441 13"
                        fill="#1e60b9"
                        stroke="#fff"
                        strokeWidth={2}
                    >
                        <circle cx={32} cy={32} r={32} stroke="none" />
                        <circle cx={32} cy={32} r={31} fill="none" />
                    </g>
                    <text
                        transform="translate(21 38)"
                        fill="#fff"
                        fontSize={25}
                        fontFamily="HelveticaNeue-Bold, Helvetica Neue"
                        fontWeight={700}
                    >
                        <tspan x={0} y={0}>
                            {"P"}
                        </tspan>
                    </text>
                    <text
                        data-name={8}
                        transform="translate(35 43)"
                        fill="#fff"
                        fontSize={18}
                        fontFamily="HelveticaNeue-Bold, Helvetica Neue"
                        fontWeight={700}
                    >
                        <tspan x={0} y={0}>
                            {"8"}
                        </tspan>
                    </text>
                </g>
                <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 300">
                    <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 299">
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 342"
                            d="M51.176 12.817A27.129 27.129 0 1059.122 32a26.951 26.951 0 00-7.946-19.183zm-2.108 36.261a24.148 24.148 0 117.073-17.075 23.99 23.99 0 01-7.073 17.075z"
                            fill="#f2f2f2"
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default ClusterLegendIconSvg
