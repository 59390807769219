import React, {  useEffect } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import MainContainerComponent from '../../components/MainContainerComponent';
import HeroImg from '../../imgs/pagesHero.jpg';
import { useTranslation } from 'react-i18next';
import SubtitleComponent from '../../components/SubtitleComponent';
import { useDispatch } from 'react-redux';
import { FETCH_COMPLAINT_REDUCER } from '../../reducers/ComplaintReducer';
import { Typography } from '@material-ui/core';
import {GoogleReCaptchaProvider,} from 'react-google-recaptcha-v3'
import ComplaintFormComponent from './components/ComplaintFormComponent';
import FilesToDownloadComponent from '../../components/FilesToDownloadComponent';
import FilePdf from '../../files/Zarzadzenie-Prezesa-NiOL-ws-procedury-reklamacyjnej.pdf';

const useStyles = makeStyles((theme) => ({
    desc: {
        marginTop: 20,
        marginBottom: 20,
        "& a": {
            textDecoration: 'none',
            color: theme.palette.text.primary,
            transition: 'all 0.2s ease-in',
            '&:hover': {
                color: theme.palette.primary.main,
            }
        },
    },
    pdfFile: {
        marginBottom: theme.spacing(3),
    },
}))




const ComplaintPage = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(FETCH_COMPLAINT_REDUCER());
    }, [dispatch])

    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA}>
            <MainContainerComponent
                heroImg={HeroImg}
                mobileImg={HeroImg}
                breadcrumbs={[{
                    title: t('complaint_page.title'),
                }]}
                smallHero
                leftPart={<Box >
                    <SubtitleComponent title={t('complaint_page.title')} variant='h1' />
                    <Typography
                        className={classes.desc}
                        dangerouslySetInnerHTML={{ __html: t('complaint_page.desc') }}
                    />
                    <Box className={classes.pdfFile}>
                        <FilesToDownloadComponent pdf src={FilePdf} fileTitle="Zarządzenie Prezesa NiOL ws procedury reklamacyjnej.pdf" />
                    </Box>
                    <SubtitleComponent title={t('complaint_page.form_title')} variant='h2' />
                    <ComplaintFormComponent /> 
                </Box>}>
            </MainContainerComponent>
        </GoogleReCaptchaProvider>
    )
}

export default React.memo(ComplaintPage);
