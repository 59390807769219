import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import PointSvg from '../../../svg/PointSvg';

const useStyles = makeStyles((theme) => ({
    cont: {
        position: 'absolute',
        zIndex: 201,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: theme.palette.gray.light,
    },
    pointSvg: {
        width: 100,
        height: 100,
        animation: `$iconAnim 2s linear infinite`,
    },
    "@keyframes iconAnim": {
        "0%": {
            width: 100,
            height: 100,
        },
        "50%": {
            width: 150,
            height: 150,
        },
        "100%": {
            width: 100,
            height: 100,
        },
    },
}))

const MapLoaderComponent = ({ show }) => {
    const classes = useStyles();
    const theme = useTheme()
    return (
        <>
            {
                show ? <section className={classes.cont} >
                    <PointSvg fill={theme.palette.gray.text} className={classes.pointSvg} />
                </section>
                    : ''
            }
        </>
    )
}


MapLoaderComponent.propTypes = {
    show: PropTypes.bool,
}
export default React.memo(MapLoaderComponent);