import { LOCALIZATION_API } from "../constants/api";
import { GET } from "../functions/superagentSending";

const initialState = {
    loading: false,
    data: {},
    error: '',
};


const FETCH_LOCALIZATION_REQUEST = 'FETCH_LOCALIZATION_REQUEST'
const FETCH_LOCALIZATION_SUCCESS = 'FETCH_LOCALIZATION_SUCCESS'
const FETCH_LOCALIZATION_FAILURE = 'FETCH_LOCALIZATION_FAILURE'


const fetchLocalizationRequest = () => ({
    type: FETCH_LOCALIZATION_REQUEST
})

const fetchLocalizationSuccess = data => ({
    type: FETCH_LOCALIZATION_SUCCESS,
    payload: data
})


const fetchLocalizationFailure = error => ({
    type: FETCH_LOCALIZATION_FAILURE,
    payload: error
})

const LocalizationReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_LOCALIZATION_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_LOCALIZATION_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload,
                error: '',
            }
        case FETCH_LOCALIZATION_FAILURE:
            return {
                loading: false,
                data: {},
                error: payload
            }
        default:
            return state
    }
}

export const FETCH_LOCALIZATION_REDUCER = (slug) => {
    return (dispatch) => {
        dispatch(fetchLocalizationRequest())
        GET(`${LOCALIZATION_API}/${slug}`).then((success) => {
            dispatch(fetchLocalizationSuccess(success?.body?.data))
        }).catch((error) => {
            dispatch(fetchLocalizationFailure(error))
            dispatch(fetchLocalizationFailure(''))
        })
    }
}

export default LocalizationReducer