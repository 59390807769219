import React, { useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/styles/makeStyles'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import PropTypes from 'prop-types';
import MainContainerComponent from '../../components/MainContainerComponent';
import { useTranslation } from 'react-i18next';
import ReadAlsoComponent from '../../components/ReadAlsoComponent';
import RecommendComponent from '../../components/RecommendComponent';
import SubtitleComponent from '../../components/SubtitleComponent';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_QUESTIONS_REDUCER } from '../../reducers/QuestionsReducer';
import AccordionSkeletonComponent from '../../components/skeletonComponents/AccordionSkeletonComponent';
import { classNames } from '../../functions';
import HeroImage from '../../imgs/pagesHero.jpg';
import HeroImageMobile from '../../imgs/pagesHeroMobile.jpg'
import articleCss from '../../constants/articleCss'

const useStyles = makeStyles((theme) => ({
    accordion: {
        // '& .MuiPaper-root': {
        background: theme.palette.background.default,
        // }
    },
    answer: {
        ...articleCss(theme),
    },
    firstAccordion: {
        '&:before': {
            display: 'none',
        }
    }

}))

const QuestionsPage = (props) => {
    const classes = useStyles();
    const { data, loading } = useSelector((state) => state.QuestionsReducer);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(FETCH_QUESTIONS_REDUCER())
    }, [dispatch])

    return (
        <MainContainerComponent
            loading={loading}
            gallery={data?.gallery}
            smallHero
            breadcrumbs={[
                ...(data?.breadcrumbs?.length ? data?.breadcrumbs : []),
                {
                    title: t('questions_page.title'),
                }
            ]}
            heroImg={HeroImage}
            mobileImg={HeroImageMobile}
            shorts={data?.shorts}
            showTitle
            leftPart={
                <>
                    {loading
                        ? <>
                            <AccordionSkeletonComponent />
                            <AccordionSkeletonComponent />
                            <AccordionSkeletonComponent />
                        </>
                        : data?.data?.length
                            ? data.data.map(({ name, questions }, key) =>
                                <Box key={key} marginBottom='40px'>
                                    <Box mb='20px'>
                                        <SubtitleComponent title={name} variant={'h2'} />
                                    </Box>
                                    {questions?.length
                                        ? questions.map((q, key2) =>
                                            <Accordion key={key2} className={classNames([classes.accordion, classes.firstAccordion])}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon color='primary' />}
                                                    aria-controls={`panel${key}-${key2}-content`}
                                                    id={`panel${key}-${key2}-header`}
                                                >
                                                    <Typography color='primary'>{q.question}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography
                                                        dangerouslySetInnerHTML={{ __html: q.answer }}
                                                        className={classes.answer}
                                                    />
                                                </AccordionDetails>
                                            </Accordion>
                                        )
                                        : null
                                    }
                                </Box>
                            )
                            : null}
                </>}
            rightPart={<>
                <ReadAlsoComponent
                    loading={loading}
                    items={data.also_read || []}
                />
                <RecommendComponent
                    loading={loading}
                    items={data?.recommend}
                />
            </>}
        />
    )
}


QuestionsPage.propTypes = {
    name: PropTypes.string,
}
export default React.memo(QuestionsPage);