import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    cont: {
        padding: 20,
        position: 'relative',
        '&::after': {
            content: "''",
            display: 'block',
            paddingBottom: 'calc(100% - 40px)',
        },
    },
    item: {
        width: 'calc(100% - 40px)',
        padding: 15,
        position: 'absolute',
        minHeight: 'calc(100% - 40px)',
    }
}))

const ChoiceItemSkeletonComponent = (props) => {
    const classes = useStyles();

    return (
        <Grid item md={4} sm={6} xs={12} className={classes.cont}>
            <Skeleton variant='rect' className={classes.item} />
        </Grid>
    )
}


ChoiceItemSkeletonComponent.propTypes = {
    name: PropTypes.string,
}
export default React.memo(ChoiceItemSkeletonComponent);